import React, {FC, useEffect, useState} from 'react';
import {ReactSVG} from "react-svg";
import chevron_down from '../../../assets/icons/chevron_down.svg';
import {useTranslation} from "react-i18next";

interface Props {
}


const HomeFAQ: FC<Props> = () => {
  const {t} = useTranslation();

  const [activeIdx, setActiveIdx] = useState<any>(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
    if (activeIdx >= 0 && ready) {
      const el: any = document.getElementById(`home-faq-item_${activeIdx}`);
      if (!el) return;
      setTimeout(() => {
        const section: any = document.getElementById('home-faq');
        const top = section?.offsetTop + el?.offsetTop - 180;
        if (window?.scrollY > top) window.scrollTo({top, behavior: 'smooth'});
      }, 300)
    }
  }, [activeIdx]);

  const handleToggle = (idx: number) => () => {
    setActiveIdx((activeIdx: any) => {
      if (activeIdx === idx) return null;
      return idx;
    });
  }

  return (
    <div className='home-faq'>
      <h3 className='text-center' dangerouslySetInnerHTML={{__html: t(`faq:FAQ_TITLE`) || ''}} />
      <div className='pt-3'>
        {Array(6).fill({}).map((_, i) => (
          <div className={`home-faq-item${activeIdx === i ? ' active' : ''}`} id={`home-faq-item_${i}`} key={i}>
            <div className="home-faq-item-head" onClick={handleToggle(i)}>
              <h5 className='flex-grow-1 pe-4'  dangerouslySetInnerHTML={{__html: t(`faq:FAQ_TITLE_${i+1}`) || ''}} />
              <ReactSVG src={chevron_down} className='react-icon'/>
            </div>
            <div className="home-faq-item-content" dangerouslySetInnerHTML={{__html: t(`faq:FAQ_TEXT_${i+1}`) || ''}} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default HomeFAQ;