import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import done from "../../assets/icons/done.svg";
import Button from "../../components/Button";
import diamond from "../../assets/icons/diamond.svg";
import {useTranslation} from "react-i18next";

const textData = [
  'NO_MONEY_TEXT_1',
  'NO_MONEY_TEXT_2',
  'NO_MONEY_TEXT_3',
  'NO_MONEY_TEXT_4',
  'NO_MONEY_TEXT_5',
]

interface Props {
  position: 'create-ai'|'chat'
  onClick: () => void;
}

const ModalNoMoneySubscription: FC<Props> = ({onClick, position}) => {
  const {t} = useTranslation();
  if (position === 'chat') return (
    <div className="modal-with_img-content with-bg">
      <div className="modal-with_img-content-bg" />
      <div className="modal-with_img-content-bg-2" />
      <div className='position-relative z-1'>
        <h4 className='mb-3' dangerouslySetInnerHTML={{__html: t('NO_MONEY_TITLE_1') || ''}}/>
        <div className='text-bold pb-3'>{t('NO_MONEY_SUBTITLE_1')}</div>
        {textData.map((item, i) => (
          <div className='d-flex align-items-center mt-3' key={i}>
            <ReactSVG className='react-icon' src={done}/>
            <div className='text-bold ms-2'>{t(item)}</div>
          </div>
        ))}
        <div className='modal-footer'>
          <Button
            onClick={onClick}
            iconAbsolute
            className='w-100'
            icon={diamond}
            title='UPDATE_TO_PREM'
          />
        </div>
      </div>
    </div>
  )
  return (
    <div className="modal-with_img-content with-bg ">
      <div className="modal-with_img-content-bg" />
      <div className="modal-with_img-content-bg-2" />
      <div className='position-relative z-1'>
        <h4 className='mb-3' dangerouslySetInnerHTML={{__html: t('NO_MONEY_TITLE_1') || ''}}/>
        <div className='text-bold'>{t('NO_MONEY_SUBTITLE_2')}</div>
        <div className='modal-footer'>
          <Button
            onClick={onClick}
            iconAbsolute
            className='w-100'
            icon={diamond}
            title='GO_PREM'
          />
        </div>
      </div>
    </div>
  );
}

export default ModalNoMoneySubscription;