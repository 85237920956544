import React, {FC, InputHTMLAttributes, useRef} from 'react';
import {generatePassword} from '../../modules/utils';
import {useTranslation} from "react-i18next";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  id?: string;
  className?: string;
  small?: boolean;
  active?: boolean;
}

const Radio: FC<Props> = ({
                               label,
                               className,
                               id,
                               small,
                               ...props
                             }) => {
  const {t} = useTranslation();
  const _id = useRef('radio_' + generatePassword());
  return (
    <div className={`form-radio ${className ?? ''}`}>
      <input
        {...props}
        className={`form-radio-input`}
        type="radio"
        id={id || _id.current}
      />
      <div className='form-radio-custom' />
      {label
        ?
        <label className="form-radio-label" htmlFor={id || _id.current}>
          {t(label)}
        </label>
        :
        null
      }
    </div>
  );
};

export default Radio;
