import React, {FC, useEffect, useRef, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import StepFooter from "../StepFooter";
import {EGender, ICreateModelRequest, IVoice} from "../../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel,
  getModalHairColor,
  getModelAge,
  getModelPersonalityData,
  getRelationshipData
} from "../../assets/modules/utils";
import SummaryItem from "./SummaryItem";
import AppStore from "../../../../store/AppStore";
import {useTranslation} from "react-i18next";
import PlayBtn from "../../../../components/PlayBtn";

interface Props {
  onStepChange: (step: number) => void;
  onSubmit: () => void;
  data: Partial<ICreateModelRequest>;
  loading: boolean;
  voices?: IVoice[];
}

const Step9: FC<Props> = observer(({onStepChange, onSubmit, data, loading, voices}) => {
  const {t} = useTranslation();
  const audio: any = useRef(null);

  const [voicePlaying, setVoicePlaying] = useState(false);

  useEffect(() => {
    if(!data.relationship) onStepChange(0);
  }, []);

  useEffect(() => {
    if (!voices?.length) return;
    const voice = voices?.find(item => item.id === data.voiceId);
    if (voice) {
      audio.current = new Audio(voice?.sample.url);
      audio.current.addEventListener('ended', handleAudioEnded);
    }
    return () => {
      audio?.current?.pause();
      audio?.current?.removeEventListener('ended', handleAudioEnded);
      audio.current = null;
    }
  }, [voices?.length]);

  const handleAudioEnded = () => {
    setVoicePlaying(false);
  };

  const togglePlaying = (e: any) => {
    e.stopPropagation();
    setVoicePlaying(prevState => {
      if (!audio?.current) return false;
      const nextState = !prevState;
      if (nextState) {
        audio.current.currentTime = 0;
        audio.current.play();
      } else {
        audio.current.pause();
      }
      return nextState;
    })
  }
  return (
    <div className='create-ai-step'>
      <StepTitle title='SUMMARY' onStepChange={() => onStepChange(7)} last step={9}/>
      <div className='row g-3'>
        <SummaryItem title='STYLE'>
          <ActiveElement small onClick={() => onStepChange(0)}>
            <div className='model-card'>
              <div className="model-card-category">
                <span className='letter-uppercase text-truncate'>{t(data.style?.toUpperCase()!)}</span>
              </div>
              <img src={getImageByModel(AppStore.gender, 'style', data.style!)} alt=""/>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        <SummaryItem title='ETHNICITY'>
          <ActiveElement small onClick={() => onStepChange(1)}>
            <div className='model-card'>
              <div className="model-card-category">
                <span className='letter-uppercase text-truncate'>{t(data.ethnicity?.toUpperCase()!)}</span>
              </div>
              <img src={getImageByModel(AppStore.gender, 'ethnicity', data.ethnicity!, data.style)} alt=""/>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        <SummaryItem title='AGE'>
          <ActiveElement bordered small onClick={() => onStepChange(1)}>
            <div className='create-ai-summary-item-title'>
              {t(getModelAge(data.age!))}
            </div>
            <div className="model-card-category">
              <span className='letter-uppercase  text-truncate'>{t(getModelAge(data.age!))}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        <SummaryItem title='EYE_COLOR'>
          <ActiveElement bordered small onClick={() => onStepChange(1)}>
            <img className='create-ai-summary-eye' src={getImageByModel(AppStore.gender, 'eyesColor', data.eyesColor!)}
                 alt="eye"/>
            <div className="model-card-category">
              <span className='text-truncate'>{t(data.eyesColor?.toUpperCase()!)}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        <SummaryItem title='HAIR_STYLE'>
          <ActiveElement small onClick={() => onStepChange(2)}>
            <div className='model-card'>
              <div className="model-card-category">
                <span className='text-truncate'>{t(data.hairStyle?.toUpperCase()!)}</span>
              </div>
              <img src={getImageByModel(AppStore.gender, 'hairStyle', data.hairStyle!, data.style)} alt=""/>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        <SummaryItem title='HAIR_COLOR'>
          <ActiveElement bordered small onClick={() => onStepChange(2)}>
            <div className="create-ai-summary-eye" style={{background: getModalHairColor(data.hairColor!)}}/>
            <div className="model-card-category">
              <span className='text-truncate'>{t(data.hairColor?.toUpperCase()!)}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>

        <SummaryItem title='BODY_TYPE'>
          <ActiveElement small onClick={() => onStepChange(3)}>
            <div className='model-card'>
              <div className="model-card-category">
                <span className='text-truncate'>{t(data.bodyType?.toUpperCase()!)}</span>
              </div>
              <img src={getImageByModel(AppStore.gender, 'bodyType', data.bodyType!, data.style)} alt=""/>
            </div>

            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        {AppStore.gender === EGender.Female &&
          <>
            <SummaryItem title='BREAST_SIZE'>
              <ActiveElement small onClick={() => onStepChange(3)}>
                <div className='model-card'>
                  <div className="model-card-category">
                    <span className='text-truncate'>{t(data.breastSize?.toUpperCase()!)}</span>
                  </div>
                  <img src={getImageByModel(AppStore.gender, 'breastSize', data.breastSize!, data.style)} alt=""/>
                </div>

                <div className="btn-edit"/>
              </ActiveElement>
            </SummaryItem>
            <SummaryItem title='BUTT_SIZE'>
              <ActiveElement small onClick={() => onStepChange(3)}>
                <div className='model-card'>
                  <div className="model-card-category">
                    <span className='text-truncate'>{t(data.buttSize?.toUpperCase()!)}</span>
                  </div>
                  <img src={getImageByModel(AppStore.gender, 'buttSize', data.buttSize!, data.style)} alt=""/>
                </div>

                <div className="btn-edit"/>
              </ActiveElement>
            </SummaryItem>
          </>
        }
        <SummaryItem title='PERSONALITY'>
          <ActiveElement bordered small onClick={() => onStepChange(4)}>
            <div className="create-ai-summary-icon">{getModelPersonalityData(data?.personality!)?.icon}</div>
            <div className="model-card-category">
              <span
                className='letter-uppercase text-truncate'>{t(getModelPersonalityData(data?.personality!)?.title?.toUpperCase())}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>

        <SummaryItem title='RELATIONSHIP'>
          <ActiveElement bordered small onClick={() => onStepChange(7)}>
            <div className="create-ai-summary-icon">{getRelationshipData(data?.relationship!)?.icon}</div>
            <div className="model-card-category">
              <span
                className='letter-uppercase text-truncate'>{t(getRelationshipData(data?.relationship!)?.[AppStore.gender])}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        {data.voiceId
          ?
          <SummaryItem title='VOICE' className='create-ai-voice'>
            <ActiveElement bordered small onClick={() => onStepChange(4)}>
              <div className={`btn btn-primary btn-md${voicePlaying ? ' playing' : ''}`}>
                <PlayBtn onClick={togglePlaying} isPlaying={voicePlaying}/>
              </div>
              <div className="model-card-category">
              <span
                className='letter-uppercase text-truncate'>{t('VOICE')}</span>
              </div>
              <div className="btn-edit"/>
            </ActiveElement>
          </SummaryItem>
          :
          null
        }
        <SummaryItem title='OCCUPATION'>
          <ActiveElement className='button' bordered onClick={() => onStepChange(5)}>
            <div className='d-grid overflow-hidden flex-1 text-center pe-2'>
              <span className='text-truncate'>{data.occupation}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
        <SummaryItem title='HOBBIES'>
          {data.hobbies?.map(hobby => (
            <ActiveElement className='button mb-2' bordered onClick={() => onStepChange(5)} key={hobby}>
              <div className='d-grid overflow-hidden flex-1 text-center pe-2'>
                <span className='text-truncate'>{hobby}</span>
              </div>
              <div className="btn-edit"/>
            </ActiveElement>
          ))}
        </SummaryItem>
        <SummaryItem title='CLOTHING'>
          <ActiveElement className='button' bordered onClick={() => onStepChange(6)}>
            <div className='d-grid overflow-hidden flex-1 text-center pe-2'>
              <span className='text-truncate'>{data.clothing}</span>
            </div>
            <div className="btn-edit"/>
          </ActiveElement>
        </SummaryItem>
      </div>
      <StepFooter
        last
        nextTitle={'BRING_AI_TO_LIFE'}
        onBack={() => onStepChange(7)}
        onNext={onSubmit}
        loading={loading}
      />
    </div>
  );
})

export default Step9;