import  React, {FC} from 'react';
import Button from "../../../../components/Button";
import {useTranslation} from "react-i18next";

interface Props {
  value: number;
  onChange: (age: number) => void;
}

const Step2Age: FC<Props> = ({value, onChange}) => {
  const {t} = useTranslation();

  return (
    <div className="card-dark create-ai-inner-card">
      <h6 className='text-center'>{t('CHOOSE_AGE')}</h6>
      <div className='d-flex justify-content-center mt-3 mt-md-4 flex-wrap gap-2'>
        <Button
          className={`${value === 18 ? 'active' : ''}`}
          size='md'
          btnType='third'
          title='TEEN_18'
          onClick={() => onChange(18)}
        />
        <Button
          className={`${value === 20 ? 'active' : ''}`}
          size='md'
          btnType='third'
          title='20_S'
          onClick={() => onChange(20)}
        />
        <Button
          className={`${value === 30 ? 'active' : ''}`}
          size='md'
          btnType='third'
          title='30_S'
          onClick={() => onChange(30)}
        />
        <Button
          className={`${value === 40 ? 'active' : ''}`}
          size='md'
          btnType='third'
          title='40-50'
          onClick={() => onChange(40)}
        />
      </div>
    </div>
  );
}

export default Step2Age;