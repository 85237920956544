import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC, useEffect, useState} from "react";
import close from "../../assets/icons/close.svg";
import {EFieldGroup, EGender, EModelStyle, ESortOrder, IModel} from "../../modules/rest";
import ActiveElement from "../../components/ActiveElement";
import ModelCard from "../../components/ModelCard";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import Loadable from "../../components/Loadable";
import EmptyList from "../../components/EmptyList";
import {useTranslation} from "react-i18next";
import HorizontalScroll from "../../components/HorizontalScroll";


interface Props {
  resolve(result?: IModel): void;
}

const ChooseModelModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();

  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modelType, setModelType] = useState<'discover'|'my'>('discover');
  const [modelStyle, setModelStyle] = useState<EModelStyle>(EModelStyle.Real);
  const [gender, setGender] = useState<EGender>(EGender.Female);
  const [selectedModel, setSelectedModel] = useState<IModel>();
  const [list, setList] = useState<IModel[]>();

  useEffect(() => {
    fetch()
  }, [modelType, modelStyle, gender]);

  const fetch = async () => {
    setLoading(true);
    try {
      const res = await API.Models.getList(
        {
          style: modelStyle,
          gender,
          limit: 100,
          preset: modelType === 'my' ? 'my' : undefined,
          order: ESortOrder.DESC,
        }, [EFieldGroup.ModelFull]);
      setList(res.data);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
      setReady(true);
    }
  }

  const submit = () => {
    resolve(selectedModel);
  }

  const handleClose = () => {
    resolve()
  }

  return (
    <div className='modal-container big generate-image-modal_models'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <h4 className='px-2 pt-2'>{t('CHOOSE_CHARACTER')}</h4>
      <HorizontalScroll className="generate-image-modal_models-tabs " secondary>
        <div className="tabs sm">
          <div className={`tab${modelType === 'my' ? ' active' : ''}`}
               onClick={() => setModelType('my')}>{t('MY_AIS')}
          </div>
          <div className={`tab${modelType === 'discover' ? ' active' : ''}`}
               onClick={() => setModelType('discover')}>{t('DISCOVER')}
          </div>
        </div>
        <div className="tabs sm">
          <div className={`tab${modelStyle === EModelStyle.Anime ? ' active' : ''}`}
               onClick={() => setModelStyle(EModelStyle.Anime)}>{t('ANIME')}
          </div>
          <div className={`tab${modelStyle === EModelStyle.Real ? ' active' : ''}`}
               onClick={() => setModelStyle(EModelStyle.Real)}>{t('REALISTIC')}
          </div>
        </div>
        <div className="tabs sm">
          <div className={`tab${gender === EGender.Male ? ' active' : ''}`}
               onClick={() => setGender(EGender.Male)}>{t('MALE')}
          </div>
          <div className={`tab${gender === EGender.Female ? ' active' : ''}`}
               onClick={() => setGender(EGender.Female)}>{t('FEMALE')}
          </div>
        </div>
      </HorizontalScroll>
      {list?.length
        ? <Loadable loading={loading} className="generate-image-modal_models-list-container">
          <div className="row g-2 generate-image-modal_models-list">
            {list.map(model => (
              <div className='col-6 col-sm-4' key={model.id}>
                <ActiveElement
                  radio
                  onClick={() => setSelectedModel(model)}
                  active={model.id === selectedModel?.id}
                >
                  <ModelCard model={model}/>
                </ActiveElement>
              </div>
            ))
            }
          </div>
        </Loadable>
        :
        <EmptyList visible={ready}/>
      }
      <div className="modal-footer">
        <Button
          onClick={handleClose}
          title={'CANCEL'}
          btnType='secondary'
        />
        <Button
          title={'SELECT'}
          onClick={submit}
        />
      </div>
    </div>
  )
    ;
}

const openChooseModelModal = (): Promise<IModel> => {
  return asyncModal(ChooseModelModal, {}, {showCloseIcon: false, center: true, blockScroll: false});
};

export {openChooseModelModal};
