import React, {FC} from 'react';
import BlurComponent from "../BlurComponent";
import {openGalleryModal} from "../../modals";
import {ReactSVG} from "react-svg";
import expand from "../../assets/icons/expand.svg";
import {thumb, thumbAvatar} from "../../modules/utils";
import Avatar from "../Avatar";
import {ESender, IMessage} from "../../modules/rest";
import Button from "../Button";

interface Props {
  data?: IMessage;
  avatarId?: string;
  hideAvatar?: boolean;
  userStub?: boolean;
  message?: string;
  className?: string;
  handleAuth?: () => void;
  onPremiumNavigate: () => void;
}

const BoardMessage: FC<Props> = ({data, hideAvatar, className, handleAuth, avatarId, userStub, onPremiumNavigate}) => {
  return (
    <div
      className={`message-container${hideAvatar ? ' hide-avatar' : ''} ${data?.sender} ${className || ''}`}
      id={`message_${data?.id}`}>
      <div className="message pt-1">
        {data?.photo
          ? <BlurComponent
            onPremiumNavigate={onPremiumNavigate}
            handleAuth={handleAuth}
            imageId={avatarId}
            isNsfw={data?.photo.isNsfw}
            className='message-photo btn-expand-container'
            onClick={() => openGalleryModal({image: data?.photo})}>
            <ReactSVG src={expand} className='react-icon btn-expand'/>
            <img src={thumb(data?.photo.id, 800)} alt="img"/>
          </BlurComponent>
          : null
        }
        {data?.text ? <div className='px-2 my-2'>{data?.text}</div> : null}
        {data?.ad
          ?
          <div className='mt-3 d-flex justify-content-start'>
            <Button
              href={data.ad.buttonUrl || ''}
              target={'_blank'}
              size='xs'
            >
              {data.ad.buttonText}
            </Button>
          </div>
          :
          null
        }

      </div>
      {!hideAvatar && <Avatar
        userStub={userStub}
        image={data?.sender === ESender.Model ? thumbAvatar(avatarId, 128) : ''}
        size={'xs'}
        user={data?.sender === ESender.User}/>
      }
    </div>
  );
}

export default BoardMessage;