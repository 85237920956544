import asyncModal from 'react-async-modal';
import Button from '../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC} from "react";
import close from "../assets/icons/close.svg";
import logo from "../assets/images/logo_small.png";
import {requestPermissionMessaging} from "../modules/firebase";
import {useTranslation} from "react-i18next";


interface Props {

  resolve(result: boolean|string): void;
}

const PushNotificationModal: FC<Props> = ({resolve}) => {
  const {t} = useTranslation();

  const submit = () => {
    resolve(true);
    requestPermissionMessaging()
  }

  const handleClose = () => {
    resolve(false)
  }

  return (
    <div className='modal-container'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <div className='push-notif-modal-logo'>
        <img src={logo} alt="logo"/>
      </div>
      <div className='text-bold text-center'>{t('GET_PROMO')}</div>
      <div className="modal-footer">
        <Button
          contentClassName={'px-3'}
          onClick={handleClose}
          title={'MB_LATER'}
          btnType='secondary'
        />
        <Button
          contentClassName={'px-3'}
          title={'SURE'}
          onClick={submit}
        />
      </div>
    </div>
  );
};

const openPushNotificationModal = (): Promise<boolean> => {
  return asyncModal(PushNotificationModal, {}, {showCloseIcon: false, center: true});
};

export {openPushNotificationModal};
