import React, {FC} from 'react';
import {EGender} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import female from "./assets/icons/female.svg";
import female_active from "./assets/icons/female_active.svg";
import male from "./assets/icons/male.svg";
import male_active from "./assets/icons/male_active.svg";

const icons: { [GENDER in EGender]: { icon: any, iconActive: any } } = {
  [EGender.Female]: {icon: female, iconActive: female_active},
  [EGender.Male]: {icon: male, iconActive: male_active},
}

interface Props {
  gender: EGender;
  className?: string;
  active?: boolean;
  size?: 'xl'
}

const GenderIcon: FC<Props> = ({className, active, gender, size}) => {
  return (
    <div className={`gender-select-icon-wrap${active ? ' active' : ''} ${size || ''} ${className || ''}`}>
      <ReactSVG src={icons[gender].icon} className='react-icon gender-select-icon'/>
      <ReactSVG src={icons[gender].iconActive} className='react-icon gender-select-icon-active'/>
    </div>
  );
}

export default GenderIcon;