import React, {FC} from 'react';
import {IConversation, IModel} from "../../../modules/rest";
import Avatar from "../../../components/Avatar";
import {thumbAvatar} from "../../../modules/utils";
import {openGalleryModal} from "../../../modals";
import {ReactSVG} from "react-svg";
import expand from "../../../assets/icons/expand.svg";
import arrow_tr from "../../../assets/icons/arrow_tr.svg";
import {useTranslation} from "react-i18next";
import NameAge from "../../../components/NameAge";
import Button from "../../../components/Button";

interface Props {
  conversation: IConversation;
  onClick: () => void;
}

const SharedChatsListItem: FC<Props> = ({conversation, onClick}) => {
  const {t} = useTranslation();

  const handleOpenGallery = (e: any) => {
    e.stopPropagation();
    openGalleryModal({image: conversation?.model?.mainPhotoImage}).then();
  }

  const model: IModel = conversation?.model || {};

  return (
    <div className='col-12 col-sm-6  col-xl-4 col-xxl-3'>
      <div className='shared-list-item' onClick={onClick}>
        <div className='btn-expand-container' onClick={handleOpenGallery}>
          <ReactSVG src={expand} className='react-icon btn-expand'/>
          <Avatar size='xl' image={thumbAvatar(model.mainPhotoImage?.id, 128)}/>
        </div>
        <div className="shared-list-item-body-container">
          <div className="shared-list-item-body">

            <div className='shared-list-item-head-container'>
              <div className='shared-list-item-head'>
                <NameAge name={model.firstName} age={model.age}/>
                <div className='text-14 text-tint mt-2'>{t('BY', {author: `@${conversation.authorName}`})}</div>
              </div>
              <div className='model-card-category'>
                <span className='text-truncate'>{t(model.style?.toUpperCase() || '')}</span>
              </div>
            </div>
            <div className='text-bold-14 mt-2'>{conversation.title}</div>
            <p className='text-dark mt-2 flex-1'>{conversation.description}</p>
            <Button
              iconAbsolute
              btnType='secondary'
              className='w-100 mt-3'
              size='sm'
              title='FULL_CHAT'
              icon={arrow_tr}
            />
          </div>
        </div>
      </div>

    </div>
  );
}

export default SharedChatsListItem;