import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import sound_svg from "./assets/icons/sound.svg";
import sound_wave_2 from "./assets/icons/sound_wave_2.svg";
import sound_wave_1 from "./assets/icons/sound_wave_1.svg";
import Avatar from "../Avatar";
import {thumbAvatar} from "../../modules/utils";

interface Props {
  avatarId?: string;
}

const MessageSound: FC<Props> = ({avatarId}) => {
  return (
    <div className='message-container'>
      <div className={`message-sound model`}>
        <ReactSVG src={sound_svg} className='react-icon'/>
        <ReactSVG src={sound_wave_1} className='react-icon message-sound-wave1'/>
        <ReactSVG src={sound_wave_2} className='react-icon message-sound-wave2'/>
      </div>
      {avatarId ? <Avatar image={thumbAvatar(avatarId, 128)} size={'xs'}/> : null}
    </div>
  );
}

export default MessageSound;
