import React, {FC} from 'react';
import {observer} from "mobx-react";
import {Link, Route, Routes, useParams} from "react-router-dom";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import CookiesPolicy from "./pages/CookiesPolicy";
import UnderagePolicy from "./pages/UnderagePolicy";
import ContentRemovalPolicy from "./pages/ContentRemovalPolicy";
import BlockedContentPolicy from "./pages/BlockedContentPolicy";
import DMCAPolicy from "./pages/DMCAPolicy";
import USCExemption from "./pages/USCExemption";
import ComplainPolicy from "./pages/ComplainPolicy";
import {useTranslation} from "react-i18next";

interface Props {
}

// export const legalRoutes = [
//   {path: 'terms-of-service', title: 'TERMS_OF_SERVICE', href: 'https://docs.google.com/document/d/1gtLZ13WRJR5lbqKs9zHm4aY_PNCxB9qwy9i-6-qVmqk/edit?usp=sharing'},
//   {path: 'privacy-policy', title: 'PRIVACY_POLICY', href: ''},
//   {path: 'cookies-policy', title: 'COOKIE_POLICY', href: ''},
//   {path: 'underage-policy', title: 'UNDERAGE_POLICY', href: 'https://docs.google.com/document/d/1ZdDvaraLQqMzvJrekE265qan5lrJdqeclmObx84XSvU/edit?usp=sharing'},
//   {path: 'content-removal-policy', title: 'CONTENT_REMOVAL_POLICY', href: 'https://docs.google.com/document/d/1iPVP1wEfZ9bcOI2wNnHznEJvC8-C9RgS2w06FJcpYNk/edit?usp=sharing'},
//   {path: 'blocked-content-policy', title: 'BLOCKED_CONTENT_POLICY', href: 'https://docs.google.com/document/d/12-WP7NevHO9BF99vIdvtDiEWZW2Ij9l_3kk2aEKyz90/edit?usp=sharing'},
//   {path: 'dmca-policy', title: 'DMCA_POLICY', href: ''},
//   {path: 'complaint-policy', title: 'COMPLAIN_POLICY', href: 'https://docs.google.com/document/d/1dOhIsNH03Vk3uYrsvjE61_cknZR0DW30554BVSuCIEY/edit?usp=sharing'},
//   {path: 'usc-2257-exemption', title: 'USC_EXEMPTION', href: 'https://docs.google.com/document/d/1ml2hxoxomv8PebFDP-sH9vfWiCjDSdbuf0vpFEcxxm0/edit?usp=sharing'},
// ]
export const legalRoutes = [
  {path: 'terms-of-service', title: 'TERMS_OF_SERVICE', href: ''},
  {path: 'privacy-policy', title: 'PRIVACY_POLICY', href: ''},
  {path: 'cookies-policy', title: 'COOKIE_POLICY', href: ''},
  {path: 'underage-policy', title: 'UNDERAGE_POLICY', href: ''},
  {path: 'content-removal-policy', title: 'CONTENT_REMOVAL_POLICY', href: ''},
  {path: 'blocked-content-policy', title: 'BLOCKED_CONTENT_POLICY', href: ''},
  {path: 'dmca-policy', title: 'DMCA_POLICY', href: ''},
  {path: 'complaint-policy', title: 'COMPLAIN_POLICY', href: ''},
  {path: 'usc-2257-exemption', title: 'USC_EXEMPTION', href: ''},
]

const LegalInformationPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const params = useParams();

  if (params['*']) return (
    <Routes>
      <Route path='terms-of-service' element={<TermsOfServicePage/>}/>
      <Route path='privacy-policy' element={<PrivacyPolicies/>}/>
      <Route path='cookies-policy' element={<CookiesPolicy/>}/>
      <Route path='underage-policy' element={<UnderagePolicy/>}/>
      <Route path='content-removal-policy' element={<ContentRemovalPolicy/>}/>
      <Route path='blocked-content-policy' element={<BlockedContentPolicy/>}/>
      <Route path='dmca-policy' element={<DMCAPolicy/>}/>
      <Route path='complaint-policy' element={<ComplainPolicy/>}/>
      <Route path='usc-2257-exemption' element={<USCExemption/>}/>
    </Routes>
  )
  return (
    <div className='container'>
      <h2 className='page-title'>{t('LEGAL_INFO')}</h2>
      <div className='legal-btn-list'>
        {legalRoutes.map(item => (
          <Link to={item.href || `/legal-information/${item.path}`} target={item.href ? '_blank' : ''} className='btn btn-secondary' key={item.path}>
            <div className="btn-content"><span className='letter-uppercase'>{t(item.title)}</span></div>
          </Link>
        ))}
      </div>
    </div>
  );
})

export default LegalInformationPage;