import {openModalLogin} from "../modals";
import {useLocation, useNavigate} from "react-router-dom";
import ChatStore from "../store/ChatStore";
import {toJS} from "mobx";

interface Props {
  onClick: (authType?: 'login'|'register', onSuccessAuth?: () => void, state?: object) => void;
  finishedStatus?: boolean;

}


const useAuth = (defaultImageId?: string|null): [onClick: (authType?: 'login'|'register', onSuccessAuth?: () => void, state?: object) => void, finishedStatus?: boolean] => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (authType?: 'login'|'register', onSuccessAuth?: () => void, state?: object) => {
    if (window.innerWidth < 768) {
      navigate(`/auth/${authType || 'login'}`, {state: {prevPathname: location.pathname, ...(state || {})}});
    } else {
      openModalLogin(authType, defaultImageId, ChatStore.activeChat?.model?.id).then(conversationId => {
        if (typeof conversationId === "number" && location.pathname.includes('/chat')) {
          navigate(`/chat/${conversationId}`);
        }
        if (conversationId && onSuccessAuth) {
          onSuccessAuth();
        }
      });
    }
  }

  return [handleClick];
}

export default useAuth;