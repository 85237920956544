import React, {ButtonHTMLAttributes, FC} from 'react';
import {ReactSVG} from "react-svg";
import {Link} from "react-router-dom";
import BtnContent from "./components/BtnContent";
import {useTranslation} from "react-i18next";


interface Props extends ButtonHTMLAttributes<any> {
  children?: any;
  title?: string;
  href?: string;
  contentClassName?: string;
  titleClassName?: string;
  loading?: boolean;
  circle?: boolean;
  iconAbsolute?: boolean;
  btnType?: 'primary'|'secondary'|'third'|'white'|'danger';
  size?: 'sm'|'lg'|'md'|'xs';
  icon?: any;
  iconText?: string;
  iconPosition?: 'left'|'right';
  target?: string;
}

const Button: FC<Props> = ({
                             children,
                             title,
                             className,
                             contentClassName,
                             titleClassName,
                             type = 'button',
                             btnType = 'primary',
                             icon,
                             iconText,
                             iconAbsolute,
                             iconPosition = 'left',
                             size,
                             circle,
                             loading,
                             href,
                             ...props
                           }) => {
  const {t} = useTranslation();
  const btnSizeClass = size ? ` btn-${size}` : '';
  const btnClassName = `btn btn-${btnType}${btnSizeClass}${loading ? ' loading' : ''}${circle ? ' btn-circle' : ''} ${className || ''}`
  if (href) return (
    <Link to={href}  className={btnClassName} {...props}>
      <BtnContent className={contentClassName} icon={icon} iconPosition={iconPosition} iconAbsolute={iconAbsolute}
                  loading={loading}>
        {children || <span>{t(title || '')}</span>}
      </BtnContent>
    </Link>
  )
  return (
    <button
      {...props}
      type={type}
      className={btnClassName}
    >
      <BtnContent
        className={contentClassName}
        icon={icon}
        iconText={iconText}
        iconPosition={iconPosition}
        iconAbsolute={iconAbsolute}
        loading={loading}
      >
        {children || <span>{t(title || '')}</span>}
      </BtnContent>
    </button>
  );
}


export default Button;