import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import React, {FC, useState} from "react";
import Modal from "react-responsive-modal";
import {ReactSVG} from "react-svg";
import close_svg from "../../assets/icons/close.svg";
import PrivacyPolicies from "../../pages/LegalInformationPage.tsx/pages/UnderagePolicy";
import TermsOfServicePage from "../../pages/LegalInformationPage.tsx/pages/TermsOfServicePage";
import CookiesPolicy from "../../pages/LegalInformationPage.tsx/pages/CookiesPolicy";
import {useTranslation} from "react-i18next";


interface ConfirmModalProps {
  resolve(): void;
}

const Warning18Modal: FC<ConfirmModalProps> = ({resolve}) => {
  const {t} = useTranslation();
  const [modalVisible, setModalVisible] = useState('');

  const onSubmit = () => {
    localStorage.setItem('confirm18+', '1');
    resolve();
  }

  return (
    <div className='modal-container modal-gender-settings'>
      <h4 className='text-danger mb-4'>{t('ADULT_ONLY')} <span className='badge-18'>(18+)</span></h4>
      <div className='text-bold mb-3'>{t('ADULT_ONLY_TITLE')}</div>
      <div className='text-dark-14 mb-4 pb-2'>
        {t('ADULT_ONLY_SUBTITLE')} <br/>
        {t('ADULT_ONLY_TEXT')}
      </div>
      <div className='d-flex flex-column gap-2'>
        <div onClick={() => setModalVisible('privacy-policy')} className='text-14 link'>{t('PRIVACY_POLICY')}</div>
        <div onClick={() => setModalVisible('terms-of-service')} className='text-14 link'>{t('TERMS_OF_SERVICE')}</div>
        <div onClick={() => setModalVisible('cookies-policy')}
           className='text-14 link'>{t('COOKIE')}</div>
      </div>
      <div className='modal-footer'>
        <Button onClick={onSubmit} title={'I_AM_18'}/>
      </div>
      <Modal open={Boolean(modalVisible)} onClose={() => setModalVisible('')} showCloseIcon={false} center>
        <div className='modal-container big my-4'>
          <ReactSVG src={close_svg} className='modal-close' onClick={() => setModalVisible('')}/>
          {modalVisible === 'privacy-policy' && <PrivacyPolicies />}
          {modalVisible === 'terms-of-service' && <TermsOfServicePage /> }
          {modalVisible === 'cookies-policy' &&  <CookiesPolicy />}
        </div>
      </Modal>
    </div>
  );
};

const openWarning18Modal = (): Promise<boolean> => {
  return asyncModal(Warning18Modal, {}, {showCloseIcon: false, center: true, closeOnOverlayClick: false});
};

export {openWarning18Modal};
