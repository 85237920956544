import React, {FC, useLayoutEffect} from 'react';
import {observer} from "mobx-react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Button from "../../components/Button";
import {ReactSVG} from "react-svg";
import success_svg from './assets/icons/success.svg';
import failed_svg from './assets/icons/failed.svg';
import {HeaderSecondary} from "../../containers/Header";

interface Props {
}

const PaymentStatusPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate()
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const status = query.get('status');
  const errorText = query.get('error');

  useLayoutEffect(() => {
    if (status === 'success') {
      const type = query.get('type');
      if (type === 'cred') {
        //@ts-ignore
        ym(97677527, 'reachGoal', 'submit6');
      } else if (type === 'sub') {
        //@ts-ignore
        ym(97677527, 'reachGoal', 'submit4')
      }
    }
    if (!status) navigate('/', {replace: true});
  }, [])

  return (
    <>
      <HeaderSecondary back title='PAYMENT' onClick={() => navigate('/', {replace: true})}/>

      <div className='container'>
        <h2 className='page-title'>{t('PAYMENT')}</h2>
        {status === 'success' || status === 'failed' || status === 'pending'
          ?
          <div className={`payment-item ${status}`}>
            {status === 'success' &&
              <>
                <h6 className='d-flex align-items-center'>
                  <ReactSVG src={success_svg} className='react-icon me-2'/>
                  <span className='text-success'>{t('PAYMENT_SUCCESS')}</span>
                </h6>
                <div className='text-dark mt-2'>{t('PAYMENT_SUCCESS_TEXT')}</div>
              </>
            }
            {status === 'pending' &&
              <h6 className='d-flex align-items-center text-dark'>{t('PAYMENT_PENDING')}</h6>
            }
            {status === 'failed' &&
              <>
                <h6 className='d-flex align-items-center'>
                  <ReactSVG src={failed_svg} className='react-icon me-2'/>
                  <span className='text-danger'>{t('PAYMENT_FAILED')}</span>
                </h6>
                {errorText ? <div
                  className='text-dark mt-2 letter-uppercase'>{decodeURIComponent(errorText).replaceAll('+', ' ')}</div> : null}
              </>
            }
            <div className='modal-footer w-100'>
              <Button
                btnType={'secondary'}
                href='/'
                title='HOME'
              />
            </div>
          </div>
          :
          null
        }
      </div>
    </>
  );
})

export default PaymentStatusPage;