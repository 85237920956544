import React, {FC, useEffect, useState} from 'react';
import {thumbAvatar} from "../../modules/utils";
import Avatar from "../Avatar";
import {IMessage} from "../../modules/rest";
import Button from "../Button";
//@ts-ignore
import WavesurferPlayer from '@wavesurfer/react'
import PlayBtn from "../PlayBtn";

const formatTime = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs.toString().padStart(2, '0')}`;
};

interface Props {
  data?: IMessage;
  avatarId?: string;
  hideAvatar?: boolean;
  activeVoice?: boolean;
  onClick: () => void;
}

const BoardVoice: FC<Props> = ({data, hideAvatar, avatarId, activeVoice, onClick}) => {
  const [wavesurfer, setWavesurfer] = useState<any>()
  const [currentTime, setCurrentTime] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (!activeVoice && isPlaying) {
      wavesurfer?.pause();
    }
  }, [activeVoice]);

  const onReady = (ws: any) => {
    setWavesurfer(ws)
    setIsPlaying(false);
  }

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause()
  }

  const handleTimeUpdate = () => {
    setCurrentTime(wavesurfer.getCurrentTime());
  };

  const handleClick = () => {
    if (activeVoice) {
      onPlayPause();
    } else {
      wavesurfer?.play();
      onClick();
    }
  }

  return (
    <div
      className={`message-container${hideAvatar ? ' hide-avatar' : ''} model`}
      id={`message_${data?.id}`}>
      <div className="message pt-1">
        <div className='d-flex align-items-center p-1 pe-2 me-1'>
          <PlayBtn onClick={handleClick} isPlaying={isPlaying}/>
          <div className='message-voice-container'>
            <div className='position-relative' style={{top: -4}}>
              <div className='message-wavesurfer'>
                <WavesurferPlayer
                  height={16}
                  waveColor="#525252"
                  progressColor='#fff'
                  cursorColor={'transparent'}
                  // progressColor='rgba(255, 255, 255, 1)'
                  // cursorColor='red'
                  url={data?.voice?.url}
                  // url={track}
                  onTimeupdate={handleTimeUpdate}
                  onReady={onReady}
                  onPlay={() => setIsPlaying(true)}
                  onPause={() => setIsPlaying(false)}
                  // barWidth={4}
                  // barRadius={5}
                  // barGap={4}
                />
              </div>
            </div>
            <div className='text-dark text-12 position-relative' style={{height: 18, top: 8}}>
              {wavesurfer ? formatTime(currentTime || wavesurfer?.getDuration()) : null}
            </div>
          </div>
        </div>
        {data?.ad
          ?
          <div className='mt-3 d-flex justify-content-start'>
            <Button
              href={data.ad.buttonUrl || ''}
              target={'_blank'}
              size='xs'
            >
              {data.ad.buttonText}
            </Button>
          </div>
          :
          null
        }
      </div>
      {!hideAvatar && <Avatar image={thumbAvatar(avatarId, 128)} size={'xs'}/>
      }
    </div>
  );
}

export default BoardVoice;
