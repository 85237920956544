import React, {FC, useEffect, useRef, useState} from 'react';
import {observer} from 'mobx-react';
import PremiumBenefits from './components/PremiumBenefits';
import {ReactSVG} from 'react-svg';
import shield from '../../../../assets/icons/shield.svg';
import {
  EPaymentType,
  ETransactionStatus,
  IAbstractPricingItem,
  ICreditsPricingItem,
  ISavedPaymentMethod,
  ISubscriptionPricingItem, ITransaction,
} from '../../../../modules/rest';
import {benefitsCredits, benefitsSubscription} from './modules/utils';
import SubscriptionsPlanItem from './components/SubscriptionsPlanItem';
import CreditsPlanItem from './components/CreditsPlanItem';
import {HeaderSecondary} from '../../../../containers/Header';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Button from '../../../../components/Button';
import {API} from '../../../../modules/api';
import {PaymentCardLogo} from "../../../../containers/ListPaymentCard";
import {toast} from "react-toastify";
import {openPaymentMethodCards} from "../../../../modals";
import PremiumTransaction from "./components/PremiumTransaction";
import AppStore from "../../../../store/AppStore";
import {isSub} from "../../../../modules/utils";

const paymentData: any = {
  credits: {
    title: 'EXCLUSIVE_PACKAGE',
    subTitle: 'EXCLUSIVE_PACKAGE_TITLE',
    benefitsTitle: 'TOKEN_BENEFITS',
    benefits: benefitsCredits,
  },
  subscription: {
    title: 'CHOOSE_PLAN',
    subTitle: 'PREMIUM_TITLE',
    benefitsTitle: 'TOKEN_BENEFITS',
    benefits: benefitsSubscription,
  },
};

interface Props {
}

const PremiumPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const _timer: any = useRef(null);
  const {paymentType = EPaymentType.Subscription} = useParams();
  const data = paymentData[paymentType as EPaymentType.Subscription];

  const [ready, setReady] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [product, setProduct] = useState<IAbstractPricingItem>();
  const [subProducts, setSubProducts] = useState<ISubscriptionPricingItem[]>([]);
  const [credProducts, setCredProducts] = useState<ICreditsPricingItem[]>([]);
  const [savedCards, setSavedCards] = useState<ISavedPaymentMethod[]>();
  const [defaultCard, setDefaultCard] = useState<ISavedPaymentMethod>();
  const [transaction, setTransaction] = useState<{ transaction: ITransaction; redirect: string }>();

  useEffect(() => {
    if (isSub(AppStore.user?.subscription?.status)) {
      navigate('/premium/credits', {replace: true});
    }
    return () => {
      clearTimeout(_timer?.current)
    }
  }, []);

  useEffect(() => {
    if (!data) navigate('/', {replace: true});
    else {
      fetchProducts();
    }
  }, [paymentType]);

  useEffect(() => {
    fetchSavedCards()
  }, []);

  const fetchSavedCards = async () => {
    try {
      const res = await API.SavedPaymentMethods.getSavedMethods();
      setSavedCards(res);
      setDefaultCard(res.find(card => card.isDefault));
    } catch (e) {

    } finally {
      setReady(true)
    }
  }

  const fetchProducts = () => {
    if (paymentType === 'subscription') {
      API.Pricing.getSubscriptionPricing().then((products) => {
        setSubProducts(products);
        setProduct(products.find((p) => p.isRecommended)!);
      });
    } else {
      API.Pricing.getCreditsPricing().then((products) => {
        setCredProducts(products);
        setProduct(products.find((p) => p.isRecommended)!);
      });
    }
  };

  const handlePay = async () => {
    if (btnLoading) return;
    setBtnLoading(true);
    try {
      const res = await API.Subscriptions.purchase(product!.id, {method: defaultCard!.id});
      setTransaction(res);
      if (res.transaction.status === ETransactionStatus.Failed) {
        toast.error(t('PAYMENT_FAILED'));
      }
      _timer.current = setTimeout(() => setTransaction(undefined), 2500);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setBtnLoading(false);
    }
  }

  const handleSelectPayMethod = async () => {
    openPaymentMethodCards(savedCards, defaultCard?.id).then((cardId) => {
      if (!cardId) return;
      if (cardId === 'new') {
        navigate(String(product?.id))
      } else {
        const card = savedCards?.find(card => card.id === cardId);
        if (card) {
          setDefaultCard(card);
        }
      }
    })
  }

  if (!data) return null;

  return (
    <>
      <HeaderSecondary title={data.title} coins onClick={() => navigate(-1)} back/>
      <div className="container premium-page">
        <h2 className="page-title">{t(data.title)}</h2>
        {paymentType === 'subscription' && (
          <div className="text-muted text-center d-none d-md-block mt-3 mb-4">{t('PREMIUM_TEXT')}</div>
        )}
        <div className="premium-container">
          <h6 className="text-center" dangerouslySetInnerHTML={{__html: t(data.subTitle) || ''}}/>
          {paymentType === 'subscription' && (
            <div className="text-muted text-center  d-block d-md-none mb-3">{t('PREMIUM_TEXT')}</div>
          )}
          <PremiumBenefits title={data.benefitsTitle} data={data.benefits}/>
          {!ready
            ?
            null
            :
            <>
              <div className={`row g-2 g-md-4 pt-4${btnLoading ? ' pointer-event' : ''}`}>
                {paymentType === 'subscription' &&
                  subProducts.map((p) => (
                    <SubscriptionsPlanItem key={p.id} onClick={() => setProduct(p)} data={p} active={p === product}/>
                  ))}
                {paymentType === 'credits' &&
                  credProducts.map((p) => (
                    <CreditsPlanItem key={p.id} onClick={() => setProduct(p)} data={p} active={p === product}/>
                  ))}
              </div>
              {paymentType === 'subscription' && (
                <div className="text-dark-14 d-flex align-items-center justify-content-center mt-3">
                  <ReactSVG src={shield} className="react-icon me-1"/>
                  {t('NO_ADULT')}
                </div>
              )}
              <PremiumTransaction
                transaction={transaction}
                loading={btnLoading}
                card={defaultCard}
              />
              {btnLoading || transaction
                ?
                null
                :
                defaultCard
                  ?
                  <>
                    <Button
                      loading={btnLoading}
                      className="modal-footer w-100"
                      onClick={handlePay}
                      // icon={card}
                      title="PAY"
                      style={{maxWidth: 460}}
                    >
                      <span>{t('PAY_WITH')}:</span>
                      <div className='premium-btn-card-info'>
                        <PaymentCardLogo type={defaultCard.type}/> {defaultCard.name}
                      </div>
                    </Button>
                    <div onClick={handleSelectPayMethod}
                         className='text-tint text-center mt-3 text-bold cursor-pointer'>{t('OTHER_PAYMENT_METHOD')}</div>
                  </>
                  :
                  <Button
                    className="modal-footer w-100"
                    href={String(product?.id)}
                    title="PAY"
                    style={{maxWidth: 460}}
                  />
              }
            </>
          }

          {paymentType === 'subscription' && (
            <div className="text-dark-14 mt-3 text-center">
              {(product as ISubscriptionPricingItem)?.period > 1 ? (
                <div>
                  {t('PLAN_TEXT_' + (product as ISubscriptionPricingItem).period, {
                    amount: (product as ISubscriptionPricingItem).priceFormatted,
                  })}
                </div>
              ) : null}
              <div>{t('CANCEL_ANYTIME')}</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default PremiumPage;
