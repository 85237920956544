import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import image from "../../assets/icons/image.svg";
import Avatar from "../Avatar";
import {thumbAvatar} from "../../modules/utils";

interface Props {
  avatarId?: string;
}

const MessageUploading: FC<Props> = ({avatarId}) => {
  return (
    <div className='message-container'>
      <div className={`message-uploading model`}>
        <ReactSVG src={image} className='react-icon'/>
        <div className="message-uploading-progress"/>
      </div>
      {avatarId ? <Avatar image={thumbAvatar(avatarId, 128)} size={'xs'}/> : null}
    </div>
  );
}

export default MessageUploading;
