import asyncModal from 'react-async-modal';
import React, {FC, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {ESender, IConversation, IMessage} from "../../modules/rest";
import {API} from "../../modules/api";
import Message from "../../components/Message";
import {thumb, thumbAvatar} from "../../modules/utils";
import Avatar from "../../components/Avatar";
import play_active from '../../assets/icons/play_active.svg';
import play from '../../assets/icons/play.svg';
import {ReactSVG} from "react-svg";
import Button from "../../components/Button";
import {toast} from "react-toastify";
import AppStore from "../../store/AppStore";
import BlurComponent from "../../components/BlurComponent";
import {openGalleryModal} from "../GalleryModal";
import expand from "../../assets/icons/expand.svg";
import BoardMessage from "../../components/Message/BoardMessage";

interface ConfirmModalProps {
  resolve(data?: {type?: number |'auth' | 'premium', cloneChat?: boolean, conversationId?: number}): void;

  conversation: IConversation;
}

const ShareChatModal: FC<ConfirmModalProps> = ({resolve, conversation}) => {
  const _scroll: any = useRef(null);
  const _ping: any = useRef(null);
  const {t} = useTranslation();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<IMessage[]>();
  const [visible, setVisible] = useState(false);
  const [visibleTrigger, setVisibleTrigger] = useState(false);

  useEffect(() => {
    fetch()
    return () => {
      clearTimeout(_ping.current);
    }
  }, []);

  const fetch = async () => {
    try {
      const res = await API.Conversations.getMessages(conversation?.id, {limit: 100}, []);
      setMessages(res);
      setReady(true);
      _scroll.current?.scrollTo({top: 999999, behavior: 'instant'})
    } catch (e) {

    }
  }

  const handleVisible = () => {
    setVisible(prevState => {
      if (prevState) {
        setVisibleTrigger(true);
        clearTimeout(_ping.current);
        _ping.current = setTimeout(() => {
          setVisibleTrigger(false);
        }, 100);
      }
      return true;
    })
  }

  const handleSubmit = async () => {
    if (!AppStore.user?.id) return resolve({type: 'auth', cloneChat: true})

    setLoading(true);
    if (loading) return;
    try {
      const res = await API.Conversations.cloneShared(conversation.id);
      resolve({conversationId: res.id});
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false)
    }
  }
  const handleClose = () => {
    resolve();
  }

  return (
    <div className='modal-container share-chat-modal'>
      <div className='share-chat-modal-board-head-container'>
        <div className="share-chat-modal-board-head">
          <Avatar image={thumbAvatar(conversation.model?.mainPhotoImage?.id, 128)} size='sm'/>
          <div className='ps-3 flex-1 pe-3'>
            <div><span className='text-bold'>{conversation?.model?.name}</span> {conversation?.model?.age}</div>
            <div className='text-14'><span className='text-dark'>{conversation.title}</span> <span
              className='text-tint text-nowrap'>{t('BY', {author: `@${conversation.authorName}`})}</span></div>
          </div>
          <Button
            icon={play}
            size='sm'
            title={'CONTINUE_CHAT'}
            onClick={handleVisible}
          />
        </div>
        <div className="share-chat-modal-close" onClick={handleClose}/>
      </div>
      <div className='share-chat-modal-chat'>
        <div className="chat-board" ref={_scroll}>
          {messages?.map((item, i) => (
            <BoardMessage
              onPremiumNavigate={() => resolve({type: 'premium'})}
              handleAuth={() => resolve({type: 'auth'})}
              userStub
              avatarId={conversation.model?.mainPhotoImage?.id}
              data={item}
              key={item.id}
              hideAvatar={item.sender === messages[i + 1]?.sender || (i === messages.length - 1 && item.sender === ESender.Model)}
            />

          ))}
        </div>
      </div>
      <div className="share-chat-modal-footer" onClick={() => setVisible(true)}>
        <ReactSVG src={play_active} className='react-icon'/>
        <div className='text-tint text-semibold ms-2'>{t('CONTINUE_CHAT')}</div>
      </div>
      {ready &&
        <div
          className={`share-chat-modal-confirm${visible ? ' visible' : ''}${visibleTrigger ? ' visible-trigger' : ''}`}>
          <div className="share-chat-modal-confirm-close" onClick={() => setVisible(false)}/>
          <div className='d-flex align-items-center'>
            <ReactSVG src={play} className='react-icon'/>
            <div className='text-bold ms-2'>{t('CONTINUE_CHATTING')}</div>
          </div>
          <p className='text-dark'>{t('CONTINUE_CHAT_TEXT')}</p>
          <div className='share-chat-modal-confirm-footer'>
            <Button
              size='md'
              onClick={() => setVisible(false)}
              title={'CANCEL'}
              btnType='secondary'
            />
            <Button
              size='md'
              title={'CONFIRM_CHAT'}
              onClick={handleSubmit}
              loading={loading}
            />
          </div>
        </div>
      }
    </div>
  );
};

const openShareChatModal = (conversation: IConversation): Promise<{type: number |'auth' | 'premium', cloneChat?: boolean, conversationId?: number}> => {
  return asyncModal(ShareChatModal, {conversation}, {
    showCloseIcon: false,
    center: true,
    blockScroll: false,
    classNames: {modalContainer: 'bottom-modal_media'}
  });
};

export {openShareChatModal};
