import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EFieldGroup, EModelStyle, IConversation, IGetConversationsRequest, IPagedData} from "../modules/rest";
import {API} from "../modules/api";
import {toast} from "react-toastify";

class SharedStore {
  ready: boolean = false;
  loading: boolean = false;
  request: IGetConversationsRequest = {page: 1, limit: 24};
  pager?: IPagedData<IConversation>;

  constructor() {
    makeAutoObservable(this, {
      ready: observable,
      loading: observable,
      pager: observable,
      request: observable,

      fetch: action,
      onPageChange: action,
      onCategoryStyle: action,
    });

  }

  fetch = async (startLoading?: boolean) => {
    runInAction(() => {
      this.loading = startLoading || false;
    })
    try {
      const res = await API.Conversations.getSharedList(this.request, [EFieldGroup.ModelFull])
      runInAction(() => {
        this.pager = res;
      });
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => {
        this.ready = true;
        this.loading = false;
      })
    }
  };

  onPageChange = (page: number) => {
    runInAction(() => {
      this.loading = true;
      this.request.page = page;
      this.fetch(true);
      window.scrollTo({top: 0, behavior: 'smooth'})
    })
  }

  onCategoryStyle = async (style?: EModelStyle) => {
    if (style === this.request.style) return;
    runInAction(() => {
      this.loading = true;
      this.request.style = style;
      this.request.page = 1;
    })
    this.fetch(true);
    window.scrollTo({top: 0, behavior: 'smooth'})
  }
}

export default new SharedStore();