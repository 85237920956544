import React, {FC, useEffect, useRef, useState} from 'react';
import {IVoice} from "../../../../modules/rest";
import {useTranslation} from "react-i18next";
import Button from "../../../../components/Button";
import PlayBtn from "../../../../components/PlayBtn";

interface Props {
  voices?: IVoice[];
  voiceId: number | null;
  onChange: (voiceId: number) => void;
}

const Step5Voice: FC<Props> = ({voiceId, onChange, voices}) => {
  const {t} = useTranslation();
  const audio: any = useRef(null);
  const [activeVoiceId, setActiveVoiceId] = useState<number|null>();

  useEffect(() => {
    if (voices?.length) {
      onChange(voices[0].id);
    }
    return () => {
      stopVoice();
    }
  }, []);

  const handleAudioEnded = () => {
    setActiveVoiceId(null);
  };

  const handleClick = (voice: IVoice) => (e: any) => {
    e.stopPropagation();
    setActiveVoiceId(prevState => {
      const isPlaying = prevState === voice.id ? null : voice.id;
      const available = playVoice(voice, Boolean(isPlaying));
      return available ? isPlaying : null;
    });
  }

  const playVoice = (voice: IVoice, isPlaying: boolean): boolean => {
    stopVoice();
    if (!isPlaying) {
      return false;
    }
    let voiceUrl = voice.sample.url;
    if (voiceUrl) {
      audio.current = new Audio(voiceUrl);
      audio.current.play();
      audio.current.addEventListener('ended', handleAudioEnded);
      return true
    }
    return false
  }

  const stopVoice = () => {
    audio?.current?.pause();
    audio?.current?.removeEventListener('ended', handleAudioEnded);
    audio.current = null;
  }

  if (!voices?.length) return null;

  return (
    <div className="card-dark create-ai-inner-card create-ai-voice">
      <h6 className='text-center'>{t('CHOOSE_VOICE')}</h6>
      <div className='d-flex justify-content-center mt-3 mt-md-4 flex-wrap gap-2'>
        {voices.map((voice, i) => (
          <Button
            key={voice.id}
            className={`${voice.id === voiceId ? 'active' : ''}${voice.id === activeVoiceId ? ' playing' : ''}`}
            size='md'
            btnType='third'
            onClick={() => onChange(voice.id)}
          >
            <PlayBtn onClick={handleClick(voice)} isPlaying={voice.id === activeVoiceId}/>
            <div>{t('VOICE')} {i + 1}</div>
          </Button>
        ))}
      </div>
    </div>
  );
}

export default Step5Voice;
