import React, {FC, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import ListPaymentCard from "../../../containers/ListPaymentCard";
import {ISavedPaymentMethod} from "../../../modules/rest";
import {API} from "../../../modules/api";
import {observer} from "mobx-react";
import {confirmDialog} from "../../../modals";
import {toast} from "react-toastify";
import Loadable from "../../../components/Loadable";

interface Props {
}

const PremiumCards: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [savedCards, setSavedCards] = useState<ISavedPaymentMethod[]>();

  useEffect(() => {
    getSavedCards()
  }, []);

  const getSavedCards = () => {
    API.SavedPaymentMethods.getSavedMethods().then(setSavedCards);
  }

  const handleUpdateDefault = (card: ISavedPaymentMethod) => async () => {
    if (card.isDefault) return;

    try {
      setLoading(true);
      await API.SavedPaymentMethods.setDefault(card.id);
      getSavedCards();
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false)
    }
  }

  const handleRemove = (card: ISavedPaymentMethod) => async () => {
    const confirmed = await confirmDialog(t('DELETING_CARD_CONFIRM_TEXT', {cardNumber: card.name}), {
      title: t('DELETING_CARD')!,
      cancelText: 'NO',
    });
    if (confirmed) {
      try {
        setLoading(true);
        await API.SavedPaymentMethods.delete(card.id);
        getSavedCards()
      } catch (e: any) {
        toast.error(e)
      } finally {
        setLoading(false)
      }
    }
  }

  return (
    <>
      {savedCards?.length ?
        <>
          <div className='d-flex justify-content-between mt-3'>
            <div className='text-dark-14'>{t('PAYMENT_METHOD')}</div>
          </div>
          <Loadable loading={loading} className='d-flex flex-column gap-2 mt-2'>
            {savedCards.map((savedCard) => (
              <div className='d-flex align-items-center w-100' key={savedCard.id}>
                <ListPaymentCard
                  className='w-100'
                  card={savedCard}
                  active={savedCard.isDefault}
                  onClick={handleUpdateDefault(savedCard)}
                  onRemove={handleRemove(savedCard)}
                />
              </div>
            ))}
          </Loadable>
        </>
        :
        null
      }

    </>
  );
})

export default PremiumCards;