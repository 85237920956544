import React, {FC} from 'react';
import Button from "../../components/Button";
import {useTranslation} from "react-i18next";


interface Props {
  position: 'create-ai'|'chat';
  onClick: () => void;
}

const ModalNoMoneyCoins: FC<Props> = ({onClick, position}) => {
  const {t} = useTranslation();

  if (position === 'chat') return (
    <div className="modal-with_img-content with-bg">
      <div className="modal-with_img-content-bg" />
      <div className="modal-with_img-content-bg-2" />
      <div className='position-relative z-1'>
        <h4 className='mb-3' dangerouslySetInnerHTML={{__html: t('NO_MONEY_TITLE_3') || ''}}/>
        <div className='text-bold pb-3'>{t('NO_MONEY_SUBTITLE_3')}</div>

        <div className='modal-footer'>
          <Button
            onClick={onClick}
            iconAbsolute
            className='w-100'
            iconText={'💎'}
            title='GET_MORE_GEMS'
            btnType='white'
          />
        </div>
      </div>
    </div>
  )
  return (
    <div className="modal-with_img-content with-bg">
      <div className="modal-with_img-content-bg" />
      <div className="modal-with_img-content-bg-2" />
      <div className='position-relative z-1'>
        <h4 className='mb-3' dangerouslySetInnerHTML={{__html: t('NO_MONEY_TITLE_4') || ''}}/>
        <div className='text-bold'>{t('NO_MONEY_SUBTITLE_4')}</div>
        <div className='modal-footer'>
        {/*<div >*/}

          <Button
            iconAbsolute
            onClick={onClick}
            className='w-100'
            iconText={'💎'}
            title='GET_MORE_GEMS'
            btnType='white'
          />

        </div>
      </div>
    </div>
  );
}

export default ModalNoMoneyCoins;