import React, {FC, useState} from 'react';
import {EGeneratePromptTabs, getPosesArr, promptsByType, TTemplatePrompt} from "../modules/utils";
import {EGender, EPose, ESubscriptionStatus} from "../../../modules/rest";
import Button from "../../../components/Button";
import plus from '../../../assets/icons/plus_16.svg';
import check from '../../../assets/icons/check_active.svg';
import diamond from '../../../assets/icons/diamond.svg';
import {ReactSVG} from "react-svg";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import {openGenerateImagePromptModal, openModalNoMoney} from "../../../modals";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import GeneratePromptSelectorList from "./GeneratePromptSelectorList";


interface Props {
  gender?: EGender;
  prompt: string;
  pose?: EPose;
  onPoseChange: (pose: EPose) => void;
  onChange: (prompt: string) => void;
}

const GeneratePromptSelector: FC<Props> = observer(({
                                                      prompt,
                                                      onChange,
                                                      gender,
                                                      pose,
                                                      onPoseChange,
                                                    }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<EGeneratePromptTabs>(EGeneratePromptTabs.Poses);

  const handleTabClick = (tab: EGeneratePromptTabs) => () => {
    // if (window.innerWidth < 768) {
    //   openGenerateImagePromptModal({tab, prompt, gender, pose}).then(res => {
    //     if (res?.type === 'premium') {
    //       handlePremiumClick();
    //     } else if (res?.type === 'prompt') {
    //       onChange(res?.data?.prompt!)
    //       onPoseChange(res?.data?.pose!);
    //     }
    //   })
    // } else {
    //   setActiveTab(tab);
    // }
    setActiveTab(tab);
  }
  const handlePremiumClick = () => {
    openModalNoMoney('create-ai').then(path => {
      if (path) navigate(path)
    });
  }


  return (
    <div className='card-dark bdrs-24 p-1 mt-3'>
      <div className="generate-prompt-tabs">
        {Object.keys(EGeneratePromptTabs).map(tab => (
          <div
            key={tab}
            className={`generate-prompt-tab${tab === activeTab ? ' active' : ''}`}
            onClick={handleTabClick(tab as EGeneratePromptTabs)}
          >
            {t(tab.toUpperCase())}
          </div>
        ))}
      </div>
      <GeneratePromptSelectorList
        activeTab={activeTab}
        gender={gender}
        pose={pose}
        onPoseChange={onPoseChange}
        prompt={prompt}
        onChangePrompt={onChange}
        handlePremiumClick={handlePremiumClick}
      />
    </div>
  );
})

export default GeneratePromptSelector;