import React, {FC, useEffect} from 'react';
import {ETransactionStatus, ISavedPaymentMethod, ITransaction} from "../../../../../modules/rest";
import {PaymentCardLogo} from "../../../../../containers/ListPaymentCard";
import {Spinner} from "../../../../../components/Loadable";
import {ReactSVG} from "react-svg";
import failed_svg from "../../../../PaymentStatusPage/assets/icons/failed.svg";
import success_svg from "../../../../PaymentStatusPage/assets/icons/success.svg";

interface Props {
  transaction?: { transaction: ITransaction; redirect: string };
  loading: boolean;
  card?: ISavedPaymentMethod;
}

const PremiumTransaction: FC<Props> = ({card, loading, transaction}) => {

  useEffect(() => {
    let _timer: any;
    if (transaction?.redirect && transaction.transaction.status !== ETransactionStatus.Failed) {
      _timer = setTimeout(() => {
        window.location.href = transaction?.redirect;
      }, 2000)
    }
    return () => {
      clearTimeout(_timer);
    }
  }, [transaction?.redirect]);

  if (!card) return null;
  if(!loading && !transaction ) return null;

  const status = transaction?.transaction?.status || '';
  return (
    <div className={`premium-transaction ${status}`}>
      <PaymentCardLogo type={card.type}/>
      <div className='text-bold-14 flex-1'>{card.name}</div>
      {loading && <Spinner loading/>}
      {status === ETransactionStatus.Success && <ReactSVG src={success_svg} className='react-icon'/>}
      {status === ETransactionStatus.Failed && <ReactSVG src={failed_svg} className='react-icon'/>}
    </div>
  );
}

export default PremiumTransaction;