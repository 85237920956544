import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import Avatar from "../../components/Avatar";
import AppStore from "../../store/AppStore";
import {formatDate, getLanguageName, isSub, thumb} from "../../modules/utils";
import SettingsItem from "./components/SettingsItem";
import {
  confirmDialog,
  openGenderModal,
  openLanguageModal,
  openNicknameModal,
  openPasswordModal,
  openPaymentManageModal
} from "../../modals";
import Button from "../../components/Button";
import trash from '../../assets/icons/trash.svg';
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import {useNavigate} from "react-router-dom";
import {HeaderSecondary} from "../../containers/Header";
import logout_svg from "../../assets/icons/logout_white.svg";
import diamond_svg from "../../assets/icons/diamond.svg";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import {InputFile} from "../../components/FormControls";
import {IAsset} from "../../modules/rest";

interface Props {
}

const SettingsPage: FC<Props> = observer(() => {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const user = AppStore.user;

  useEffect(() => {
    if (AppStore.ready && !AppStore.user?.id) navigate('/', {replace: true})
  }, [AppStore.ready]);

  const onDeleteAcc = async () => {
    const confirmed = await confirmDialog(t('Confirm_message_delete_acc'), {
      title: t('DELETE_ACC') || '',
      icon: trash,
      cancelText: 'NO_STAY',
      confirmText: 'YES_DELETE',
    })
    if (confirmed) {
      try {
        setLoading(true);
        await API.Users.deleteAccount();
        AppStore.logout();
        navigate('/');
      } catch (e: any) {
        toast.error(e);
      } finally {
        setLoading(false);
      }
    }
  }

  const handleSubManage = async () => {
    openPaymentManageModal();
  }

  const handleLogout = () => {
    AppStore.logout();
    navigate('/');
  }

  const handleChangeAvatar = async (image?: IAsset|null) => {
    try {
      await AppStore.updateUser({avatarId: image?.id || null});
    } catch (e: any) {
      toast.error(e)
    }
  }

  return (
    <>
      <HeaderSecondary onClick={() => navigate(-1)} title='PROFILE_SETTINGS' back>
        <Button
          icon={logout_svg}
          btnType='secondary'
          onClick={handleLogout}
        />
      </HeaderSecondary>
      {AppStore.ready &&
        <div className='container settings-page'>
          <h2 className='page-title'>{t('PROFILE_SETTINGS')}</h2>
          <div className="card settings-card">
            <div className='d-flex position-relative flex-column flex-sm-row'>
              <InputFile
                className='settings-avatar'
                onChange={handleChangeAvatar}
                value={user?.avatar}
                accept="image/jpeg/png"
              >
                <Avatar image={thumb(user?.avatar?.id, 204)} user size='xl'/>
              </InputFile>
              <div className='settings-items'>
                <SettingsItem label='NICKNAME' value={user?.name} onClick={openNicknameModal}/>
                <SettingsItem label='GENDER' value={t(user?.gender?.toUpperCase() || '')} onClick={() => openGenderModal(user?.gender)}/>
                <SettingsItem label='PASSWORD' value={'*********'}
                              onClick={openPasswordModal}
                  // onClick={() => openModalNoMoney('create-ai')}
                />
                <SettingsItem label='E-MAIL' value={user?.email}/>
                <SettingsItem label='LANGUAGE' value={getLanguageName(i18n.language)}
                              onClick={openLanguageModal}
                  // onClick={openLanguageModal}
                />
              </div>
            </div>
            {/*<div className='settings-notifications'>*/}
            {/*  <Checkbox />*/}
            {/*  <div className='ps-3'>*/}
            {/*    <div className='text-bold'>Automatic Notifications</div>*/}
            {/*    <p className='text-dark-14 mt-2'>As a user, you will receive automatic notifications from us. If you don't want any notifications, uncheck the box by clicking on it.</p>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*<div className='d-flex mt-4 align-items-center w-100'>*/}
            {/*  <p className='flex-1 pe-4 text-dark-14 d-none d-sm-block'>{t('Remove_acc_text')}</p>*/}
            {/*  <Button*/}
            {/*    title='DELETE_ACC'*/}
            {/*    btnType='danger'*/}
            {/*    size={"sm"}*/}
            {/*    icon={trash}*/}
            {/*    onClick={onDeleteAcc}*/}
            {/*    loading={loading}*/}
            {/*    className='remove-acc-btn'*/}
            {/*  />*/}
            {/*</div>*/}
          </div>
          <div className="card p-2 bdrs-32 mt-4">
            <div className={`settings-sub-container${isSub(user?.subscription?.status) ? ' subscribed' : ''}`}>
              <div className="settings-sub-content">
                <div className='settings-sub-head'>
                  <div className='flex-1 text-bold d-flex align-items-center'>
                    <span>{t('CURRENT_PLAN')}</span>
                    {isSub(user?.subscription?.status)
                      ?
                      <div className='settings-sub'>
                        <ReactSVG src={diamond_svg} className='react-icon'/>
                        <span>{t('PREMIUM')}</span>
                      </div>
                      :
                      <span className='border-gradient-secondary'>
                        <span className='text-tint'>{t('FREE')}</span>
                    </span>
                    }
                  </div>
                  {!isSub(user?.subscription?.status)
                    ?
                    <Button
                      href='/premium/subscription'
                      title='CHANGE_PLAN'
                      btnType='secondary'
                      size={"sm"}
                      className='mt-3 mt-sm-0'
                    />
                    :
                    <div onClick={handleSubManage}
                         className='text-tint text-14 cursor-pointer text-end'>{t('MANAGE_SUB')}</div>
                  }
                </div>
                {isSub(user?.subscription?.status)
                  ?
                  <div className='settings-sub-info text-dark-14'>
                    <div>{t('PAYMENT_DATE')}: {formatDate(user?.subscription?.updatedAt)}</div>
                    <div>{t('EXPIRED_DATE')}: {formatDate(user?.subscription?.expiresAt)} </div>
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
})

export default SettingsPage;