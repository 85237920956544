import React, {FC, useEffect,  useRef, useState} from 'react';
import Button from "../Button";
import chevron_left from "../../assets/icons/chevron_left.svg";
import chevron_right from "../../assets/icons/chevron_right.svg";

interface Props {
  children: any;
  className?: string;
  secondary?: boolean
  trigger?: any
}

const HorizontalScroll: FC<Props> = ({children, className, secondary, trigger}) => {
  const _container: any = useRef(null);
  const _scroll: any = useRef(null);
  const [arrowLeft, setArrowLeft] = useState(false);
  const [arrowRight, setArrowRight] = useState(false);

  useEffect(() => {
      if (_scroll.current.scrollWidth > _container.current?.clientWidth) {
        setArrowRight(true);
      }
    _scroll?.current?.scrollTo({left: 0, behavior: "smooth"});
  }, [trigger]);

  const onArrowClick = (dir: 1|-1) => () => {
    _scroll?.current?.scrollTo({left: _scroll.current.scrollLeft + (300 * dir), behavior: "smooth",});
  }
  const handleScroll = () => {
    setArrowLeft(_scroll.current.scrollLeft > 0);
    setArrowRight((_scroll.current.scrollWidth - _scroll.current.offsetWidth - 8) > _scroll.current.scrollLeft);
  }

  return (
    <div className={`horizontal-scroll-container${arrowLeft ? ' show-arrow-left' : ''}${arrowRight ? ' show-arrow-right' : ''}${secondary ? ' horizontal-scroll-secondary' : ''} ${className || ''}`} ref={_container}>
      <div className='horizontal-scroll' ref={_scroll} onScroll={handleScroll}>
        {children || null}
      </div>
      {arrowLeft &&
        <Button
          onClick={onArrowClick(-1)}
          className='horizontal-scroll-arrow arrow-left'
          circle
          icon={chevron_left}
          size='sm'
          btnType='secondary'
        />
      }
      {arrowRight &&
        <Button
          onClick={onArrowClick(1)}
          className='horizontal-scroll-arrow arrow-right'
          circle
          icon={chevron_right}
          size='sm'
          btnType='secondary'
        />
      }
    </div>
  );
}

export default HorizontalScroll;