import React, {FC} from 'react';
import {ReactSVG} from "react-svg";

interface Props {
  icon: any;
  title: string;
}

const PaymentPlatformIconsItem: FC<Props> = ({icon, title}) => {
  return (
    <div className='payment-platform-icons-item'>
      <ReactSVG src={icon} className='react-icon'/>
      <div className='payment-platform-icons-item-text'>{title}</div>
    </div>
  );
}

export default PaymentPlatformIconsItem;