import React, { FC } from 'react';
import { Radio } from '../../../../../components/FormControls';
import { useTranslation } from 'react-i18next';
import { ISubscriptionPricingItem } from '../../../../../modules/rest';

interface Props {
  data: ISubscriptionPricingItem;
  active: boolean;
  onClick: () => void;
}

const SubscriptionsPlanItem: FC<Props> = ({ data, active, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="col-12 col-sm-4">
      <div className={`premium-plan-item border-gradient-secondary${active ? ' active' : ''}`} onClick={onClick}>
        <div className="premium-plan-item-bg" />
        <div className="premium-plan-item-content">
          <div className="d-flex px-1 pt-1 align-items-center justify-content-between">
            <div className="premium-plan-item-diamond" />
            <div className="d-flex align-items-center pe-2">
              {data.isRecommended && <div className="premium-plan-item-recommended">{t('RECOMMENDED')}</div>}
              <Radio checked={active} />
            </div>
          </div>
          <div className="p-4 p-sm-3 p-md-4 d-flex d-sm-block align-items-center justify-content-between">
            <div>
              <div className="text-bold text-lowercase">
                {data.period} {t(data.period === 1 ? 'MONTH' : 'MONTHS')}
              </div>
              <div className="mt-2 text-dark-14 text-decoration-line-through">
                {t('PRICE_WAS', { price: data.oldMonthlyPriceFormatted })}
              </div>
            </div>
            <div className="text-end text-sm-start">
              <div className="border-gradient-secondary premium-plan-item-off">
                <span className="text-tint position-relative z-1">
                  {t('DISCOUNT_OFF', { discount: data.discount })}
                </span>
              </div>
              <div className="premium-plan-item-price">{data.monthlyPriceFormatted}</div>
              <div className="text-dark-14 mt-2">/ {t('MONTH')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsPlanItem;
