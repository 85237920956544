import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import StepFooter from "../StepFooter";
import {ICreateModelRequest } from "../../../../modules/rest";
import Button from "../../../../components/Button";
import {observer} from "mobx-react";
import AppStore from "../../../../store/AppStore";


interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step7: FC<Props> = observer(({onStepChange, data}) => {
  const [clothing, setClothing] = useState(data.clothing);

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? { clothing} : undefined);
  }

  const disabled =  !clothing

  return (
    <div className='create-ai-step'>
      <StepTitle title='CHOOSE_CLOTHING' onStepChange={handleStepChange} nextDisabled={disabled} step={7}/>
        <div className='create-ai-list justify-content-center py-1'>
          {AppStore.dict?.[AppStore.gender]['clothing'].map((item, i) => (
            <Button
              key={i}
              className={`m-1 ${item === clothing ? 'active' : ''}`}
              size='xs'
              btnType='third'
              title={item}
              onClick={() => setClothing(item)}
            />
          ))}
        </div>
      <StepFooter
        disabled={disabled}
        onBack={() => handleStepChange(-1)}
        onNext={() => handleStepChange(1)}
      />
    </div>
  );
})

export default Step7;