import React, {FC} from 'react';
import {IBot} from "../modules/rest";
import {observer} from "mobx-react";
import AppStore from "../store/AppStore";

interface Props {
  className?: string;
  bot?: IBot|null;
}

const TgBtn: FC<Props> = observer(({className, bot}) => {
  if (!bot) return null;

  return (
    <a
      href={`${bot.url}?start=${AppStore.clickId}`}
      className={className || ''}
      onClick={e => e.stopPropagation()}
      target={'_blank'}
    />
  );
})

export default TgBtn;