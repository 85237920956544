import React, {FC} from 'react';
import {ESender, IMessage} from "../../modules/rest";
import BoardMessage from "./BoardMessage";
import {useNavigate} from "react-router-dom";
import BoardVoice from "./BoardVoice";

interface Props {
  data?: IMessage;
  voice?: boolean;
  avatarId?: string;
  hideAvatar?: boolean;
  activeVoiceMessage?: IMessage | null;
  userStub?: boolean;
  onMessageVoiceClick: () => void;
}

const Message: FC<Props> = ({
                              data,
                              hideAvatar,
                              avatarId,
                              userStub,
                              onMessageVoiceClick,
                              activeVoiceMessage,
                            }) => {
  const navigate = useNavigate();

  if(data?.voice) return (
    <BoardVoice
      activeVoice={data.id === activeVoiceMessage?.id}
      onClick={onMessageVoiceClick}
      data={data}
      hideAvatar={hideAvatar}
      avatarId={avatarId}
    />
  )

  return <BoardMessage
    onPremiumNavigate={() => navigate('/premium/subscription')}
    userStub={userStub}
    data={data}
    hideAvatar={hideAvatar}
    avatarId={avatarId}
  />
}

export default Message;
