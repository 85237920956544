import React, {FC} from 'react';
import Spinner from './Spinner';

interface Props {
  loading?: boolean;
  showSpinner?: boolean;
  className?: string;
  children?: any;
  onClick?: () => void;
}

const Loadable: FC<Props> = ({loading, className, children, onClick, showSpinner = true}) => {
  return (
    <div className={`loadable${loading ? ' loading' : ''} ${className || ''}`} onClick={onClick}>
      {children || null}
      {loading && <Spinner loading={showSpinner}/>}
    </div>
  );
};

export default Loadable;
