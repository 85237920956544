import React, {FC, useState} from 'react';
import Avatar from "../../../../components/Avatar";
import {thumbAvatar} from "../../../../modules/utils";
import ChatStore from "../../../../store/ChatStore";
import {IModel} from "../../../../modules/rest";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import close_svg from "../../../../assets/icons/close.svg";
import arrow_tr from "../../../../assets/icons/arrow_tr.svg";
import {ModelProfile} from "../../../ModelProfilePage";
import Modal from "react-responsive-modal";
import {observer} from "mobx-react";

interface Props {
  model?: IModel
  actionsInChat: boolean
}

const ChatBoardModel: FC<Props> = observer(({actionsInChat, model}) => {
  const {t} = useTranslation();

  const [profileModalVisible, setProfileModalVisible] = useState(false);

  const handleProfileClick = () => {
    setProfileModalVisible(true);
  }

  if (!model) return null;

  return (
    <>
      <div className="chat-board-model" onClick={handleProfileClick}>
        <Avatar size='sm' image={thumbAvatar(model?.mainPhotoImage?.id, 128)}/>
        <div className='ms-3 d-grid'>
          <div className='d-flex'>
            <div className='text-bold d-grid overflow-hidden'>
              <span className='text-truncate'>{model?.name}</span>
            </div>
            <ReactSVG src={arrow_tr} className='react-icon chat-board-model-arrow' />
          </div>
          {actionsInChat
            ?
            <div className='text-tint text-14'
                 style={{marginTop: 2}}>{t(ChatStore.activeChat?.action?.toUpperCase() || '')}...</div>
            :
            null
          }
        </div>

      </div>
      <Modal
        center
        showCloseIcon={false}
        open={profileModalVisible}
        onClose={() => setProfileModalVisible(false)}
      >
        <div className='modal-container big modal-profile'>
          <ReactSVG src={close_svg} className='modal-close' onClick={() => setProfileModalVisible(false)}/>
          <ModelProfile model={ChatStore.activeChat?.model}/>
        </div>
      </Modal>
    </>
  );
})

export default ChatBoardModel;
