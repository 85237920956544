import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {ETag, EGender} from "../../modules/rest";
import HomeStore from "../../store/HomeStore";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";

const categories: Record<EGender, { id: ETag, title: string, icon: string }[]> = {
  [EGender.Female]: [
    {id: ETag.Milf, title: 'CATEGORY_F_Milf', icon: '🌹'},
    {id: ETag.Hentai, title: 'CATEGORY_F_Hentai', icon: '💢'},
    {id: ETag.Latina, title: 'CATEGORY_F_Latina', icon: '🫦'},
    {id: ETag.Asian, title: 'CATEGORY_F_Asian', icon: '🌸'},
    {id: ETag.Ebony, title: 'CATEGORY_F_Ebony', icon: '🖤'},
    {id: ETag.BigAss, title: 'CATEGORY_F_BigAss', icon: '🍑'},
    {id: ETag.BigTits, title: 'CATEGORY_F_BigTits', icon: '🍒'},
    {id: ETag.Petite, title: 'CATEGORY_F_Petite', icon: '🧸'},
    {id: ETag.Pregnant, title: 'CATEGORY_F_Pregnant', icon: '🤰'},
    {id: ETag.Furry, title: 'CATEGORY_F_Furry', icon: '🐾'},
    {id: ETag.Slim, title: 'CATEGORY_F_Slim', icon: '🦴'},
    {id: ETag.Fat, title: 'CATEGORY_F_Fat', icon: '🐷'},
    {id: ETag.Muscular, title: 'CATEGORY_F_Muscular', icon: '💪'},
    {id: ETag.ColoredHair, title: 'CATEGORY_F_ColoredHair', icon: '🌈'},
    {id: ETag.Arab, title: 'CATEGORY_F_Arab', icon: '🕌'},
    {id: ETag.Dominant, title: 'CATEGORY_F_Dominant', icon: '👑'},
    {id: ETag.Submissive, title: 'CATEGORY_F_Submissive', icon: '🙇‍♀️'},
    {id: ETag.Elf, title: 'CATEGORY_F_Elf', icon: '🧝‍♀️'},
    {id: ETag.BoundWithRopes, title: 'CATEGORY_F_BoundWithRopes', icon: '🪢'},
    {id: ETag.BDSM, title: 'CATEGORY_F_BDSM', icon: '🔗'},
    {id: ETag.Athletes, title: 'CATEGORY_F_Athletes', icon: '🏋️‍♀️'},
    {id: ETag.Students, title: 'CATEGORY_F_Students', icon: '🎓'},
  ],
  [EGender.Male]: [
    {id: ETag.Muscular, title: 'CATEGORY_M_Muscular', icon: '💪🏼'},
    // {id: ETag.Young, title: 'CATEGORY_M_Young', icon: '🐣'},
    // {id: ETag.Thin, title: 'CATEGORY_M_Thin', icon: '🦴'},
    // {id: ETag.SugarDaddy, title: 'CATEGORY_M_SugarDaddy', icon: '🍭'},
    // {id: ETag.European, title: 'CATEGORY_M_European', icon: '🤍'},
    {id: ETag.Asian, title: 'CATEGORY_M_Asian', icon: '🌸'},
    {id: ETag.Ebony, title: 'CATEGORY_M_Black', icon: '🖤'},
  ]
}

const tags = [{id: ETag.Milf, title: 'CATEGORY_F_Milf', icon: '🌹'},
  {id: ETag.Hentai, title: 'CATEGORY_F_Hentai', icon: '💢'},
  {id: ETag.Latina, title: 'CATEGORY_F_Latina', icon: '🫦'},
  {id: ETag.Asian, title: 'CATEGORY_F_Asian', icon: '🌸'},
  {id: ETag.Ebony, title: 'CATEGORY_F_Ebony', icon: '🖤'},
  {id: ETag.BigAss, title: 'CATEGORY_F_BigAss', icon: '🍑'},
  {id: ETag.BigTits, title: 'CATEGORY_F_BigTits', icon: '🍒'},
  {id: ETag.Petite, title: 'CATEGORY_F_Petite', icon: '🧸'},
  {id: ETag.Pregnant, title: 'CATEGORY_F_Pregnant', icon: '🤰'},
  {id: ETag.Furry, title: 'CATEGORY_F_Furry', icon: '🐾'},
  {id: ETag.Slim, title: 'CATEGORY_F_Slim', icon: '🦴'},
  {id: ETag.Fat, title: 'CATEGORY_F_Fat', icon: '🐷'},
  {id: ETag.Muscular, title: 'CATEGORY_F_Muscular', icon: '💪'},
  {id: ETag.ColoredHair, title: 'CATEGORY_F_ColoredHair', icon: '🌈'},
  {id: ETag.Arab, title: 'CATEGORY_F_Arab', icon: '🕌'},
  {id: ETag.Dominant, title: 'CATEGORY_F_Dominant', icon: '👑'},
  {id: ETag.Submissive, title: 'CATEGORY_F_Submissive', icon: '🙇‍♀️'},
  {id: ETag.Elf, title: 'CATEGORY_F_Elf', icon: '🧝‍♀️'},
  {id: ETag.BoundWithRopes, title: 'CATEGORY_F_BoundWithRopes', icon: '🪢'},
  {id: ETag.BDSM, title: 'CATEGORY_F_BDSM', icon: '🔗'},
  {id: ETag.Athletes, title: 'CATEGORY_F_Athletes', icon: '🏋️‍♀️'},
  {id: ETag.Students, title: 'CATEGORY_F_Students', icon: '🎓'},
]


interface Props {
  className?: string;
  onChange?: () => void;
}

const Tags: FC<Props> = observer(({className, onChange}) => {
  const {t} = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleChangeTag = (tag?: ETag) => () => {
    if(onChange) onChange();
    HomeStore.onTagChange(tag);
    if (searchParams.has('category')) {
      searchParams.delete('category');
      setSearchParams(searchParams);
    }
  }

  const tag = HomeStore.tag;

  return (
    <div className={`tags-list ${className || ''}`}>
      <div className={`tags-list-item px-2${!tag ? ' active' : ''}`} onClick={handleChangeTag()}>
        <span className='tags-list-item-text'>{t('ALL')}</span>
      </div>
      {tags.map(item => (
        <div
          className={`tags-list-item${tag === item.id ? ' active' : ''}`}
          key={item.id}
          onClick={handleChangeTag(item.id)}
        >
          <span>{item.icon}</span>
          <span className='ms-2 tags-list-item-text'>{t(item.title)}</span>
        </div>
      ))}
    </div>
  );
})

export default Tags;