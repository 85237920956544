import React, {FC} from 'react';
import {EGeneratePromptTabs, getPosesArr, promptsByType, TTemplatePrompt} from "../modules/utils";
import {EGender, EPose, ESubscriptionStatus} from "../../../modules/rest";
import Button from "../../../components/Button";
import plus from '../../../assets/icons/plus_16.svg';
import check from '../../../assets/icons/check_active.svg';
import diamond from '../../../assets/icons/diamond.svg';
import {ReactSVG} from "react-svg";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import {isSub} from "../../../modules/utils";


interface Props {
  gender?: EGender;
  activeTab: EGeneratePromptTabs;
  prompt: string;
  onChangePrompt: (prompt: string) => void;
  pose?: EPose;
  onPoseChange: (pose: EPose) => void;
  handlePremiumClick: () => void;
}

const GeneratePromptSelector: FC<Props> = observer(({
                                                      pose,
                                                      prompt,
                                                      activeTab,
                                                      onChangePrompt,
                                                      gender,
                                                      onPoseChange,
                                                      handlePremiumClick,
                                                    }) => {
  const handleClick = (text: TTemplatePrompt) => () => {
    const prefix = prompt ? '. ' : '';
    const str = text.prompt[0].toUpperCase() + text.prompt.slice(1);
    onChangePrompt((prompt || '') + prefix + str);
  }


  const posesArr = getPosesArr(isSub(AppStore.user?.subscription?.status));

  return (
    <div className="generate-prompt-tabs-list">
      {activeTab === EGeneratePromptTabs.Poses
        ?
        <>
          {posesArr.regular.map((item, i) => (
            <Button
              key={i}
              className={pose === item ? ' active' : ''}
              size='xs'
              btnType={`third`}
              onClick={() => onPoseChange(item as EPose)}
            >
              <span>{item}</span>
              <ReactSVG src={pose === item ? check : plus} className={`react-icon btn-icon-right`}/>
            </Button>
          ))
          }
          {posesArr.premium.map((item, i) => (
            <Button
              title={item}
              icon={diamond}
              iconPosition={"right"}
              key={i}
              className={pose === item ? ' active' : ''}
              size='xs'
              onClick={handlePremiumClick}
            />
          ))}
        </>
        :
        promptsByType[activeTab][gender || EGender.Female].regular.map((item, i) => (
          <Button
            key={i}
            icon={plus}
            iconPosition='right'
            size='xs'
            btnType={`third`}
            title={item.button}
            onClick={handleClick(item)}
          />
        ))
      }
    </div>
  );
})

export default GeneratePromptSelector;