import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import arrow_left from '../../../assets/icons/arrow_left.svg';
import arrow_right from '../../../assets/icons/arrow_right_active.svg';
import check_svg from '../../../assets/icons/check.svg';
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  last?: boolean;
  step?: number;
  onStepChange: (dir: 1|-1) => void;
}

const StepTitle: FC<Props> = ({title, prevDisabled, nextDisabled, onStepChange, last, step}) => {
  const {t} = useTranslation();
  return (
    <div className='create-ai-title'>
      <ReactSVG
        src={arrow_left}
        className={`react-icon create-ai-arrow${prevDisabled ? ' disabled' : ''}`}
        onClick={() => onStepChange(-1)}
      />
      <h6>{t(title)}</h6>
      <div className='d-flex align-items-center justify-content-end' style={{width: 24}}>
        <div className="create-ai-title-step text-dark-14">{step}/9 {last && <ReactSVG src={check_svg} className='react-icon ms-2' />}</div>
        {!last &&
          <ReactSVG
            src={arrow_right}
            className={`react-icon create-ai-arrow ms-2${nextDisabled ? ' disabled' : ''}`}
            onClick={() => onStepChange(1)}
          />
        }
      </div>
    </div>
  );
}

export default StepTitle;