import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import chevron_down from '../../../assets/icons/chevron_down_gradient.svg';
import {useTranslation} from "react-i18next";

interface Props {
}

const HomeDescription: FC<Props> = () => {
  const {t} = useTranslation();

  return (
    <div className='home-description'>
      <h3 className='text-center ' dangerouslySetInnerHTML={{__html: t(`HOME_DESCRIPTION_TITLE`) || ''}}/>
      <div className={`home-description-container`}>
        <p className='pre-wrap'>{t('HOME_DESCRIPTION_TEXT')}</p>
      </div>
    </div>
  );
}

export default HomeDescription;