import React, {Suspense, useEffect} from "react";
import {Route, Routes, useLocation, useSearchParams} from 'react-router-dom';
import {observer} from "mobx-react";
import Header from "./containers/Header";
import Sider from "./containers/Sider";
import useUtm from "./hooks/useUtm";
import useToTop from "./hooks/useToTop";
import {initFirebase} from "./modules/firebase";
import AppStore from "./store/AppStore";
import HomeStore from "./store/HomeStore";
import useSetUp from "./hooks/useSetUp";
import PreloadImages from "./components/PreloadImages";
import Footer from "./containers/Footer";
import {preloadSrcEthnicity, preloadSrcStyle} from "./pages/CreateAIPage/assets/modules/utils";

import HomePage from "./pages/HomePage";
import CreateAIPage from "./pages/CreateAIPage";
import ChatPage from "./pages/ChatPage";
import MyAIPage from "./pages/MyAIPage";
import GalleryPage from "./pages/GalleryPage";
import PaymentStatusPage from "./pages/PaymentStatusPage";
import GenerateImagePage from "./pages/GenerateImagePage";
import {generateImageFilters} from "./pages/GenerateImagePage/components/GenerateImageFilters";
import PaymentPage from "./pages/PaymentPage";
import ModelProfilePage from "./pages/ModelProfilePage";
import AuthPage from "./pages/AuthPage";
import RefPage from "./pages/RefPage";
import SettingsPage from "./pages/SettingsPage";
import LegalInformationPage from "./pages/LegalInformationPage.tsx";
import SharedChatsPage from "./pages/SharedChatsPage";
import SharedStore from "./store/SharedStore";
import {EGender, EModelStyle, ETag} from "./modules/rest";
import CategoriesPage from "./pages/CategoriesPage";

function adjustViewportHeight() {
  // Приводим в соответствие высоту видимой области
  // document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight}px`);
}




const App = observer(() => {
  const location = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const modelStyle = searchParams.get('style');
  const modelCategory = searchParams.get('category');
  useToTop();
  useUtm();
  useSetUp(AppStore.user);
  // usePreloadImages();

  useEffect(() => {
    initFirebase();
    AppStore.init();
    queryParamsSetUp();
    // adjustViewportHeight();
    // window.addEventListener('orientationchange', adjustViewportHeight);
  }, []);

  useEffect(() => {
    HomeStore.init(modelStyle as EModelStyle, modelCategory as ETag);
    SharedStore.fetch();
  }, [AppStore.gender]);

  const queryParamsSetUp = () => {
    let queryGender = searchParams.get('gender')?.toLowerCase() as EGender;
    if (queryGender && Object.values(EGender).includes(queryGender)) {
      AppStore.changeGender(queryGender);
      searchParams.delete('gender');
    }
    searchParams.delete('lang');
    setSearchParams(searchParams);
  }

  return (
    <>
      <Header/>
      <main className={location.pathname.includes('/chat/') ? 'main-chat' : ''}>
        <Suspense>
          <Routes>
            <Route path='/' element={<HomePage/>}/>
            <Route path="/r/:id" element={<RefPage/>}/>
            <Route path="/auth/*" element={<AuthPage/>}/>
            <Route path='/chat' element={<ChatPage chatPageType='list'/>}/>
            <Route path='/chat/:id' element={<ChatPage chatPageType='chat'/>}/>
            <Route path='/model/:id/:chatId' element={<ModelProfilePage/>}/>
            {/*<Route path='/chat/:id' element={<ChatPage/>}/>*/}

            <Route path='/create-ai' element={<CreateAIPage/>}/>

            <Route path='/premium/*' element={<PaymentPage/>}/>
            <Route path='/payment-status' element={<PaymentStatusPage/>}/>

            <Route path='/generate-image' element={<GenerateImagePage/>}/>

            <Route path='/gallery' element={<GalleryPage/>}/>
            <Route path='/tags' element={<CategoriesPage/>}/>

            <Route path='/my-ai' element={<MyAIPage/>}/>
            <Route path='/public/*' element={<SharedChatsPage/>}/>

            <Route path='/settings' element={<SettingsPage/>}/>
            <Route path='/legal-information/*' element={<LegalInformationPage/>}/>

            <Route path='*' element={<HomePage/>}/>
          </Routes>
        </Suspense>
      </main>
      <Footer/>
      <Sider/>
      <PreloadImages images={[...preloadSrcStyle, ...preloadSrcEthnicity, ...Object.keys(generateImageFilters)]}/>
    </>
  )
})

export default App;