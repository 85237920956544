import React, {FC, useEffect, useState} from 'react';
import chatStore from "../../../../store/ChatStore";
import ChatStore from "../../../../store/ChatStore";
import Message from "../../../../components/Message";
import {EAction, ESender, IMessage} from "../../../../modules/rest";
import {observer} from "mobx-react";
import MessageSound from "../../../../components/Message/MessageSound";
import MessageUploading from "../../../../components/Message/MessageUploading";
import MessageTyping from "../../../../components/Message/MessageTyping";


interface Props {
}

const ChatBoard: FC<Props> = observer(() => {

  const [activeVoiceMessage, setActiveVoice] = useState<IMessage | null>(null);

  useEffect(() => {
    return () => {
      setActiveVoice(null);
    }
  }, [ChatStore.activeChat?.id]);

  const handleMessageVoiceClick = (message: IMessage) => () => {
    setActiveVoice(message);
  }

  const action = ChatStore.activeChat?.action === EAction.Typing ||
    ChatStore.activeChat?.action === EAction.UploadPhoto ||
    ChatStore.activeChat?.action === EAction.UploadVoice;

  return (
    <>
      {chatStore.messages?.map((item, i) => (
        <Message
          onMessageVoiceClick={handleMessageVoiceClick(item)}
          activeVoiceMessage={activeVoiceMessage}
          avatarId={ChatStore.activeChat?.model.mainPhotoImage?.id}
          data={item}
          key={item.id}
          hideAvatar={item.sender === chatStore.messages[i + 1]?.sender || (i === chatStore.messages.length - 1 && item.sender === ESender.Model && action)}
        />
      ))}
      {ChatStore.activeChat?.action === EAction.Typing &&
        <MessageTyping avatarId={ChatStore.activeChat?.model?.mainPhotoImage?.id}/>}
      {ChatStore.activeChat?.action === EAction.UploadPhoto &&
        <MessageUploading avatarId={ChatStore.activeChat?.model?.mainPhotoImage?.id}/>}
      {ChatStore.activeChat?.action === EAction.UploadVoice &&
        <MessageSound avatarId={ChatStore.activeChat?.model?.mainPhotoImage?.id}/>}
      {/*<MessageSound  />*/}
    </>
  );
})

export default ChatBoard;
