import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import ChatStore from "../../../../store/ChatStore";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";
import arrow_svg from '../../../../assets/icons/chevron_down.svg';
import {lerp} from "../../../../modules/utils";
import {openLoyaltySystemModal} from "../../../../modals";
import OutsideClick from "../../../../components/OutsideClick";
import {runInAction} from "mobx";
import Modal from "react-responsive-modal";
import close_svg from "../../../../assets/icons/close.svg";
import {ModelProfile} from "../../../ModelProfilePage";
import {IConversation} from "../../../../modules/rest";

interface Props {
  onClick: () => Promise<void>;
  alwaysVisible?: boolean;
  conversation?: IConversation;
}

const icons = ['🥶', '🤗', '😍', '❤️‍🔥'];
const loyaltyClass = ['cold', 'friendly', 'horny', 'loving'];

const lerpLevelMap: Record<number, [number, number, number, number]> = {
  1: [0, 0, 20, 100],
  2: [20, 0, 50, 100],
  3: [50, 0, 80, 100],
  4: [80, 100, 100, 100],
}

const ChatLoyaltySystem: FC<Props> = ({onClick, alwaysVisible, conversation}) => {
  const {t} = useTranslation();


  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    if (window.innerWidth > 1700) {
      setVisible(prevState => !prevState);
    } else {
      openLoyaltySystemModal(onClick, conversation);
    }

  }

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await onClick();
    } catch (e) {

    }
    setLoading(false)
  }



  // useEffect(() => {
  //   runInAction(() => {
  //     ChatStore.activeChat!.level = 2;
  //     ChatStore.activeChat!.temperature = 60;
  //   })
  // }, []);

  if (!conversation || !conversation?.level) return null;

  return (
    <>

      <OutsideClick outsideClickEvent={() => setVisible(false)} className='loyalty-system'>
        <div
          className={`loyalty-system-container ${loyaltyClass[conversation.level - 1]}${visible || alwaysVisible ? ' visible' : ''}`}>
          {/*{t(`LOYALTY_LEVEL_${activeChat.level || 1}`)}*/}
          <div className="loyalty-system-header" onClick={toggleVisible}>
            <div className="loyalty-system-header-title">
              {t('LOVE_MATTER')}
            </div>
            <ReactSVG src={arrow_svg} className='loyalty-system-header-arrow react-icon'/>
          </div>
          <div className="loyalty-system-status">
            <div className='position-relative d-flex w-100 justify-content-between align-items-center'>
              {icons.map((icon, i) => (
                <div key={i}
                     className={`loyalty-system-status-item ${loyaltyClass[i]}${i < conversation.level ? ' active' : ''}${i + 1 === conversation.level ? ' current' : ''}`}>
                  <div className="loyalty-system-status-item-content">
                    <div className='loyalty-system-status-item-icon'><span>{icon}</span></div>
                    <div className='loyalty-system-status-item-name'>
                      <span className='text-truncate'>{t(`LOYALTY_LEVEL_${i + 1}`)}</span>
                    </div>
                    <div className="loyalty-system-status-item-about">
                      {t(`LOYALTY_LEVEL_${i + 1}_ABOUT`)}
                    </div>
                    {(i === 3 && conversation.level === 4) &&
                      <div className='loyalty-system-status-item-percent'>{Math.round(conversation.temperature)}%</div>}
                  </div>
                  <div className="loyalty-system-status-progress">
                    <div className="loyalty-system-status-progress-percent"
                         style={{width: lerp(...lerpLevelMap[conversation.level], conversation.temperature) + '%'}}>
                      <div className="loyalty-system-status-progress-percent-value">
                        {Math.round(conversation.temperature)}%
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="loyalty-system-about">
            <div className="loyalty-system-about-title">{t(`LOYALTY_LEVEL_${conversation.level}`)}</div>
            <p className='text-dark text-12 mt-1'
               style={{maxWidth: 230}}>{t(`LOYALTY_LEVEL_${conversation.level}_ABOUT`)}</p>
            <button className='btn btn-sm loyalty-system-about-btn' onClick={handleClick}>
              {t('LOVE_MATTER_BTN')}
            </button>
          </div>
        </div>
      </OutsideClick>

    </>
  );
}

export default ChatLoyaltySystem;
