import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import StepFooter from "../StepFooter";
import Step2Age from "./Step2Age";
import Step2EyeColor from "./Step2EyeColor";
import {EEthnicity, EEyesColor, ICreateModelRequest} from "../../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel,
  preloadSrcHairStyle
} from "../../assets/modules/utils";
import AppStore from "../../../../store/AppStore";
import PreloadImages from "../../../../components/PreloadImages";
import {useTranslation} from "react-i18next";

interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step2: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const [age, setAge] = useState(data.age || 18);
  const [ethnicity, setEthnicity] = useState<EEthnicity>(data.ethnicity || EEthnicity.Caucasian);
  const [eyesColor, setEyesColor] = useState<EEyesColor>(data.eyesColor || EEyesColor.Brown);

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {age, ethnicity, eyesColor} : undefined);
  }

  return (
    <div className='create-ai-step'>
      <PreloadImages images={preloadSrcHairStyle} />
      <StepTitle title='CHOOSE_ETHNICITY' onStepChange={handleStepChange} step={2}/>
      <div className='create-ai-list'>
        {Object.values(EEthnicity).map(key => {
          const image = getImageByModel(AppStore.gender, 'ethnicity', key, data.style);
          if(!image) return null;
          return <ActiveElement radio small onClick={() => setEthnicity(key)} key={key} active={ethnicity === key}>
            <div className='model-card'>
              <div className="model-card-category">
                <span className='letter-uppercase text-truncate'>{t(key.toUpperCase())}</span>
              </div>
              <img src={image} alt=""/>
            </div>
          </ActiveElement>
        })}
      </div>
      <Step2Age value={age} onChange={setAge}/>
      <Step2EyeColor value={eyesColor} onChange={setEyesColor}/>
      <StepFooter onBack={() => handleStepChange(-1)} onNext={() => handleStepChange(1)}/>

    </div>
  );
})

export default Step2;