import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {EFieldGroup, EModelStyle, ETag, IModel} from "../modules/rest";
import {API} from "../modules/api";
import AppStore from "./AppStore";
import {toast} from "react-toastify";

class HomeStore {
  ready: boolean = false;
  loading: boolean = false;
  style?: EModelStyle;
  tag?: ETag;
  randomModels: IModel[] = [];
  models: IModel[] = [];

  constructor() {
    makeAutoObservable(this, {
      ready: observable,
      loading: observable,
      style: observable,
      tag: observable,
      randomModels: observable,
      models: observable,

      init: action,
      onStyleChange: action,
      onTagChange: action,
    });
  }

  init = async (style?: EModelStyle, tag?: ETag): Promise<any> => {
    if (style && EModelStyle[style]) {
      runInAction(() => {
        this.style = style;
      })
    }
    if (tag && Object.values(ETag).includes(tag)) {
      runInAction(() => {
        this.tag = tag;
      })
    }

    try {
      const randomModels = await API.Models.getList({
        gender: AppStore.gender,
        limit: 3,
        preset: 'random'
      }, [EFieldGroup.ModelFull]);
      await this.fetchList();
      runInAction(() => {
        this.randomModels = randomModels.data;
      })
    } catch (e: any) {
      toast.error(e);
    } finally {
      runInAction(() => {
        this.ready = true;
      })
    }
  };

  fetchList = async () => {
    try {
      runInAction(() => {
        this.loading = true;
      })
      const res = await API.Models.getList({
        gender: AppStore.gender,
        limit: 100,
        style: this.style,
        tag: this.tag,
        preset: 'random',
      }, [EFieldGroup.ModelFull])
      runInAction(() => {
        this.models = res.data?.sort(() => .5 - Math.random());
      })
    } catch (e: any) {
      toast.error(e)
    } finally {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  onStyleChange = async (style?: EModelStyle) => {
    if (style === this.style) return;
    try {
      runInAction(() => {
        this.style = style;
      })
      await this.fetchList();
    } catch (e) {

    }
  }

  onTagChange = async (tag?: any) => {
    if (tag === this.tag) return;
    try {
      runInAction(() => {
        this.tag = tag;
      })
      await this.fetchList();
    } catch (e) {

    }
  }

}

export default new HomeStore();