import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {APP_COMPANY, APP_COMPANY_EMAIL_SUPPORT, APP_COMPANY_NAME} from "../../../modules/constants";

interface Props {
}

const ContentRemovalPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='CONTENT_REMOVAL_POLICY'>
          <p>At {APP_COMPANY}, we are committed to maintaining a safe and respectful experience for all our users. This Content Deletion Policy outlines our approach to addressing concerns related to content that may inadvertently resemble real individuals. All content on {APP_COMPANY_NAME} ("application") is generated exclusively through artificial intelligence technology, enabling users to create and interact with AI-generated characters. It is important to note that any resemblance to actual persons is unintentional and purely coincidental.</p>
            <h4>1. Content Deletion Process
            </h4>
            <p>If a user believes that any content on our application bears resemblance to them or another real person, they can request its removal by contacting our support team at <a
              href={`mailto:${APP_COMPANY_EMAIL_SUPPORT}`} target='_blank'>{APP_COMPANY_EMAIL_SUPPORT}</a> or by reporting their concern directly within the application in the “Contact” section. We will thoroughly review the request and take appropriate action within a reasonable timeframe.
            </p>
            <h4>2. Unintentional Resemblance to Actual Persons
            </h4>
            <p>While the content on the application is AI-generated, we acknowledge that there may be instances where the generated content unintentionally resembles real individuals. We are committed to promptly addressing any concerns that arise in such situations.
            </p>
            <h4>3. User Verification
            </h4>
            <p>To ensure the accuracy and legitimacy of content deletion requests, we may request the user to provide adequate evidence of their identity or relationship to the person depicted in the content. This verification process is implemented to responsibly handle requests and safeguard the rights and interests of all users.
            </p>
            <h4>4. Content Deletion Execution
            </h4>
            <p>Upon verification and confirmation of a valid content removal request, the specified content will be deleted promptly from the application. Our goal is to complete this process efficiently while ensuring compliance with applicable laws and regulations.
            </p>
            <h4>5. Privacy Assurance
            </h4>
            <p>We prioritize user privacy throughout the entire content removal process. All requests are treated with strict confidentiality, and we do not disclose any personal information or details of the requests to any third parties without explicit consent, unless required by law.
            </p>
            <h4>6. Contact Information
            </h4>
            <p>If you have any questions or require further clarification regarding our Content Deletion Policy, please contact us at <a
              href={`mailto:${APP_COMPANY_EMAIL_SUPPORT}`} target='_blank'>{APP_COMPANY_EMAIL_SUPPORT}</a>. We are committed to addressing concerns in a timely manner and ensuring a positive experience for all our users.
            </p>
    </LegalInformationPageWrapper>
  );
}

export default ContentRemovalPolicy;