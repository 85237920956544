import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import edit_active_svg from "../../assets/icons/edit_active.svg";
import trash_svg from "../../assets/icons/trash.svg";
import {useTranslation} from "react-i18next";
import {ISavedPaymentMethod} from "../../modules/rest";
import {Radio} from "../../components/FormControls";
import PaymentCardLogo from "./components/PaymentCardLogo";


interface Props {
  active?: boolean;
  newCard?: boolean;
  card?: ISavedPaymentMethod;
  className?: string;
  onClick: () => void;
  onRemove?: () => void;
}

const ListPaymentCard: FC<Props> = ({active, card, onClick, newCard, className, onRemove}) => {
  const {t} = useTranslation();
  const [edit, setEdit] = useState(false);

  const toggleEdit = (e: any) => {
    e.stopPropagation();
    setEdit(prevState => !prevState)
  }


  return (
    <>
      <div className={`list-pay-card${active ? ' active' : ''} ${edit ? ' edit' : ''} ${className || ''}`}
           onClick={() => {
             setEdit(false);
             onClick()
           }}>
        <div className='position-relative d-flex z-1'>
          <div className='list-pay-card-status'>
            <Radio checked={active}/>
          </div>
          {newCard
            ?
            <span className='text-bold'>{t('ANOTHER_METHOD')}</span>
            :
            <>
              <PaymentCardLogo type={card?.type}/>
              <span className='text-bold'>{card?.name}</span>
              {!onRemove ? null : edit
                ?
                <div onClick={toggleEdit} className='text-tint ms-2'>{t('CANCEL_EDIT')}</div>
                :
                <ReactSVG src={edit_active_svg} className='react-icon ms-1' onClick={toggleEdit}/>
              }
            </>
          }

        </div>
      </div>
      {
        edit && <ReactSVG src={trash_svg} className='react-icon list-pay-card-trash' onClick={onRemove}/>
      }
    </>
  );
}

export default ListPaymentCard;