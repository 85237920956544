import React, {FC, useLayoutEffect, useState} from 'react';
import {observer} from "mobx-react";
import {HeaderSecondary} from "../../containers/Header";
import AppStore from "../../store/AppStore";
import Button from "../../components/Button";
import {EFilter, EPose, IModel} from "../../modules/rest";
import {API} from "../../modules/api";
import GenerateModel from "./components/GenerateModel";
import GeneratePromptInput from "./components/GeneratePromptInput";
import GeneratePromptSelector from "./components/GeneratePromptSelector";
import arrow_right from "../../assets/icons/arrow_right.svg";
import GenerateImageFilters from "./components/GenerateImageFilters";
import GenerateImageCount from "./components/GenerateImageCount";
import {useLocation, useNavigate} from "react-router-dom";
import {openModalNoMoney} from "../../modals";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

interface Props {
}

const GenerateImagePage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [model, setModel] = useState<IModel>();
  const [prompt, setPrompt] = useState('');
  const [pose, setPose] = useState<EPose>();
  const [filter, setFilter] = useState<EFilter>(EFilter.Default);
  const [count, setCount] = useState(1);

  useLayoutEffect(() => {
    let model = location.state?.model;
    if (model) {
      model = JSON.parse(model);
      setModel(model);
    }
  }, []);


  const onSubmit = async (e: any) => {
    e.preventDefault();
    window.scrollTo({top: 0, behavior: 'smooth'});
    setError(false);
    if (!model) {
      setError(true);
      return toast.error(t('CHOOSE_CHARACTER'));
    }
    if (!prompt) {
      setError(true);
      return toast.error(t('ENTER_PROMPT'));
    }
    if (loading) return;
    try {
      setLoading(true);
      await API.Models.createExtraPhoto(model?.id!, {prompt, pose, count, filter});
      //@ts-ignore
      ym(97677527, 'reachGoal', 'submit8');
      navigate(`/gallery`);
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        openModalNoMoney("create-ai").then((path) => {
          if (path) navigate(path);
        });
      } else {
        toast.error(e);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <HeaderSecondary title='GENERATE_IMAGE' onClick={() => AppStore.toggleVisibleAside()}/>
      <div className='container create-image-page'>
        <h2 className='page-title mb-4'>{t('GENERATE_IMAGE')}</h2>
        {AppStore.ready &&
          <div className="card p-3 p-lg-4 bdrs-24">
            <form onSubmit={onSubmit}>
              <h6 className='text-center mb-3'>{t('CHOOSE_CHARACTER')}*</h6>
              <GenerateModel
                error={error}
                model={model}
                onChange={setModel}/>
              <GeneratePromptInput
                error={error}
                prompt={prompt}
                onChange={setPrompt}
                gender={model?.gender}
              />
              <GeneratePromptSelector
                prompt={prompt}
                onChange={setPrompt}
                gender={model?.gender}
                pose={pose}
                onPoseChange={setPose}
              />
              <GenerateImageFilters filter={filter} onChange={setFilter}/>
              <GenerateImageCount count={count} onChange={setCount}/>
              <Button
                iconAbsolute
                className='modal-footer w-100'
                title={'GENERATE'}
                icon={arrow_right}
                iconPosition={"right"}
                type='submit'
              />
            </form>
          </div>

        }
      </div>
    </>
  );
})

export default GenerateImagePage;