import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {APP_COMPANY, APP_COMPANY_EMAIL_REPORT, APP_COMPANY_NAME} from "../../../modules/constants";

interface Props {
}

const PrivacyPolicies: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='UNDERAGE_POLICY'>
           <p>At {APP_COMPANY}, we are dedicated to ensuring that our platform {APP_COMPANY_NAME} ("application") is used responsibly and safely by individuals who meet the legal age requirements.
           </p>
            <p>The application includes AI-generated adult content available exclusively to registered users. Users must be at least 18 years old or of legal age in their country of residence to access and interact with this content. By using the application, users affirm that they meet the minimum age requirement and are legally permitted to access adult content.
            </p>
            <p>We have implemented several measures to monitor and prevent the registration of underage users. We are committed to ensuring that AI-generated content on our application does not resemble minors or exploit their likeness. To this end, we have deployed a proprietary content moderation filter to ensure full compliance with our Terms and Policies.
            </p>
            <h4>1. Registration
            </h4>
            <p>During the registration process for the application, users are presented with an initial landing page or age gate, designed to restrict access to individuals who are not of legal age. Before accessing the main content, users must confirm their age.
            </p>
            <ul>
                    <li>Users must affirm that they are above 18, and this information is used to verify their eligibility to access the application.
                    </li>
                    <li>A clear disclaimer emphasizes that users must be 18 years or older to access our services. Users are also informed that providing false information about their age is a violation of our Terms of Service.
                    </li>
                    <li>{APP_COMPANY} cannot be held responsible for any inaccuracies or misrepresentations regarding user age. It is the user's responsibility to ensure compliance with their local laws and regulations regarding the access and consumption of adult content.</li>
            </ul>
            <h4>2. Content Responsibility
            </h4>
            <p>As a user of the application, you are solely responsible for the output generated by the AI Companions through text messages, images, and other media. The AI Companions generate responses based on the conversations you lead and the parameters you set. You understand and agree that {APP_COMPANY} does not control or endorse the content generated by the AI Companions. Therefore, you acknowledge full responsibility for the AI-generated output and your actions while using the application.
            </p>
            <ul>
                    <li>You must ensure that your interactions with the AI Companions comply with applicable laws, regulations, our Terms and Policies, especially the Prohibited Content Policy. You must not engage in any illegal, unethical, or harmful activities through the application.
                    </li>
                    <li>Specifically, paragraphs 1d) and 1e) of the Prohibited Content Policy prohibit any content that exploits or poses a danger to minors, including but not limited to child pornography, sexual exploitation, or any form of harm or harassment towards minors, as well as any attempt to generate AI content that resembles minors or exploits their likeness.
                    </li>
            </ul>
            <h4>3. Content Moderation
            </h4>
            <p>At {APP_COMPANY}, we prioritize the safety, respect, and integrity of all our users. While conversations between users and AI Companions are generally confidential, we have implemented a content moderation filter, based on our proprietary technology, to ensure compliance with our Terms and Policies, particularly the Prohibited Content Policy. If the moderation filter detects any content that violates our terms, we reserve the right to manually review the flagged content and take appropriate action, which may include terminating the user's account. This measure is implemented to maintain a respectful and secure environment for all users. We strive to balance privacy and community standards and appreciate your cooperation in adhering to our guidelines.
            </p>
            <h4>4. Content Deletion
            </h4>
            <p>Any user content that we believe, in our sole discretion, violates these provisions will be promptly deleted.
            </p>
            <h4>5. Contact Information
            </h4>
            <p>If you notice any violation of these Terms, encounter inappropriate content, or have any questions regarding our Underage Policy, please contact us at <a
              href={`mailto:${APP_COMPANY_EMAIL_REPORT}`} target='_blank'>{APP_COMPANY_EMAIL_REPORT}</a>.
            </p>
            <h4>6. Termination
            </h4>
            <p>We reserve the right to suspend or terminate the use of the application by anyone engaged in the suspected infringement of the above-stated policies.
            </p>
    </LegalInformationPageWrapper>
  );
}

export default PrivacyPolicies;