import React, {FC, useEffect, useState} from 'react';
import process_bg from '../assets/images/process_bg.png';
import {useLocation} from "react-router-dom";
import {ReactSVG} from "react-svg";
import stick from '../assets/icons/stick.svg';
import stick_star from '../assets/icons/stick_star.svg';
import check from '../../../assets/icons/check.svg';
import {useTranslation} from "react-i18next";

interface Props {
  finished?: boolean;
  className?: string;
  title?: string;
  progress?: number | null;
}

const NewAIProcess: FC<Props> = ({finished, title, className, progress}) => {
  const {t} = useTranslation();
  const { state } = useLocation()
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prevState => {
        if (prevState === 3) return 0;
        return prevState + 1
      })
    }, 500);
    return () => {
      clearInterval(interval)
    }
  }, []);

  return (
      <div className={`create-my-ai-container ${className || ''}`}>
      <div className={`my-ai-process${finished  ? ' finished' : ''}`}>
        <div className='my-ai-process-bg'>
          <img src={process_bg} alt="bg"/>
        </div>
        <div className="my-ai-progress-bar">
          <span className="my-ai-progress-bar-loader" />
          <ReactSVG src={stick} className='react-icon position-absolute' />
          <ReactSVG src={stick_star} className={`react-icon position-absolute stick_star-1${dots > 0 ? ' visible' : ''}`} />
          <ReactSVG src={stick_star} className={`react-icon position-absolute stick_star-2${dots > 1 ? ' visible' : ''}`} />
          <ReactSVG src={stick_star} className={`react-icon position-absolute stick_star-3${dots > 2 ? ' visible' : ''}`} />
        </div>
        <div className="my-ai-progress-finished">
          <ReactSVG src={check} className='react-icon' />
        </div>
        {state?.category ?
          <div className="model-card-category"><span className='letter-uppercase'>{state?.category}</span></div>
          :
          null
        }
        <div className='text-semibold-14 my-ai-process-text'>{t(title || 'CREATING_NEW_AI')}
          {/*<div className="my-ai-process-text-dots">{Array(dots).fill({}).map((_, i) => <span key={i}>.</span>)}</div>*/}
        </div>
        <div className='my-ai-generate-progress'>
          <div className="my-ai-generate-progress-percent" style={{width: (progress || 0) + '%'}}/>
        </div>
      </div>
    </div>
  );
}

export default NewAIProcess;
