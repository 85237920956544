import React, {FC} from 'react';
import Button from "../../../components/Button";
import arrow_left from '../../../assets/icons/arrow_left.svg';
import arrow_right from '../../../assets/icons/arrow_right.svg';
import magic_wand from '../../../assets/icons/magic_wand.svg';

interface Props {
  onBack?: () => void;
  onNext?: () => void;
  disabled?: boolean;
  loading?: boolean;
  last?: boolean;
  nextTitle?: string;
}

const StepFooter: FC<Props> = ({onBack, onNext, disabled, nextTitle, loading, last}) => {
  return (
    <div className={`create-ai-step-footer${last ? ' create-ai-step-summary' : ''}`}>
      <Button
        onClick={onBack}
        disabled={!onBack || loading}
        icon={arrow_left}
        iconAbsolute
        btnType='secondary'
        title='BACK'
        className='w-50'
      />
      <Button
        loading={loading}
        disabled={disabled}
        onClick={onNext}
        icon={last ? magic_wand : arrow_right}
        iconPosition='right'
        iconAbsolute
        title={nextTitle || 'NEXT'}
        className='w-50'
      />
    </div>
  );
}

export default StepFooter;