import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import arrow_left from '../../../assets/icons/arrow_left.svg';
import {Input} from "../../../components/FormControls";
import email_svg from "../../../assets/icons/email.svg";
import {API} from "../../../modules/api";
import {toast} from "react-toastify";
import Button from "../../../components/Button";
import {useTranslation} from "react-i18next";

interface Props {
  onTypeChange: () => void;
}

const AuthRecovery: FC<Props> = ({onTypeChange}) => {
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState('');


  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      await API.Users.resetPassword({email});
      setSuccess(true);
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }
  return (
    <>
      <div className='d-flex align-items-center mb-3'>
        <ReactSVG src={arrow_left} className='react-icon me-2 cursor-pointer' onClick={onTypeChange}/>
        <h4>{t('RESET_PASSWORD')}</h4>
      </div>
      <div className='text-dark-14 mb-4'>{t('RESET_PASSWORD_TEXT')}</div>

      <form onSubmit={onSubmit}>
        <Input
          required
          icon={email_svg}
          label='EMAIL'
          placeholder='E-MAIL'
          value={email}
          type='email'
          onChange={(e) => setEmail(e.target.value)}
        />
        {success  &&
        <div className='auth-recovery-success'>
          {t('RESET_PASSWORD_SUCCESS_TEXT')}
        </div>
        }
        <Button
          className='w-100 mt-4'
          loading={loading}
          title={'RESET_PASSWORD'}
          type='submit'
        />
        <Button
          btnType='secondary'
          onClick={onTypeChange}
          className='w-100 mt-3'
          title={'CANCEL'}
        />
      </form>
    </>

  );
}

export default AuthRecovery;