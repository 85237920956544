import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  label: string;
  value?: string | null;
  onClick?: () => void;
  accentValue?: boolean;
  Footer?: any;
  children?: any;
}

const SettingsItem: FC<Props> = ({label, value, onClick, accentValue}) => {
  const {t} = useTranslation();
  return (
    <div className='settings-item-container'>
      <div className="settings-item" onClick={onClick}>
        <div className='flex-1 pe-2 d-grid overflow-hidden'>
          <div className='text-truncate text-dark-14'>{t(label)}</div>
          <div className={`text-truncate mt-1 ${accentValue ? 'text-tint' : 'text-bold'}`}>{value}</div>
        </div>
        {onClick && <div className="btn-edit"/> }
      </div>
    </div>
  );
}

export default SettingsItem;