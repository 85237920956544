import React, {FC, useEffect, useRef, useState} from 'react';
import {EAction, ELevel, IMessage} from "../../../../modules/rest";
import Avatar from "../../../../components/Avatar";
import {useNavigate, useParams} from "react-router-dom";
import ChatStore from "../../../../store/ChatStore";
import {observer} from "mobx-react";
import {API, wsUrl} from "../../../../modules/api";
import {Centrifuge, PublicationContext} from "centrifuge";
import AppStore from "../../../../store/AppStore";
import {runInAction} from "mobx";
import {thumbAvatar} from "../../../../modules/utils";
import ChatBoard from "./ChatBoard";
import ChatSettings from "./ChatSettings";
import ChatControl from "./ChatControl";
import {openModalNoMoney} from "../../../../modals";
import {HeaderSecondary} from "../../../../containers/Header";
import {Spinner} from "../../../../components/Loadable";
import {useTranslation} from "react-i18next";
import ChatLoyaltySystem from "./ChatLoyaltySystem";
import {toast} from "react-toastify";
import ChatBoardModel from "./ChatBoardModel";

export type PhotoInterract = {
  event: 'show_photo_request';
  interract_id: string;
  conversation_id: number;
  question: string;
  button_yes: string;
  button_no: string;
};


interface Props {
  className?: string;
}

const Chat: FC<Props> = observer(({className}) => {
  const {t} = useTranslation();

  const _scroll: any = useRef(null);
  const navigate = useNavigate()
  const params = useParams();
  const centrifuge = useRef<Centrifuge|null>(null);
  const [loading, setLoading] = useState(false);
  const [showImageConfirm, setShowImageConfirm] = useState<PhotoInterract|boolean>(false);
  // setTimeout(() => setShowImageConfirm(true), 1000)

  const publication = (ctx: PublicationContext) => {
    const data = ctx.data;
    // console.log('WSS DATA', data);
    if (data.event === 'payment_required') {
      setTimeout(() => setShowImageConfirm(false), 300)
      openModalNoMoney("chat", data.model?.mainPhotoImage?.id).then((path) => {
        if (path) navigate(path);
      });
    } else if (data.event === 'credits_updated') {
      runInAction(() => (AppStore.user!.credits = Number(data.value)));
    } else if (ChatStore.activeChat && data.conversation_id === ChatStore.activeChat?.id) {
      if (data.event === 'conversation_action') {
        runInAction(() => {
          ChatStore.activeChat!.action = data.action as EAction
          onScroll(true);
        });
      } else if (data.event === 'new_message') {
        runInAction(() => {
          ChatStore.messages.push(data.message as IMessage)
          onScroll(true);
        });
      } else if (data.event === 'show_photo_request') {
        setShowImageConfirm(data as PhotoInterract);
      } else if (data.event === 'hide_photo_request') {
        setShowImageConfirm(false);
      } else if (data.event === 'temperature_changed') {
        runInAction(() => {
          ChatStore.activeChat!.level = data.level as ELevel;
          ChatStore.activeChat!.temperature = data.temperature;
        });
      } else if (data.event === 'conversation_mode') {
        runInAction(() => {
          ChatStore.activeChat!.isVoice = data.isVoice;
        });
      }
      ChatStore.getList();
      onScroll(true);
    }
  }

  useEffect(() => {
    //@ts-ignore
    ym(97677527, 'reachGoal', 'submit1');
    if (!AppStore.ready || !AppStore.user?.id) return;
    centrifuge.current = new Centrifuge(wsUrl, {token: API.getToken()!});
    centrifuge!.current
      .on('connecting', function (ctx) {
        // console.log(`connecting: ${ctx.code}, ${ctx.reason}`);
      })
      .on('connected', function (ctx) {
        // console.log(`connected over ${ctx.transport}`);
      })
      .on('disconnected', function (ctx) {
        // console.log(`disconnected: ${ctx.code}, ${ctx.reason}`);
      })
      .connect();
    const sub = centrifuge.current!.newSubscription('user#' + AppStore.user?.id);
    sub!
      .on('publication', publication)
      .on('subscribing', function (ctx) {
        // console.log(`subscribing: ${ctx.code}, ${ctx.reason}`);
      })
      .on('subscribed', function (ctx) {
        // console.log('subscribed', ctx);
      })
      .on('unsubscribed', function (ctx) {
        // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
      })
      .subscribe();
    return () => {
      centrifuge.current?.disconnect();
    };
  }, [AppStore.ready, AppStore.user?.id]);

  useEffect(() => {
    fetch()
  }, [params.id, AppStore.user?.id]);


  const fetch = async () => {
    const id = params.id ||  ChatStore.activeChat?.id || ChatStore.chats[0].id;
    if ((!id || !AppStore.user?.id) || (!params.id && window.innerWidth < 992)) return;
    try {
      const [chat, messages] = await ChatStore.getChat(id, true) || [];
      const windowId = window.location.pathname;
      if (windowId.includes(String(id)) || (!params.id)) {
        runInAction(() => {
          ChatStore.activeChat = chat;
          ChatStore.messages = messages!;
        })
      }
    } catch (e: any) {

    }
  }

  const onScroll = (smooth?: boolean) => {
    // const height = _scroll?.current?.scrollHeight;
    // if (!height) return;
    // document.querySelector('#form').scrollIntoView({behavior: 'smooth'});
    if (smooth) {
      setTimeout(() => {
        _scroll?.current?.scrollTo({ top: 999999, behavior: 'smooth' });
      }, 50);
    } else {
      _scroll?.current?.scrollTo({ top: 999999, behavior: 'instant' });
    }
  }

  const handleTopCheck = async () => {
    if (_scroll?.current?.scrollTop === 0 && !loading && ChatStore.activeChat?.id) {
      setLoading(true);
      try {
        const messageId = ChatStore.messages?.[0]?.id ?? 0
        const messages = await API.Conversations.getMessages(ChatStore.activeChat.id, {lastMessageId: messageId})
        const height = _scroll?.current?.scrollHeight;
        runInAction(() => {
          ChatStore.messages = [...messages, ...ChatStore.messages];
          setTimeout(() => {
            _scroll?.current?.scrollTo({top: _scroll?.current?.scrollHeight - height, behavior: 'instant'})
          }, 1)

          // document.querySelector(`#message_${messageId}`)?.scrollIntoView()
        })
      } catch (e: any) {
      } finally {
        setLoading(false)
      }
    }
  }

  const handleBack = () => {
    runInAction(() => {
      ChatStore.activeChat = undefined;
      ChatStore.messages = [];
      if (!AppStore.user?.id) ChatStore.chats = [];
    })

    localStorage.removeItem('conversation');
    if(AppStore.user?.id) {
      navigate('/chat', {replace: true})
    } else {
      navigate('/', {replace: true})
    }

  }

  const activeModel = ChatStore.activeChat?.model;


  useEffect(() => {
    if (activeModel) onScroll();
  }, [activeModel]);

  const onModelProfileClick = () => {
    navigate(`/model/${ChatStore.activeChat?.model?.id}/${ChatStore.activeChat?.id}`, {state: {model: JSON.stringify(ChatStore.activeChat?.model)}});
    // const width = window.innerWidth;
    // if (width < 992) {
    //   navigate(`/model/${ChatStore.activeChat?.model?.id}`, {state: {model: JSON.stringify(ChatStore.activeChat?.model)}});
    // } else {
    //   openModelProfileModal(ChatStore.activeChat?.model!)
    // }
  }

  const handleWarmLevel = async () => {
    try {
      const res = await API.Conversations.warm(ChatStore.activeChat!.id);
      if (ChatStore.activeChat?.id === res.id) {
        runInAction(() => {
          ChatStore.activeChat = res;
        })
      }
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code !== 402) {
        toast.error(e);
      }
      throw e
    }
  }

  const actionsInChat: EAction[] = [EAction.UploadPhoto, EAction.Typing, EAction.UploadVoice]

  return (
    <div className={`chat-container${ChatStore.activeChat?.isVoice ? ' voice-chat' : ''}`}>
      <HeaderSecondary
        onClick={handleBack}
        back
        coins
        title={activeModel?.name}
        subtext={
          actionsInChat.includes(ChatStore.activeChat?.action || EAction.None)
            ? <div className='text-tint text-12'>{t(ChatStore.activeChat?.action?.toUpperCase() || '')}...</div>
            : null
        }
      >
        <Avatar size='xs' image={thumbAvatar(activeModel?.mainPhotoImage?.id, 128)} onClick={onModelProfileClick}/>
      </HeaderSecondary>
      <div className="chat-board-container">
        <Spinner loading={!activeModel && ChatStore.loading} absolute style={{top: 4}}/>
        <div className='chat-board-header'>
          <div className="chat-board-header-left">
            <ChatBoardModel
              model={activeModel}
              actionsInChat={actionsInChat.includes(ChatStore.activeChat?.action || EAction.None)}
            />
            <ChatLoyaltySystem
              conversation={ChatStore.activeChat}
              onClick={handleWarmLevel}
            />
          </div>
          <ChatSettings model={ChatStore.activeChat?.model}/>
        </div>
        <div className="chat-board" ref={_scroll} onScroll={handleTopCheck}>

          <ChatBoard/>

        </div>

      </div>
      <ChatControl
        showImageConfirm={showImageConfirm}
        onChangeImageConfirm={setShowImageConfirm}
      />
    </div>
  );
})

export default Chat;
