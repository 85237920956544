import React, {FC} from 'react';

interface Props {
  className?: string;
  active?: boolean;
  small?: boolean;
  radio?: boolean;
  bgBlur?: boolean;
  bordered?: boolean;
  onClick?: () => void;
  children: any;
}

const ActiveElement: FC<Props> = ({className, active, onClick, children, small, bordered, radio, bgBlur}) => {
  return (
    <div className={`active-element-container${small ? ' small' : ''}${bgBlur ? ' bg-blur' : ''} ${className || ''}`}>
      <div className={`active-element${active ? ' active' : ''}`}>
        <div className={`active-element-content${bordered ? ' bordered' : ''} `} onClick={onClick}>
          {children}
          {onClick && radio
            ?
            <div className={`form-radio-custom${active ? ' active' : ''}`}/>
            :
            null
          }
        </div>
      </div>
    </div>
  );
}

export default ActiveElement;