import Message from './Message';
import MessageTyping from './MessageTyping';
import MessageUploading from './MessageUploading';
import MessageSound from './MessageSound';
import BoardMessage from './BoardMessage';
import BoardVoice from './BoardVoice';
import './assets/styles/message.scss';

export {MessageTyping, BoardVoice, BoardMessage, MessageSound, MessageUploading}
export default Message;
