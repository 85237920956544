import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import StepFooter from "../StepFooter";
import {ICreateModelRequest } from "../../../../modules/rest";
import {observer} from "mobx-react";
import {relationshipData} from "../../assets/modules/utils";
import AppStore from "../../../../store/AppStore";
import {useTranslation} from "react-i18next";

interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step8: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();
  const [relationship, setRelationship] = useState(data?.relationship || '');

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {relationship} : undefined);
  }

  const disabled = !relationship;

  return (
    <div className='create-ai-step'>
      <StepTitle title='CHOOSE_RELATIONSHIP' onStepChange={handleStepChange} nextDisabled={disabled} step={8}/>
      <div className='create-ai-list'>
        {Object.entries(relationshipData).map(([value, item]) => (
            <ActiveElement
              bordered
              radio
              small
              onClick={() => setRelationship(value)}
              key={value}
              active={relationship === value}>
              <div className='create-ai-border-item align-items-center justify-content-between'>
                <div className='mt-3' style={{fontSize: 48, lineHeight: '48px', marginBottom: 2}}>{item.icon}</div>
                <div className='text-bold text-center'>{t(item[AppStore.gender])}</div>
              </div>
            </ActiveElement>
          )
        )}
      </div>

      <StepFooter
        disabled={disabled}
        onBack={() => handleStepChange(-1)}
        onNext={() => handleStepChange(1)}
      />
    </div>
  );
})

export default Step8;