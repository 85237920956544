import React, {FC} from 'react';
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import {IModel} from "../../modules/rest";
import {useNavigate} from "react-router-dom";
import {HeaderSecondary} from "../../containers/Header";
import {useTranslation} from "react-i18next";
import HomeList from "../HomePage/components/HomeList";
import { setConversationModel} from "../HomePage/modules/utils";
import {API} from "../../modules/api";
import {toast} from "react-toastify";

interface Props {
}


const CategoriesPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleAnswer = async (model: IModel) => {
    if (!AppStore.user?.id) {
      setConversationModel(model);
      navigate(`/chat`);
      return;
    }
    try {
      const res = await API.Models.startConversation(model.id);
      navigate(`/chat/${res.id}`);
    } catch (e: any) {
      toast.error(e)
    }
  }

  return (
    <>
      <HeaderSecondary title='CATEGORIES' onClick={() => AppStore.toggleVisibleAside()}/>
      <div className='container categories-page'>
        <h2 className='page-title mb-4'>{t('CATEGORIES')}</h2>
        <HomeList onAnswer={handleAnswer} title={''}/>
      </div>
    </>
  );
})

export default CategoriesPage;