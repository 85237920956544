import React, {FC} from 'react';
import {Radio} from "../../../components/FormControls";
import {ReactSVG} from "react-svg";
import diamond from '.././../../assets/icons/diamond.svg';
import {observer} from "mobx-react";
import {openModalNoMoney} from "../../../modals";
import {useNavigate} from "react-router-dom";
import AppStore from "../../../store/AppStore";
import {ESubscriptionStatus} from "../../../modules/rest";
import HorizontalScroll from "../../../components/HorizontalScroll";
import {useTranslation} from "react-i18next";
import {isSub} from "../../../modules/utils";

interface Props {
  onChange: (count: number) => void;
  count: number;
}

type TItem = { count: number, free: boolean }

const items: TItem[] = [
  {count: 1, free: true},
  {count: 4, free: false},
  {count: 8, free: false},
  {count: 16, free: false},
]


const GenerateImageCount: FC<Props> = observer(({count, onChange}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = (item: TItem) => () => {
    if (!isSub(AppStore.user?.subscription?.status) && !item.free) {
      openModalNoMoney('create-ai').then(path => {
        if (path) navigate(path);
      })
    } else {
      onChange(item.count)
    }
  }

  return (
    <>
      <div className='mt-2 mt-lg-3 text-dark-14 mb-3'>{t('IMAGES_NUM')}</div>
      <HorizontalScroll className='generate-image-count-list' secondary>
        {items.map((item, i) => (
          <div
            key={i}
            className={`border-gradient-secondary${item.count == count ? ' active' : ''}`}
            onClick={handleClick(item)}>
            <div className='d-flex justify-content-end align-items-center w-100'>
              {!item.free ?
                <div className="generate-image-count-badge">
                  <ReactSVG src={diamond} className='react-icon me-2'/>
                  <span>{t('PREMIUM')}</span>
                </div>
                :
                null
              }
              <Radio checked={Boolean(item.count === count)} onChange={() => {}}/>
            </div>
            <div className="premium-plan-item-bg"/>
            <div className='credit-plan-coins'>{item.count}</div>
          </div>
        ))}
      </HorizontalScroll>
    </>
  );
})

export default GenerateImageCount;