import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import StepFooter from "../StepFooter";
import {ICreateModelRequest } from "../../../../modules/rest";
import Button from "../../../../components/Button";
import {observer} from "mobx-react";
import {Checkbox} from "../../../../components/FormControls";
import AppStore from "../../../../store/AppStore";
import {useTranslation} from "react-i18next";


interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step6: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const [occupation, setOccupation] = useState(data.occupation);
  const [hobbies, setHobbies] = useState(data.hobbies || []);

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {occupation, hobbies} : undefined);
  }

  const onHobbyClick = (hobby: string) => () => {
    setHobbies(prevState => {
      if (prevState.includes(hobby)) {
        return prevState.filter(item => item !== hobby);
      } else {
        if (prevState.length === 3) return prevState;
        return [...prevState, hobby];
      }
    })
  }

  const disabled = !occupation || !hobbies?.length

  return (
    <div className='create-ai-step'>
      <StepTitle title='CHOOSE_OCCUPATION' onStepChange={handleStepChange} nextDisabled={disabled} step={6}/>
      <div className='create-ai-list justify-content-center py-1'>
        {AppStore.dict?.[AppStore.gender]['occupations'].map((item, i) => (
          <Button
            key={i}
            className={`m-1 ${item === occupation ? 'active' : ''}`}
            size='xs'
            btnType='third'
            title={item}
            onClick={() => setOccupation(item)}
          />
        ))}
      </div>
      <div className="card-dark create-ai-inner-card">
        <h6 className='text-center'>{t('CHOOSE_HOBBIES')}</h6>
        <div className='text-muted create-ai-subtitle'>{t('CHOOSE_UP_VARIANT')}</div>
        <div className='create-ai-list justify-content-center py-1'>
          {AppStore.dict?.[AppStore.gender]['hobbies'].map((item, i) => (
            <Button
              disabled={!hobbies?.includes(item) && hobbies.length === 3}
              key={i}
              className={`m-1${hobbies?.includes(item) ? ' active' : ''}`}
              contentClassName='pe-2'
              size='xs'
              btnType='third'
              onClick={onHobbyClick(item)}
            >
              <span className='me-2 pe-1'>{item}</span>
              <Checkbox checked={hobbies?.includes(item)} onChange={() => {}}/>
            </Button>
          ))}
        </div>
      </div>
      <StepFooter
        disabled={disabled}
        onBack={() => handleStepChange(-1)}
        onNext={() => handleStepChange(1)}
      />
    </div>
  );
})

export default Step6;