import React, {FC} from 'react';
import {addZeroToTime} from "../../modules/utils";

interface Props {
  time: number;
  separator?: boolean;
  disabled?: boolean;
}

const Timer: FC<Props> = ({time, disabled, separator}) => {
  return (
    <>
      <div className={`timer-item${disabled ? ' disabled' : ''}`}>
        <span>{addZeroToTime(time)}</span>
      </div>
      {separator && <div className="timer-separator">:</div>}
    </>
  );
}

export default Timer;