import asyncModal from 'react-async-modal';
import {ReactSVG} from "react-svg";
import React, {FC, useState} from "react";
import close_svg from "../../assets/icons/close.svg";
import {useTranslation} from "react-i18next";
import ListPaymentCard from "../../containers/ListPaymentCard";
import {ISavedPaymentMethod} from "../../modules/rest";
import Button from "../../components/Button";
import arrow_right from '../../assets/icons/arrow_right.svg';


interface Props {
  resolve(res?: string|null): void;

  savedCards: ISavedPaymentMethod[]
  selectedCardId: string
}

const PaymentMethodCardsModal: FC<Props> = ({resolve, savedCards, selectedCardId}) => {
  const {t} = useTranslation();
  const [selected, setSelected] = useState<string|null>(selectedCardId);
  const [changed, setChanged] = useState(false);


  const handleClose = () => {
    resolve();
  }
  const handleClick = (id: string|null) => () => {
    setChanged(true);
    setSelected(id);
  }

  const submit = () => {
    resolve(changed ? selected : null);
  }


  return (
    <div className='modal-container medium'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='mt-2 text-center mb-4 px-5'>{t('PAYMENT_METHOD')}</h4>
      <div className='d-flex flex-column gap-2 mt-2'>
        {savedCards?.map((savedCard) => (
          <div className='d-flex align-items-center w-100' key={savedCard.id}>
            <ListPaymentCard
              className='w-100'
              card={savedCard}
              active={selected === savedCard.id}
              onClick={handleClick(savedCard.id)}
            />
          </div>
        ))}
        <ListPaymentCard
          newCard
          className='w-100'
          active={selected === 'new'}
          onClick={handleClick('new')}
        />
      </div>
      <div className='modal-footer'>
        <Button
          iconAbsolute
          icon={selected === 'new' ? arrow_right : null}
          iconPosition='right'
          btnType={!changed ? 'secondary' : 'primary'}
          title={!changed ? 'BACK' :  (selected === 'new' ? 'NEXT' : 'SELECT')}
          onClick={submit}
        />
      </div>
    </div>
  );
};

const openPaymentMethodCards = (savedCards?: ISavedPaymentMethod[], selectedCardId?: string): Promise<string|'new'|undefined> => {
  return asyncModal(PaymentMethodCardsModal, {savedCards, selectedCardId}, {
    showCloseIcon: false,
    center: true,
  });
};

export {openPaymentMethodCards};
