import {
  EBodyType,
  EBreastSize,
  EButtSize,
  EEthnicity,
  EEyesColor,
  EGender,
  EHairColor,
  EHairStyle,
  EModelStyle
} from "../../../../modules/rest";

import body_petite_f from "../images/bodyType/female/real/Petite.png";
import body_slim_f from "../images/bodyType/female/real/Slim.png";
import body_athletic_f from "../images/bodyType/female/real/Athletic.png";
import body_voluptuous_f from "../images/bodyType/female/real/Voluptuous.png";
import body_curvy_f from "../images/bodyType/female/real/Curvy.png";
import body_muscular_f from "../images/bodyType/female/real/Muscular.png";
// import body_wide_f from "../images/bodyType/female/real/Wide.png";

import body_anime_petite_f from "../images/bodyType/female/anime/Petite.png";
import body_anime_slim_f from "../images/bodyType/female/anime/Slim.png";
import body_anime_athletic_f from "../images/bodyType/female/anime/Athletic.png";
import body_anime_voluptuous_f from "../images/bodyType/female/anime/Voluptuous.png";
import body_anime_curvy_f from "../images/bodyType/female/anime/Curvy.png";
import body_anime_muscular_f from "../images/bodyType/female/anime/Muscular.png";
import body_anime_wide_f from "../images/bodyType/female/anime/Wide.png";

import body_slim_m from "../images/bodyType/male/real/slim.png";
import body_muscular_m from "../images/bodyType/male/real/muscular.png";
import body_wide_m from "../images/bodyType/male/real/wide.png";

import body_anime_slim_m from "../images/bodyType/male/anime/Slim.png";
import body_anime_muscular_m from "../images/bodyType/male/anime/Muscular.png";
import body_anime_wide_m from "../images/bodyType/male/anime/Wide.png";

import boobs_flat_f from "../images/breastSize/female/real/Flat.png";
import boobs_small_f from "../images/breastSize/female/real/Small.png";
import boobs_medium_f from "../images/breastSize/female/real/Medium.png";
import boobs_large_f from "../images/breastSize/female/real/Large.png";
import boobs_huge_f from "../images/breastSize/female/real/Huge.png";

import boobs_anime_flat_f from "../images/breastSize/female/anime/Flat.png";
import boobs_anime_small_f from "../images/breastSize/female/anime/Small.png";
import boobs_anime_medium_f from "../images/breastSize/female/anime/Medium.png";
import boobs_anime_large_f from "../images/breastSize/female/anime/Large.png";
import boobs_anime_huge_f from "../images/breastSize/female/anime/Huge.png";

import butt_small_f from "../images/buttSize/female/real/Small.png";
import butt_medium_f from "../images/buttSize/female/real/Medium.png";
import butt_large_f from "../images/buttSize/female/real/Huge.png";
import butt_skinny_f from "../images/buttSize/female/real/Skinny.png";
import butt_athletic_f from "../images/buttSize/female/real/Athletic.png";

import butt_anime_small_f from "../images/buttSize/female/anime/Small.png";
import butt_anime_medium_f from "../images/buttSize/female/anime/Medium.png";
import butt_anime_large_f from "../images/buttSize/female/anime/Huge.png";
import butt_anime_skinny_f from "../images/buttSize/female/anime/Skinny.png";
import butt_anime_athletic_f from "../images/buttSize/female/anime/Athletic.png";

import anime_f from "../images/style/female/anime.png";
import real_f from "../images/style/female/real.png";
import real_m from "../images/style/male/real.png";
import anime_m from "../images/style/male/anime.png";

import arab_f from "../images/ethnicity/female/real/Arab.png";
import asian_f from "../images/ethnicity/female/real/Asian.png";
import black_f from "../images/ethnicity/female/real/Afro.png";
import latina_f from "../images/ethnicity/female/real/Latin.png";
import caucasian_f from "../images/ethnicity/female/real/European.png";
import indian_f from "../images/ethnicity/female/real/Indian.png";


import ethnicity_anime_arab_f from "../images/ethnicity/female/anime/Arab.png";
import ethnicity_anime_asian_f from "../images/ethnicity/female/anime/Asian.png";
import ethnicity_anime_black_f from "../images/ethnicity/female/anime/Afro.png";
import ethnicity_anime_latina_f from "../images/ethnicity/female/anime/Latin.png";
import ethnicity_anime_caucasian_f from "../images/ethnicity/female/anime/European.png";
import ethnicity_anime_indian_f from "../images/ethnicity/female/anime/Indian.png";

import arab_m from "../images/ethnicity/male/real/Arab.png";
import asian_m from "../images/ethnicity/male/real/Asian.png";
import black_m from "../images/ethnicity/male/real/Afro.png";
import latina_m from "../images/ethnicity/male/real/Latin.png";
import caucasian_m from "../images/ethnicity/male/real/European.png";
import indian_m from "../images/ethnicity/male/real/Indian.png";

import ethnicity_anime_arab_m from "../images/ethnicity/male/anime/Arab.png";
import ethnicity_anime_asian_m from "../images/ethnicity/male/anime/Asian.png";
import ethnicity_anime_black_m from "../images/ethnicity/male/anime/Afro.png";
import ethnicity_anime_latina_m from "../images/ethnicity/male/anime/Latin.png";
import ethnicity_anime_caucasian_m from "../images/ethnicity/male/anime/European.png";
import ethnicity_anime_indian_m from "../images/ethnicity/male/anime/Indian.png";

import eye_blue from "../images/eyeColor/female/eye_blue.png";
import eye_brown from "../images/eyeColor/female/eye_brown.png";
import eye_green from "../images/eyeColor/female/eye_green.png";
import eye_yellow from "../images/eyeColor/female/eye_yellow.png";
import eye_red from "../images/eyeColor/female/eye_red.png";

import straight_f from "../images/hairStyle/female/real/Straight.png";
import braids_f from "../images/hairStyle/female/real/Braids.png";
import bangs_f from "../images/hairStyle/female/real/Bangs.png";
import curly_f from "../images/hairStyle/female/real/Curly.png";
import bun_f from "../images/hairStyle/female/real/Bun.png";
import short_f from "../images/hairStyle/female/real/Short.png";
import long_f from "../images/hairStyle/female/real/Long.png";
import ponytail_f from "../images/hairStyle/female/real/Ponytail.png";
import pigtails_f from "../images/hairStyle/female/real/Pigtails.png";
import buzzCut_f from "../images/hairStyle/female/real/Buzz-cut.png";
import dreadlocks_f from "../images/hairStyle/female/real/Dreadlocks.png";
import bald_f from "../images/hairStyle/female/real/Bald.png";
import slickedBack_f from "../images/hairStyle/female/real/SlickedBack.png";

import hair_anime_straight_f from "../images/hairStyle/female/anime/Straight.png";
import hair_anime_braids_f from "../images/hairStyle/female/anime/Braids.png";
import hair_anime_bangs_f from "../images/hairStyle/female/anime/Bangs.png";
import hair_anime_curly_f from "../images/hairStyle/female/anime/Curly.png";
import hair_anime_bun_f from "../images/hairStyle/female/anime/Bun.png";
import hair_anime_short_f from "../images/hairStyle/female/anime/Short.png";
import hair_anime_long_f from "../images/hairStyle/female/anime/Long.png";
import hair_anime_ponytail_f from "../images/hairStyle/female/anime/Ponytail.png";
import hair_anime_pigtails_f from "../images/hairStyle/female/anime/Pigtails.png";
import hair_anime_buzzCut_f from "../images/hairStyle/female/anime/BuzzCut.png";
import hair_anime_dreadlocks_f from "../images/hairStyle/female/anime/Dreadlocks.png";
import hair_anime_bald_f from "../images/hairStyle/female/anime/Bald.png";
import hair_anime_slickedBack_f from "../images/hairStyle/female/anime/SlickedBack.png";

import curly_m from "../images/hairStyle/male/real/Curly.png";
import bun_m from "../images/hairStyle/male/real/Bun.png";
import short_m from "../images/hairStyle/male/real/Short.png";
import long_m from "../images/hairStyle/male/real/Long.png";
import buzzCut_m from "../images/hairStyle/male/real/Buzz-cut.png";
import dreadlocks_m from "../images/hairStyle/male/real/Dreadlocks.png";
import bald_m from "../images/hairStyle/male/real/Bald.png";
import slickedBack_m from "../images/hairStyle/male/real/Slicked-back.png";

import hair_anime_curly_m from "../images/hairStyle/male/anime/Curly.png";
import hair_anime_bun_m from "../images/hairStyle/male/anime/Bun.png";
import hair_anime_short_m from "../images/hairStyle/male/anime/Short.png";
import hair_anime_long_m from "../images/hairStyle/male/anime/Long.png";
import hair_anime_buzzCut_m from "../images/hairStyle/male/anime/Buzz-cut.png";
import hair_anime_dreadlocks_m from "../images/hairStyle/male/anime/Dreadlocks.png";
import hair_anime_bald_m from "../images/hairStyle/male/anime/Bald.png";
import hair_anime_slickedBack_m from "../images/hairStyle/male/anime/Slicked-back.png";


type TType = 'style'|'ethnicity'|'eyesColor'|'hairStyle'|'bodyType'|'breastSize'|'buttSize'
type TCategories = EModelStyle|EEthnicity|EEyesColor|EHairStyle|EBodyType|EBreastSize|EButtSize

export const preloadSrcStyle: any = [anime_f, real_f, real_m, anime_m];
export const preloadSrcHairStyle: any = [
  curly_m, bun_m, short_m, long_m, buzzCut_m, dreadlocks_m, bald_m, slickedBack_m, straight_f, braids_f, bangs_f, curly_f, bun_f, short_f, long_f, ponytail_f, pigtails_f, buzzCut_f, dreadlocks_f, bald_f, slickedBack_f,
  hair_anime_curly_m, hair_anime_bun_m, hair_anime_short_m, hair_anime_long_m, hair_anime_buzzCut_m, hair_anime_dreadlocks_m, hair_anime_bald_m, hair_anime_slickedBack_m, hair_anime_straight_f, hair_anime_braids_f, hair_anime_bangs_f, hair_anime_curly_f, hair_anime_bun_f, hair_anime_short_f, hair_anime_long_f, hair_anime_ponytail_f, hair_anime_pigtails_f, hair_anime_buzzCut_f, hair_anime_dreadlocks_f, hair_anime_bald_f, hair_anime_slickedBack_f,
];
export const preloadSrcEyeColor: any = [eye_blue, eye_brown, eye_green, eye_yellow, eye_red,];
export const preloadSrcEthnicity: any = [
  arab_f, asian_f, black_f, latina_f, caucasian_f, indian_f, arab_m, asian_m, black_m, latina_m, caucasian_m, indian_m,
  ethnicity_anime_arab_f, ethnicity_anime_asian_f, ethnicity_anime_black_f, ethnicity_anime_latina_f, ethnicity_anime_caucasian_f, ethnicity_anime_indian_f, ethnicity_anime_arab_m, ethnicity_anime_asian_m, ethnicity_anime_black_m, ethnicity_anime_latina_m, ethnicity_anime_caucasian_m, ethnicity_anime_indian_m,];
export const preloadSrcButt: any = [
  butt_small_f, butt_medium_f, butt_large_f, butt_skinny_f, butt_athletic_f,
  butt_anime_small_f, butt_anime_medium_f, butt_anime_large_f, butt_anime_skinny_f, butt_anime_athletic_f,];
export const preloadSrcBodyType: any = [
  body_petite_f, body_slim_f, body_athletic_f, body_voluptuous_f, body_curvy_f, body_muscular_f, body_slim_m, body_muscular_m, body_wide_m,
  body_anime_petite_f, body_anime_slim_f, body_anime_athletic_f, body_anime_voluptuous_f, body_anime_curvy_f, body_anime_muscular_f, body_anime_wide_f, body_anime_slim_m, body_anime_muscular_m, body_anime_wide_m,
];
export const preloadSrcBoobs: any = [
  boobs_flat_f, boobs_small_f, boobs_medium_f, boobs_large_f, boobs_huge_f,
  boobs_anime_flat_f, boobs_anime_small_f, boobs_anime_medium_f, boobs_anime_large_f, boobs_anime_huge_f,
];

export const preloadSrcList: any = [
  ...preloadSrcStyle, ...preloadSrcHairStyle, ...preloadSrcEyeColor, ...preloadSrcEthnicity, ...preloadSrcButt, ...preloadSrcBodyType, ...preloadSrcBoobs
]

const modelStyle: { [GENDER in EGender]: { [STYLE in EModelStyle]: any } } = {
  [EGender.Female]: {
    [EModelStyle.Anime]: anime_f,
    [EModelStyle.Real]: real_f,
  },
  [EGender.Male]: {
    [EModelStyle.Anime]: anime_m,
    [EModelStyle.Real]: real_m,
  }
}

type TEthnicity = { [GENDER in EGender]: { [DATA in EEthnicity]: any } };
const ethnicityReal: TEthnicity = {
  [EGender.Female]: {
    [EEthnicity.Arab]: arab_f,
    [EEthnicity.Asian]: asian_f,
    [EEthnicity.Afro]: black_f,
    [EEthnicity.Latin]: latina_f,
    [EEthnicity.Caucasian]: caucasian_f,
    [EEthnicity.Indian]: indian_f,
  },
  [EGender.Male]: {
    [EEthnicity.Arab]: arab_m,
    [EEthnicity.Asian]: asian_m,
    [EEthnicity.Afro]: black_m,
    [EEthnicity.Latin]: latina_m,
    [EEthnicity.Caucasian]: caucasian_m,
    [EEthnicity.Indian]: indian_m,
  },
}
const ethnicityAnime: TEthnicity = {
  [EGender.Female]: {
    [EEthnicity.Arab]: ethnicity_anime_arab_f,
    [EEthnicity.Asian]: ethnicity_anime_asian_f,
    [EEthnicity.Afro]: ethnicity_anime_black_f,
    [EEthnicity.Latin]: ethnicity_anime_latina_f,
    [EEthnicity.Caucasian]: ethnicity_anime_caucasian_f,
    [EEthnicity.Indian]: ethnicity_anime_indian_f,
  },
  [EGender.Male]: {
    [EEthnicity.Arab]: ethnicity_anime_arab_m,
    [EEthnicity.Asian]: ethnicity_anime_asian_m,
    [EEthnicity.Afro]: ethnicity_anime_black_m,
    [EEthnicity.Latin]: ethnicity_anime_latina_m,
    [EEthnicity.Caucasian]: ethnicity_anime_caucasian_m,
    [EEthnicity.Indian]: ethnicity_anime_indian_m,
  },
}
const ethnicity: Record<EModelStyle, TEthnicity> = {
  [EModelStyle.Real]: ethnicityReal,
  [EModelStyle.Anime]: ethnicityAnime,
}

const eyesColor: { [GENDER in EGender]: { [DATA in EEyesColor]: any } } = {
  [EGender.Female]: {
    [EEyesColor.Blue]: eye_blue,
    [EEyesColor.Brown]: eye_brown,
    [EEyesColor.Green]: eye_green,
    [EEyesColor.Yellow]: eye_yellow,
    [EEyesColor.Red]: eye_red,
  },
  [EGender.Male]: {
    [EEyesColor.Blue]: eye_blue,
    [EEyesColor.Brown]: eye_brown,
    [EEyesColor.Green]: eye_green,
    [EEyesColor.Yellow]: eye_yellow,
    [EEyesColor.Red]: eye_red,
  },
}

type THairStyle = { [GENDER in EGender]: { [DATA in EHairStyle]: any } };
const hairStyleReal: THairStyle = {
  [EGender.Female]: {
    [EHairStyle.Straight]: straight_f,
    [EHairStyle.Braids]: braids_f,
    [EHairStyle.Bangs]: bangs_f,
    [EHairStyle.Curly]: curly_f,
    [EHairStyle.Bun]: bun_f,
    [EHairStyle.Short]: short_f,
    [EHairStyle.Long]: long_f,
    [EHairStyle.Ponytail]: ponytail_f,
    [EHairStyle.Pigtails]: pigtails_f,
    [EHairStyle.BuzzCut]: buzzCut_f,
    [EHairStyle.SlickedBack]: slickedBack_f,
    [EHairStyle.Dreadlocks]: dreadlocks_f,
    [EHairStyle.Bald]: bald_f,
  },
  [EGender.Male]: {
    [EHairStyle.Straight]: undefined,
    [EHairStyle.Braids]: undefined,
    [EHairStyle.Bangs]: undefined,
    [EHairStyle.Curly]: curly_m,
    [EHairStyle.Bun]: bun_m,
    [EHairStyle.Short]: short_m,
    [EHairStyle.Long]: long_m,
    [EHairStyle.Ponytail]: undefined,
    [EHairStyle.Pigtails]: undefined,
    [EHairStyle.BuzzCut]: buzzCut_m,
    [EHairStyle.SlickedBack]: slickedBack_m,
    [EHairStyle.Dreadlocks]: dreadlocks_m,
    [EHairStyle.Bald]: bald_m,
  },
}
const hairStyleAnime: THairStyle = {
  [EGender.Female]: {
    [EHairStyle.Straight]: hair_anime_straight_f,
    [EHairStyle.Braids]: hair_anime_braids_f,
    [EHairStyle.Bangs]: hair_anime_bangs_f,
    [EHairStyle.Curly]: hair_anime_curly_f,
    [EHairStyle.Bun]: hair_anime_bun_f,
    [EHairStyle.Short]: hair_anime_short_f,
    [EHairStyle.Long]: hair_anime_long_f,
    [EHairStyle.Ponytail]: hair_anime_ponytail_f,
    [EHairStyle.Pigtails]: hair_anime_pigtails_f,
    [EHairStyle.BuzzCut]: hair_anime_buzzCut_f,
    [EHairStyle.SlickedBack]: hair_anime_slickedBack_f,
    [EHairStyle.Dreadlocks]: hair_anime_dreadlocks_f,
    [EHairStyle.Bald]: hair_anime_bald_f,
  },
  [EGender.Male]: {
    [EHairStyle.Straight]: undefined,
    [EHairStyle.Braids]: undefined,
    [EHairStyle.Bangs]: undefined,
    [EHairStyle.Curly]: hair_anime_curly_m,
    [EHairStyle.Bun]: hair_anime_bun_m,
    [EHairStyle.Short]: hair_anime_short_m,
    [EHairStyle.Long]: hair_anime_long_m,
    [EHairStyle.Ponytail]: undefined,
    [EHairStyle.Pigtails]: undefined,
    [EHairStyle.BuzzCut]: hair_anime_buzzCut_m,
    [EHairStyle.SlickedBack]: hair_anime_slickedBack_m,
    [EHairStyle.Dreadlocks]: hair_anime_dreadlocks_m,
    [EHairStyle.Bald]: hair_anime_bald_m,
  },
}
const hairStyle: Record<EModelStyle, THairStyle> = {
  [EModelStyle.Real]: hairStyleReal,
  [EModelStyle.Anime]: hairStyleAnime,
}

type TBodyType = { [GENDER in EGender]: { [DATA in Partial<EBodyType>]: any } };
const bodyTypeReal: TBodyType = {
  [EGender.Female]: {
    [EBodyType.Petite]: body_petite_f,
    [EBodyType.Slim]: body_slim_f,
    [EBodyType.Athletic]: body_athletic_f,
    [EBodyType.Voluptuous]: body_voluptuous_f,
    [EBodyType.Curvy]: body_curvy_f,
    [EBodyType.Muscular]: body_muscular_f,
    [EBodyType.Wide]: undefined,
  },
  [EGender.Male]: {
    [EBodyType.Petite]: undefined,
    [EBodyType.Slim]: body_slim_m,
    [EBodyType.Athletic]: undefined,
    [EBodyType.Voluptuous]: undefined,
    [EBodyType.Curvy]: undefined,
    [EBodyType.Muscular]: body_muscular_m,
    [EBodyType.Wide]: body_wide_m,
  },
}
const bodyTypeAnime: TBodyType = {
  [EGender.Female]: {
    [EBodyType.Petite]: body_anime_petite_f,
    [EBodyType.Slim]: body_anime_slim_f,
    [EBodyType.Athletic]: body_anime_athletic_f,
    [EBodyType.Voluptuous]: body_anime_voluptuous_f,
    [EBodyType.Curvy]: body_anime_curvy_f,
    [EBodyType.Muscular]: body_anime_muscular_f,
    [EBodyType.Wide]: body_anime_wide_f,
  },
  [EGender.Male]: {
    [EBodyType.Petite]: undefined,
    [EBodyType.Slim]: body_anime_slim_m,
    [EBodyType.Athletic]: undefined,
    [EBodyType.Voluptuous]: undefined,
    [EBodyType.Curvy]: undefined,
    [EBodyType.Muscular]: body_anime_muscular_m,
    [EBodyType.Wide]: body_anime_wide_m,
  },
}
const bodyType: Record<EModelStyle, TBodyType> = {
  [EModelStyle.Real]: bodyTypeReal,
  [EModelStyle.Anime]: bodyTypeAnime,
}

type TBreastSize = { [GENDER in EGender]: { [DATA in Partial<EBreastSize>]: any } };
const breastSizeReal: TBreastSize = {
  [EGender.Female]: {
    [EBreastSize.Flat]: boobs_flat_f,
    [EBreastSize.Small]: boobs_small_f,
    [EBreastSize.Medium]: boobs_medium_f,
    [EBreastSize.Large]: boobs_large_f,
    [EBreastSize.Huge]: boobs_huge_f,
  },
  [EGender.Male]: {
    [EBreastSize.Flat]: undefined,
    [EBreastSize.Small]: undefined,
    [EBreastSize.Medium]: undefined,
    [EBreastSize.Large]: undefined,
    [EBreastSize.Huge]: undefined,
  },
}
const breastSizeAnime: TBreastSize = {
  [EGender.Female]: {
    [EBreastSize.Flat]: boobs_anime_flat_f,
    [EBreastSize.Small]: boobs_anime_small_f,
    [EBreastSize.Medium]: boobs_anime_medium_f,
    [EBreastSize.Large]: boobs_anime_large_f,
    [EBreastSize.Huge]: boobs_anime_huge_f,
  },
  [EGender.Male]: {
    [EBreastSize.Flat]: undefined,
    [EBreastSize.Small]: undefined,
    [EBreastSize.Medium]: undefined,
    [EBreastSize.Large]: undefined,
    [EBreastSize.Huge]: undefined,
  },
}
const breastSize: Record<EModelStyle, TBreastSize> = {
  [EModelStyle.Real]: breastSizeReal,
  [EModelStyle.Anime]: breastSizeAnime,
}

type TButtSize = { [GENDER in EGender]: { [DATA in Partial<EButtSize>]: any } };
const buttSizeReal: TButtSize = {
  [EGender.Female]: {
    [EButtSize.Small]: butt_small_f,
    [EButtSize.Medium]: butt_medium_f,
    [EButtSize.Large]: butt_large_f,
    [EButtSize.Skinny]: butt_skinny_f,
    [EButtSize.Athletic]: butt_athletic_f,

  },
  [EGender.Male]: {
    [EButtSize.Small]: undefined,
    [EButtSize.Medium]: undefined,
    [EButtSize.Large]: undefined,
    [EButtSize.Skinny]: undefined,
    [EButtSize.Athletic]: undefined,
  },
}
const buttSizeAnime: TButtSize = {
  [EGender.Female]: {
    [EButtSize.Small]: butt_anime_small_f,
    [EButtSize.Medium]: butt_anime_medium_f,
    [EButtSize.Large]: butt_anime_large_f,
    [EButtSize.Skinny]: butt_anime_skinny_f,
    [EButtSize.Athletic]: butt_anime_athletic_f,

  },
  [EGender.Male]: {
    [EButtSize.Small]: undefined,
    [EButtSize.Medium]: undefined,
    [EButtSize.Large]: undefined,
    [EButtSize.Skinny]: undefined,
    [EButtSize.Athletic]: undefined,
  },
}
const buttSize: Record<EModelStyle, TButtSize> = {
  [EModelStyle.Real]: buttSizeReal,
  [EModelStyle.Anime]: buttSizeAnime,
}

const data: { [KEY in TType]: any } = {
  style: modelStyle,
  ethnicity: ethnicity,
  eyesColor: eyesColor,
  hairStyle: hairStyle,
  bodyType: bodyType,
  breastSize: breastSize,
  buttSize: buttSize,
}

export const getImageByModel = (gender: EGender, type: TType, category: TCategories, style?: EModelStyle): any => {
  if (style) return data[type]?.[style]?.[gender]?.[category] || null
  return data[type]?.[gender]?.[category] || null
}

export const getModelAge = (age: number): string => {
  if (age < 20) return 'TEEN_18';
  if (age < 30) return '20_S'
  if (age < 40) return '30_S'
  return '40-50'
}
export const getModalHairColor = (hairColor: EHairColor): string => {
  if (hairColor === EHairColor.Blonde) return '#fff';
  else if (hairColor === EHairColor.Ginger) return '#b06500'
  else if (hairColor === EHairColor.Redhead) return '#B83C08'
  else if (hairColor === EHairColor.Brunette) return '#664238'
  else if (hairColor === EHairColor.Multicolor) return 'linear-gradient(to left, #fff 0%, red 33%, yellow 66%, pink 100%)'
  return hairColor;
}

export type TPersonality = { icon: string, title: string, text: string }
export const personalityData: { [KEY in string]: TPersonality } = {
  caregiver: {
    icon: '🙆‍♀️',
    title: 'Caregiver',
    text: 'Nurturing, protective, and always there to offer comfort.'
  },
  sage: {icon: '🧠', title: 'Sage', text: 'Wise, reflective, and a source of guidance.'},
  innocent: {icon: '😇', title: 'Innocent', text: 'Optimistic, naive, and sees world with wonder.'},
  jester: {icon: '🤡', title: 'Jester', text: 'Playful, humorous, and always there to make you laugh.'},
  temptress: {
    icon: '💘',
    title: 'Temptress',
    text: 'Flirtatious, playful, and always leaving you wanting more.'
  },
  dominant: {icon: '👑', title: 'Dominant', text: 'Assertive, controlling, and commanding.'},
  submissive: {icon: '🥺', title: 'Submissive', text: 'Obedient, yielding, and happy to follow.'},
  lover: {icon: '💞', title: 'Lover', text: 'Romantic, affectionate, and cherishes deep emotional'},
  nympho: {icon: '🔥', title: 'Nympho', text: 'Insatiable, passionate, and constantly craving intimacy.'},
  mean: {icon: '❄️', title: 'Mean', text: 'Cold, dismissive, and often sarcastic.'},
  confidant: {
    icon: '😎',
    title: 'Confidant',
    text: 'Trustworthy, a good listener, and always can offer advice.'
  },
  experimenter: {
    icon: '💡',
    title: 'Experimenter',
    text: 'Curious, willing, and always eager to try new things.'
  },
}

export const getModelPersonalityData = (personality: string): TPersonality => {
  return personalityData[personality]
}

type TRelationship = { icon: string, female: string, male: string }
export const relationshipData: { [VALUE in string]: { male: string, female: string, icon: string } } = {
  stranger: {icon: '👽', female: 'Stranger', male: 'Stranger'},
  schoolmate: {icon: '🎓', female: 'School Mate', male: 'Schoolmate'},
  colleague: {icon: '💼', female: 'Colleague', male: 'Work Colleague'},
  mentor: {icon: '📚', female: 'Mentor', male: 'Mentor'},
  relationship: {icon: '❤️', female: 'Girlfriend', male: 'Boyfriend'},
  'sex friend': {icon: '🔞', female: 'Sex Friend', male: 'Sex Friend'},
  spouse: {icon: '💍', female: 'Wife', male: 'Husband'},
  lover: {icon: '👑', female: 'Mistress', male: 'Lover'},
  friend: {icon: '👋🏻', female: 'Friend', male: 'Friend'},
  'best friend': {icon: '🤝', female: 'Best Friend', male: 'Best Friend'},
  summarized: {icon: '🍑', female: 'Step Sister', male: 'Step Brother'},
  'step father': {icon: '💋', female: 'Step Mom', male: 'Step Father'},
}
export const getRelationshipData = (personality: string): TRelationship => {
  return relationshipData[personality]
}