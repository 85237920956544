import React, {FC} from 'react';
import asyncModal from "react-async-modal";
import close from "../../assets/icons/close.svg";
import {ReactSVG} from "react-svg";
import girl from '../assets/images/girl.png';
import {isSub, thumb} from "../../modules/utils";
import HomeStore from "../../store/HomeStore";
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import {ESubscriptionStatus} from "../../modules/rest";
import ModalNoMoneySubscription from "./ModalNoMoneySubscription";
import ModalNoMoneyCoins from "./ModalNoMoneyCoins";

interface Props {
  resolve: (path?: string) => void;
  position: 'create-ai' | 'chat';
  imageId?: string;
}

const ModalNoMoney: FC<Props> = observer(({position, imageId, resolve}) => {

  const handleClose = () => {
    resolve()
  }

  return (
    <div className='modal-container big modal-with_img'>
      <ReactSVG src={close} className='modal-close' onClick={handleClose}/>
      <div className="modal-with_img-image">
        <img src={thumb(imageId || HomeStore.randomModels?.[0]?.mainPhotoImage?.id, 800) || girl} alt="girl"/>
      </div>
        {isSub(AppStore?.user?.subscription?.status)
          ?
          <ModalNoMoneyCoins onClick={() => resolve('/premium/credits')} position={position}/>
          :
          <ModalNoMoneySubscription onClick={() => resolve('/premium/subscription')} position={position}/>
        }
    </div>
  );
})

const openModalNoMoney = (position: 'create-ai' | 'chat', imageId?: string): Promise<string> => {
  return asyncModal(ModalNoMoney, {position, imageId}, {showCloseIcon: false, center: true});
};

export {openModalNoMoney};