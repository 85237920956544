import React, {FC} from 'react';

interface Props {
  images: any[];
}

const PreloadImages: FC<Props> = ({images}) => {
  return (
    <div className='preload-hidden-images'>
      {images.map((image: any, i) => (
        <img src={image} alt="img-hidden" key={i}/>
      ))}
    </div>
  );
}

export default PreloadImages;