import React, {FC} from 'react';
import {useTranslation} from "react-i18next";
import {APP_COMPANY, APP_COMPANY_ADDRESS} from "../../../modules/constants";

interface Props {
  className?: string;
}

const HomeFooterContacts: FC<Props> = ({className}) => {
  const {t} = useTranslation();

  return (
    <div className={`home-footer-contacts ${className || ''}`}>
      <div className='text-muted-14'>{t('CONTACTS')}</div>
      <div className='text-bold text-muted mt-3'>
        {APP_COMPANY} <br/>
        {APP_COMPANY_ADDRESS}
      </div>
    </div>
  );
}

export default HomeFooterContacts;