import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {APP_COMPANY, APP_COMPANY_EMAIL_SUPPORT, APP_COMPANY_NAME} from "../../../modules/constants";

interface Props {
}

const USCExemption: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='USC_EXEMPTION'>
      <p>At {APP_COMPANY}, {APP_COMPANY_NAME} ("application") operates exclusively as a platform for AI-generated content. No real individuals are depicted or involved in the creation of any content on our platform. All images available on the application are entirely produced by artificial intelligence (AI) technology.
      </p>
      <p>As a result, the Federal Labeling and Record-Keeping Law, 18 U.S.C. 2257, does not apply to our platform.
      </p>
      <p>If you have any questions or require further clarification regarding the 18 U.S.C. 2257 Exemption, please contact us at <a
        href={`mailto:${APP_COMPANY_EMAIL_SUPPORT}`} target='_blank'>{APP_COMPANY_EMAIL_SUPPORT}</a>.
      </p>
    </LegalInformationPageWrapper>
  );
}

export default USCExemption;