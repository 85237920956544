import React, {FC, useState} from 'react';
import {ReactSVG} from "react-svg";
import sound_svg from '../../../../assets/icons/sound.svg';
import text_svg from '../../../../assets/icons/text.svg';
import {IConversation} from "../../../../modules/rest";
import {API} from "../../../../modules/api";
import {openModalNoMoney} from "../../../../modals";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {runInAction} from "mobx";
import ChatStore from "../../../../store/ChatStore";

interface Props {
  conversation?: IConversation;
  className?: string;
}

const ChatTextOrVoice: FC<Props> = ({conversation, className}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const toggle = async () => {
    if (loading || !conversation) return;
    try {
      setLoading(true);
      const newIsVoice =  !Boolean(conversation.isVoice);
      const res = await API.Conversations.setConversationMode(conversation.id, {isVoice: newIsVoice});
      if (conversation.id === res.id && ChatStore.activeChat) {
        runInAction(() => {
          ChatStore.activeChat!.isVoice = res.isVoice;
        })
      }
      await ChatStore.getList();
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code !== 402) {
        toast.error(e);
      }
    } finally {
      setLoading(false);
    }
  }
  if (!conversation || !conversation?.isVoiceAvailable) return null;

  return (
    <div className={`chat-voice-panel${conversation?.isVoice ? ' active' : ''} ${className || ''}`} onClick={toggle}>
      <div className="chat-voice-panel-active"/>
      <ReactSVG src={text_svg} className='react-icon chat-voice-panel-item item-text'/>
      <ReactSVG src={sound_svg} className='react-icon chat-voice-panel-item item-voice'/>
    </div>
  );
}

export default ChatTextOrVoice;
