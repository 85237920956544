import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RefPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const ref = window.location.pathname.replace('/r/', '');
    localStorage.setItem('ref', ref);
    navigate('/', { replace: true });
  }, [navigate]);

  return null;
};

export default RefPage;
