import asyncModal from 'react-async-modal';
import React, {FC, useEffect, useRef, useState} from "react";
import {IAsset} from "../modules/rest";
import {thumb} from "../modules/utils";
import {ReactSVG} from "react-svg";
import collapse from "../assets/icons/collapse.svg";
import trash from "../assets/icons/trash.svg";
import Slider from "react-slick";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface Props {
  resolve?: (remove?: boolean) => void;
  removable?: boolean;
  initialIdx?: number
  image?: IAsset|IAsset[]|null;
}

const GalleryModal: FC<Props> = ({resolve, image, initialIdx, removable}) => {
  const _timout: any = useRef();
  const [swiping, setSwiping] = useState(false);

  useEffect(() => {
    return () => {
      clear()
    }
  }, []);

  const clear = () => {
    clearTimeout(_timout?.current)
  }


  const handleClose = () => {
    if (swiping) return;
    resolve!();
  }

  const onMouseInOut = (status: boolean) => () => {
    clear();
    _timout.current = setTimeout(() => setSwiping(status), status ? 200 : 0);
  }

  return (
    <div className='modal-container modal-gallery'
         onMouseDown={onMouseInOut(true)}
         onMouseUp={onMouseInOut(false)}
    >
      <div className='btn-expand-container btn-trash-container'>
        <ReactSVG src={collapse} className='react-icon btn-expand'/>
        {removable && <ReactSVG src={trash} className='react-icon btn-trash' onClick={() => resolve!(true)}/>}
        {Array.isArray(image)
          ?
          <Slider {...settings} initialSlide={initialIdx}>
            {image.map(item => (
              <div className='gallery-modal-image' key={item.id}
                   onClick={handleClose}>
                <img src={thumb(item.id, 800)} alt="img"/>
              </div>
            ))}
          </Slider>
          :
          <div className='gallery-modal-image' onClick={handleClose}>
            <img src={thumb(image?.id, 800)} alt=""/>
          </div>
        }
      </div>
    </div>
  );
};

const openGalleryModal = (props: Props): Promise<boolean> => {
  return asyncModal(GalleryModal, props, {showCloseIcon: false, center: true});
};

export {openGalleryModal};
