import React, {FC} from 'react';
import Button from "../../../components/Button";
import magic_wand from '../../../assets/icons/magic_wand.svg';
import diamond from '../../../assets/icons/diamond.svg';
import TopCarousel from "./TopCarousel";
import GenderSelect from "../../../components/GenderSelect";
import {observer} from "mobx-react";
import AppStore from "../../../store/AppStore";
import {IModel} from "../../../modules/rest";
import HomeTimer from "./HomeTimer";
import {useTranslation} from "react-i18next";
import {isSub} from "../../../modules/utils";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";

interface Props {
  onAnswer: (model: IModel) => void;
}

const TopSection: FC<Props> = observer(({onAnswer}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const [onAuthClick] = useAuth();

  const handlePremiumNavigate = () => {
    navigate('/premium/subscription');
  }

  const handlePremiumClick = () => {
    if (!AppStore.user?.id) {
      onAuthClick('login', handlePremiumNavigate);
    } else {
      handlePremiumNavigate()
    }
  }

  return (
    <section className='mt-0 section-bg home-top-section'>
        <div className="home-top-container">
          <div className="home-top-left">
            <div className='home-top-gender'>
              <GenderSelect onChange={AppStore.changeGender} gender={AppStore.gender}/>
            </div>
            <h1 dangerouslySetInnerHTML={{__html: t('HOME_TITLE') || ''}}/>
            {/*<div className='home-top-title'/>*/}
            <div className='home-top-text'>
              {t('HOME_TEXT')}
            </div>
            <Button
              href={'/create-ai'}
              size='lg'
              icon={magic_wand}
              title={'CREATE_YOUR_AI'}
            />
          </div>
          <TopCarousel onAnswer={onAnswer}/>
        </div>
        {!isSub(AppStore.user?.subscription?.status) &&
        <div className='home-top-footer'>
          <div className='text-bold me-2 pe-1'>{t('FIRST_SUB')}</div>
          <Button
            onClick={handlePremiumClick}
            icon={diamond}
            btnType='secondary'
            size='sm'
            title='UP_OFF_75'
          />
          <HomeTimer/>
        </div>
        }
    </section>
  );
})

export default TopSection;