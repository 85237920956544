import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import Header, {HeaderSecondary} from "../containers/Header";
import Footer from "../containers/Footer";
import Sider from "../containers/Sider";
import AppStore from "../store/AppStore";
import Button from "../components/Button";
import {ReactSVG} from "react-svg";
import success_svg from "./PaymentStatusPage/assets/icons/success.svg";
import failed_svg from "./PaymentStatusPage/assets/icons/failed.svg";

interface Props {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorFallback: FC<Props> = ({error, resetErrorBoundary}) => {
  const {t} = useTranslation();

  return (
    <>
      <HeaderSecondary  title='ERROR'/>
      <main className='align-items-center px-3'>
        <h2 className='page-title'>{t('ERROR')}</h2>
        <div className={`payment-item w-100 failed mt-4`}>
          <div className='text-dark mt-2 letter-uppercase'>{t('ERROR_TEXT')}</div>
          <div className='modal-footer w-100'>
            <Button
              btnType={'secondary'}
              onClick={() => window.location.replace('/')}
              title='HOME'
            />
          </div>
        </div>

        {/*<div className="alert alert-danger mt-5" style={{width: 800}}>*/}
        {/*<p className="lead"> {error.message}</p>*/}
        {/*<details className="cursor-pointer mb-3">*/}
        {/*  <pre className="p-3 border-1 bg-light">{error.stack}</pre>*/}
        {/*</details>*/}
        {/*</div>*/}
      </main>
    </>
  );
};

export default ErrorFallback;
