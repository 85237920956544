import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {APP_COMPANY} from "../../../modules/constants";

interface Props {
}

const ComplainPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='COMPLAIN_POLICY'>

      <p>At {APP_COMPANY}, we respect and uphold the intellectual property rights of others and are dedicated to adhering to the Digital Millennium Copyright Act (DMCA) and other relevant copyright laws. Our Copyright Compliance Policy outlines the steps we take to address notifications of copyright infringement and provides guidance on how to contact us if you believe your copyrighted material has been used on our platform without authorization.
      </p>
      <h4>1. Reporting Copyright Infringement
      </h4>
      <p>If you believe that materials transmitted or created through <a href="https://sildagroup.ltd/" target='_blank'>https://sildagroup.ltd/</a>  (the “Website”) infringe your copyright, you (or your agent) may send us a notice requesting removal or blocking of the material. Please provide the following information in writing:
      </p>
      <ul>
        <li>An electronic or physical signature of the owner (or person authorized to act on behalf of the owner) of the copyrighted work.
        </li>
        <li>A description of the copyrighted work that you claim has been infringed upon and sufficient information for us to locate the work.
        </li>
        <li>Your address, telephone number, and e-mail address.
        </li>
        <li>A statement by you that you have a good-faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
        </li>
        <li>A statement by you, made under penalty of perjury, that the above information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner's behalf.
        </li>
      </ul>
      <h4>2. Copyright Compliance Policy Notices Response
      </h4>
      <p>Upon receiving a complete infringement notice, we will take the following actions:
      </p>
      <ul>
        <li>Review and confirm that the documents meet copyright laws requirements.
        </li>
        <li>Take preliminary actions against the alleged infringement within 1-3 days, including blocking the link.
        </li>
        <li>Notify the alleged infringer and request an explanation or counter evidence.
        </li>
      </ul>
      <h4>3. Counter Notification
      </h4>
      <p>If you believe in good faith that someone has wrongly filed a notice of copyright infringement against you, you may send us a counter-notice. Upon receiving a counter-notice, we will notify the claimant and hold the process for 10-14 days. If the copyright owner does not initiate legal action within this period, we will re-enable the content.
      </p>
      <h4>4. Contact Information
      </h4>
      <p>Notices and counter-notices should be sent to us via email at <a href="mailto:support@sildagroup.ltd" target='_blank'>support@sildagroup.ltd</a>. We are committed to addressing concerns promptly and ensuring a positive experience for all our users.
      </p>
      <h4>5. Termination
      </h4>
      <p>We reserve the right to suspend or terminate the use of the Website by anyone engaged in suspected infringement as described above.
      </p>
    </LegalInformationPageWrapper>
  );
}

export default ComplainPolicy;