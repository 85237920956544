import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import StepFooter from "../StepFooter";
import StepHairColor from "./StepHairColor";
import {EGender, EHairColor, EHairStyle, ICreateModelRequest} from "../../../../modules/rest";
import {observer} from "mobx-react";
import {
  getImageByModel,
  preloadSrcBodyType,
  preloadSrcBoobs,
  preloadSrcButt,
  preloadSrcHairStyle
} from "../../assets/modules/utils";
import AppStore from "../../../../store/AppStore";
import PreloadImages from "../../../../components/PreloadImages";
import {useTranslation} from "react-i18next";


interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step3: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const [hairStyle, setHairStyle] = useState(data.hairStyle || (AppStore.gender === EGender.Male ? EHairStyle.Curly : EHairStyle.Straight));
  const [hairColor, setHairColor] = useState(data.hairColor || EHairColor.Blonde);

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {hairStyle, hairColor} : undefined);
  }

  return (
    <div className='create-ai-step'>
      <PreloadImages images={[...preloadSrcBodyType, ...preloadSrcBoobs, ...preloadSrcButt]} />
      <StepTitle title='CHOOSE_HAIR_STYLE' onStepChange={handleStepChange} step={3}/>
      <div className='create-ai-list'>
        {Object.values(EHairStyle).map(hair => {
          const image = getImageByModel(AppStore.gender, 'hairStyle', hair, data.style);
          if(!image) return null;
          return (
            <ActiveElement radio small onClick={() => setHairStyle(hair as EHairStyle)} key={hair}
                           active={hairStyle === hair}>
              <div className='model-card'>
                <div className="model-card-category"><span>{t(hair.toUpperCase())}</span></div>
                <img src={image} alt=""/>
              </div>
            </ActiveElement>
          )
        })}
      </div>
      <StepHairColor value={hairColor} onChange={setHairColor}/>
      <StepFooter
        onBack={() => handleStepChange(-1)}
        onNext={() => handleStepChange(1)}
      />
    </div>
  );
})

export default Step3;