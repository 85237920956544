import React, {FC} from 'react';
import Button from "../../components/Button";
import burger_svg from '../../assets/icons/burger.svg';
import arrow_left from '../../assets/icons/arrow_left.svg';
import {observer} from "mobx-react";
import AppStore from "../../store/AppStore";
import {useTranslation} from "react-i18next";

interface Props {
  title?: string;
  onClick?: () => void;
  onGenderChange?: () => void;
  back?: boolean;
  gender?: boolean;
  coins?: boolean;
  subtext?: any;
  children?: any;
}

const HeaderSecondary: FC<Props> = observer(({
                                               title,
                                               onClick,
                                               back,
                                               gender,
                                               coins,
                                               onGenderChange,
                                               subtext,
                                               children,
                                             }) => {
  const {t} = useTranslation();
  return (

    <header className='header-secondary'>
      <div className="header">
        {onClick
        ?
          <div className='position-absolute z-1' style={{left: 0}}>
            <Button
              className='header-burger'
              icon={back ? arrow_left : burger_svg}
              btnType='secondary'
              onClick={onClick}
            />
          </div>
        :
        null
        }
        <div className='header-secondary-center'>
          <h2>{t(title || '')}</h2>
          {subtext  ||
            ((coins && AppStore.user?.id) ? <div className='text-bold-12'>💎{AppStore.user.credits}</div> : null)
          }
        </div>
        <div className='position-absolute' style={{right: 0}}>
          {children || null}
          {gender &&
            <div className={`header-secondary-gender ${AppStore.gender}`} onClick={() => {
              AppStore.changeGender('toggle');
              if (onGenderChange) onGenderChange();
            }}>
              <div className='header-secondary-gender-switch'/>
            </div>
          }
        </div>
      </div>
    </header>
  );
})

export default HeaderSecondary;