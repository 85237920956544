import {EGender} from "../../../modules/rest";
import templates_female from '../jsons/templates/female.json';
import templates_male from '../jsons/templates/male.json';
import poses_regular from '../jsons/poses/regular.json';
import poses_premium from '../jsons/poses/premium.json';
import body_female from '../jsons/body/female.json';
import body_male from '../jsons/body/male.json';
import clothing_female from '../jsons/clothing/female.json';
import clothing_male from '../jsons/clothing/male.json';
import view_female from '../jsons/view/female.json';
import view_male from '../jsons/view/male.json';
import {shuffleArr} from "../../../modules/utils";

export type TTemplatePrompt = {
  button: string,
  prompt: string,
}

const generatePromptTemplatesJSON = {
  male: templates_male,
  female: templates_female,
}
export const generatePromptTemplates = (gender?: EGender): TTemplatePrompt[] => {
  const json = generatePromptTemplatesJSON[gender || EGender.Female];
  return shuffleArr(json).splice(0, 3);
}

export enum EGeneratePromptTabs {
  'Poses' = 'Poses',
  'Body' = 'Body',
  'Clothing' = 'Clothing',
  'View' = 'View'
}

export enum EGeneratePromptTabsWithoutPoses {
  'Body' = 'Body',
  'Clothing' = 'Clothing',
  'View' = 'View'
}


export const promptsByType: {
  [TAB in EGeneratePromptTabsWithoutPoses]: {
    male: { regular: TTemplatePrompt[], premium?: TTemplatePrompt[] },
    female: { regular: TTemplatePrompt[], premium?: TTemplatePrompt[] }
  }
} = {
  // [EGeneratePromptTabs.Poses]: {male: {regular: poses_male}, female: {regular: poses_female}},
  [EGeneratePromptTabs.Body]: {male: {regular: body_male}, female: {regular: body_female}},
  [EGeneratePromptTabs.Clothing]: {male: {regular: clothing_male}, female: {regular: clothing_female}},
  // [EGeneratePromptTabs.Scene]: {male: {regular: scene_male}, female: {regular: scene_female}},
  [EGeneratePromptTabs.View]: {male: {regular: view_male}, female: {regular: view_female}},
}

export const getPosesArr = (isSub?: boolean): { regular: string[], premium: string[] } => {
  if (isSub) return {regular: [...poses_regular, ...poses_premium], premium: []}
  return {regular: poses_regular, premium: poses_premium}
}