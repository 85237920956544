import i18n, {Resource} from "i18next";
import {initReactI18next} from "react-i18next";
import en from './translates/en/translation.json';
import faq_en from './translates/en/faq.json';
import ru from './translates/ru/translation.json';
import faq_ru from './translates/ru/faq.json';
import hi from './translates/hi/translation.json';
import faq_hi from './translates/hi/faq.json';
import es from './translates/es/translation.json';
import faq_es from './translates/es/faq.json';

import de from './translates/de/translation.json';
import faq_de from './translates/de/faq.json';
import fr from './translates/fr/translation.json';
import faq_fr from './translates/fr/faq.json';
import id from './translates/id/translation.json';
import faq_id from './translates/id/faq.json';
import it from './translates/it/translation.json';
import faq_it from './translates/it/faq.json';
import ja from './translates/ja/translation.json';
import faq_ja from './translates/ja/faq.json';
import ko from './translates/ko/translation.json';
import faq_ko from './translates/ko/faq.json';
import pt from './translates/pt/translation.json';
import faq_pt from './translates/pt/faq.json';
import th from './translates/th/translation.json';
import faq_th from './translates/th/faq.json';
import vi from './translates/vi/translation.json';
import faq_vi from './translates/vi/faq.json';
import ms from './translates/ms/translation.json';
import faq_ms from './translates/ms/faq.json';
import moment from "moment";
import 'moment/locale/ru'
import 'moment/locale/hi'
import 'moment/locale/es'
import 'moment/locale/fr'
import 'moment/locale/de'
import 'moment/locale/ja'
import 'moment/locale/it'
import 'moment/locale/ko'
import 'moment/locale/pt'
import 'moment/locale/id'
import 'moment/locale/vi'
import 'moment/locale/th'
import 'moment/locale/ms'
import 'moment/locale/en-gb'
import {EGender, ELanguage} from "./modules/rest";

export const availableLanguages = Object.values(ELanguage);

export const getLang = (ln?: ELanguage) => {
  const urlParams = new URLSearchParams(window.location.search);
  let queryLang = urlParams.get('lang')?.toLowerCase();
  //@ts-ignore
  if (!ln) ln = queryLang || localStorage.getItem('ln') || (window?.navigator?.userLanguage || window.navigator.language).substring(0, 2);
  const rusList = ['ru', 'uk', 'kk', 'be', 'az', 'lv', 'ee', 'tr', 'ka', 'hy', 'uz', 'mo', 'bg'];
  if (rusList.includes(ln || '')) ln = ELanguage.Russian;
  ln = availableLanguages.includes(ln as ELanguage) ? ln : ELanguage.English;
  moment.locale(ln);
  localStorage.setItem('ln', ln!);
  return ln;
}

const resources: Record<ELanguage, Resource> = {
  [ELanguage.English]: {
    translation: en,
    faq: faq_en,
  },
  [ELanguage.Russian]: {
    translation: ru,
    faq: faq_ru,
  },
  [ELanguage.Hindi]: {
    translation: hi,
    faq: faq_hi,
  },
  [ELanguage.Spanish]: {
    translation: es,
    faq: faq_es,
  },
  [ELanguage.French]: {
    translation: fr,
    faq: faq_fr,
  },
  [ELanguage.German]: {
    translation: de,
    faq: faq_de,
  },
  [ELanguage.Indonesian]: {
    translation: id,
    faq: faq_id,
  },
  [ELanguage.Italian]: {
    translation: it,
    faq: faq_it,
  },
  [ELanguage.Japanese]: {
    translation: ja,
    faq: faq_ja,
  },
  [ELanguage.Korean]: {
    translation: ko,
    faq: faq_ko,
  },
  [ELanguage.Portuguese]: {
    translation: pt,
    faq: faq_pt,
  },
  [ELanguage.Thai]: {
    translation: th,
    faq: faq_th,
  },
  [ELanguage.Vietnamese]: {
    translation: vi,
    faq: faq_vi,
  },
  [ELanguage.Malay]: {
    translation: ms,
    faq: faq_ms,
  },

};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lng: getLang(),

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;