import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  text?: string;
  className?: string;
  visible?: boolean
}

const EmptyList: FC<Props> = ({text, className, visible = true}) => {
  const {t} = useTranslation();

  if (!visible) return null;
  return (
    <div className={`empty-list ${className || ''}`}>
      <div className='empty-list-content'>
        {t(text || 'EMPTY_LIST')}
      </div>
    </div>
  );
}

export default EmptyList;