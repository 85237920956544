import React, { FC } from 'react';
import { Radio } from '../../../../../components/FormControls';
import { useTranslation } from 'react-i18next';
import { ICreditsPricingItem } from '../../../../../modules/rest';

interface Props {
  data: ICreditsPricingItem;
  active: boolean;
  onClick: () => void;
}

const CreditsPlanItem: FC<Props> = ({ data, active, onClick }) => {
  const { t } = useTranslation();
  return (
    <div className="col-6 col-sm-4">
      <div className={`premium-plan-item border-gradient-secondary${active ? ' active' : ''}`} onClick={onClick}>
        <Radio checked={active} onChange={() => {}} />
        {data.bonus ? (
          <div className="credit-plan-bonus border-gradient-secondary">
            <span className="text-tint text-lowercase">
              {data.bonus} {t('BONUS')}
            </span>
          </div>
        ) : null}
        <div className="premium-plan-item-bg" />
        <div className="premium-plan-item-content credit-plan">
          <div className="credit-plan-coins">💎 {data.credits}</div>
          <div className="credit-plan-price-container">
            <div className="credit-plan-price">{data.priceFormatted}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditsPlanItem;
