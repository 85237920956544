import React, {FC, useEffect, useRef, useState} from 'react';
import {Input} from "../../../../components/FormControls";
import Button from "../../../../components/Button";
import {ReactSVG} from "react-svg";
import send from "../../../../assets/icons/send.svg";
import sound_svg from "../../../../assets/icons/sound_active.svg";
import text_svg from "../../../../assets/icons/text_white.svg";
import Avatar from "../../../../components/Avatar";
import {API} from "../../../../modules/api";
import {toast} from "react-toastify";
import {observer} from "mobx-react";
import ChatStore from "../../../../store/ChatStore";
import {PhotoInterract} from "./Chat";
import Timer from "../../../../components/Timer";
import AppStore from "../../../../store/AppStore";
import useAuth from "../../../../hooks/useAuth";
import {useTranslation} from "react-i18next";



interface Props {
  showImageConfirm?: PhotoInterract|boolean;
  onChangeImageConfirm: (status: boolean) => void;
}

const ChatControl: FC<Props> = observer(({onChangeImageConfirm, showImageConfirm}) => {
  const {t} = useTranslation();

  const [onAuthClick] = useAuth(ChatStore.activeChat?.model?.mainPhotoImage?.id);
  const _timer: any = useRef(null);
  const _timerVisible: any = useRef(null);
  const isFirstRender = useRef(true);

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [messageTypeVisible, setMessageTypeVisible] = useState(false);
  const [message, setMessage] = useState('');
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.clientHeight;

      // Если высота окна меньше высоты документа, значит клавиатура открыта
      setIsKeyboardOpen(windowHeight < documentHeight);
    };
    window.addEventListener('resize', handleResize);

    // Инициализация состояния при монтировании компонента
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if(isKeyboardOpen) {
      handleFocus();
    }
  }, [isKeyboardOpen]);


  useEffect(() => {
    if (!ChatStore.activeChat?.id) return;
    if (isFirstRender.current) {
      setMessageTypeVisible(false);
      isFirstRender.current = false;
      return;
    }
    setMessageTypeVisible(true);
    _timerVisible.current = setTimeout(() => setMessageTypeVisible(false), 2500)
    return () => {
      clearTimeout(_timerVisible.current);
    }
  }, [ChatStore.activeChat?.isVoice]);

  useEffect(() => {
    isFirstRender.current = !Boolean(ChatStore.activeChat?.id);
    setMessage('');
  }, [ChatStore.activeChat?.id]);

  useEffect(() => {
    if (showImageConfirm) {
      setTimer(10)
      startTimer();
    } else {

    }
  }, [showImageConfirm]);

  const startTimer = () => {
    _timer.current = setTimeout(() => {
      setTimer(prevState => {
        if (prevState < 1) {
          onChangeImageConfirm(false);
          return 0;
        } else {
          startTimer();
        }
        return prevState - 1;
      })
    }, 1000);
  }

  const onConfirmImageClick = (status: string) => async () => {
    try {
      onChangeImageConfirm(false);
      await API.Interract.send((showImageConfirm as PhotoInterract)?.interract_id, {data: status});
    } catch (e) {
    }
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!AppStore.user?.id) return onAuthClick('login', undefined, {modelIdConversation: ChatStore.activeChat?.model?.id});
    if (!message) return;
    try {
      setMessage('');
      await API.Conversations.sendMessage(ChatStore.activeChat?.id!, {text: message});
    } catch (e: any) {
      toast.error(e);
    }
  }

  const handleFocus = () => {
    const form = document.querySelector('.chat-control')?.getBoundingClientRect();
    const top = (form?.top || 100) - 300;
    setTimeout(() => window.scrollTo({top , behavior: 'smooth'}), 150);
    window.scrollTo({top , behavior: 'smooth'});
  }

  if (!ChatStore.activeChat) return null;

  return (
    <>
      <div
        className={`chat-control${showImageConfirm ? ' confirm-visible' : ''}${messageTypeVisible ? ' message-type-visible' : ''}`}>
        {!showImageConfirm
          ?
          <div className="chat-input">
            <div className={`chat-input-message-type${messageTypeVisible ? ' visible' : ''}`}>
              <ReactSVG src={ChatStore.activeChat?.isVoice ? sound_svg : text_svg} className='react-icon  me-2'/>
              <span>{t(`CHAT_MESSAGE_TYPE_TEXT_${Number(ChatStore.activeChat?.isVoice || 0)}`)}</span>
            </div>
            <form onSubmit={onSubmit}>
              <div className='position-relative d-flex align-items-center'>
                <Input
                  onFocus={handleFocus}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className='lg'
                  placeholder={'TYPE_MESSAGE'}
                />
                <Button type='submit' contentClassName='p-0'>
                  {ChatStore.activeChat?.isVoice
                    ?
                    <span>💎 1</span>
                    :
                    <ReactSVG src={send} className='react-icon'/>
                  }
                </Button>
                <Avatar user size='xxs'/>
              </div>
            </form>
          </div>
          :
          <div className='chat-confirm-image'>
            <div className='px-2'>{(showImageConfirm as PhotoInterract)?.question}</div>
            <div className='chat-confirm-image-footer'>
              <Button
                className='w-50 me-1'
                onClick={onConfirmImageClick('yes')}
                size='sm'
                title='YES_WANT_IMAGE'
              />
              <div className='ms-1 position-relative w-50 d-flex align-items-center'>
                <Button
                  className='w-100'
                  btnType='secondary'
                  onClick={onConfirmImageClick('no')}
                  // onClick={() =>openModalNoMoney('chat')}
                  size='sm'
                  title="DONT_WANT_IMAGE"
                />
                <Timer
                  time={timer}
                  disabled={timer < 1}
                />
              </div>
            </div>
          </div>
        }
      </div>
    </>
  );
})

export default ChatControl;
