import React from 'react';
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter,
} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import {ErrorBoundary} from 'react-error-boundary';
import './i18n';
import close_svg from './assets/icons/close.svg';
import { register } from './serviceWorker';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import './assets/styles/bootstrap.min.css'


import './assets/styles/fonts.css'
import './assets/styles/constants.css'
import './assets/styles/main.scss'
import './assets/styles/media.scss'

import App from './App'
import ErrorFallback from "./pages/ErrorFallback";
import {ReactSVG} from "react-svg";

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <App/>
    </ErrorBoundary>
    <ToastContainer
      icon={false}
      closeButton={<ReactSVG src={close_svg} className='react-icon'/>}
      closeOnClick
    />
  </BrowserRouter>
)
register();