import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import StepFooter from "../StepFooter";
import {EBodyType, EBreastSize, EButtSize, EGender, EHairStyle, ICreateModelRequest} from "../../../../modules/rest";

import {observer} from "mobx-react";
import {getImageByModel} from "../../assets/modules/utils";
import AppStore from "../../../../store/AppStore";
import {useTranslation} from "react-i18next";

interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data: Partial<ICreateModelRequest>
}

const Step4: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const [bodyType, setBodyType] = useState(data.bodyType || (AppStore.gender === EGender.Male ? EBodyType.Slim : EBodyType.Petite));
  const [breastSize, setBreastSize] = useState(data.breastSize || (AppStore.gender === EGender.Female ? EBreastSize.Flat : null));
  const [buttSize, setButtSize] = useState(data.buttSize || (AppStore.gender === EGender.Female ? EButtSize.Small : null));

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {bodyType, breastSize, buttSize} : undefined);
  }

  return (
    <div className='create-ai-step'>
      <StepTitle title='CHOOSE_BODY_TYPE' onStepChange={handleStepChange} step={4}/>
      <div className='create-ai-list'>
        {Object.values(EBodyType).map(item => {
          const image = getImageByModel(AppStore.gender, 'bodyType', item,data.style);
          if(!image) return null;
          return (
            <ActiveElement radio small onClick={() => setBodyType(item as EBodyType)} key={item} active={bodyType === item}>
              <div className='model-card'>
                <div className="model-card-category"><span className='letter-uppercase'>{t(item.toUpperCase())}</span></div>
                <img src={getImageByModel(AppStore.gender, 'bodyType', item,data.style)} alt=""/>
              </div>
            </ActiveElement>
          )
        })}
      </div>
      {AppStore.gender === EGender.Female &&
      <>
        <h6 className="create-ai-title justify-content-center">{t('CHOOSE_BREAST_SIZE')}</h6>
        <div className='create-ai-list'>
          {Object.values(EBreastSize).map(item => {
            // if (!image) return null;
            return (
              <ActiveElement radio small onClick={() => setBreastSize(item as EBreastSize)} key={item} active={breastSize === item}>
                <div className='model-card'>
                  <div className="model-card-category"><span className='letter-uppercase'>{t(item.toUpperCase())}</span></div>
                  <img src={getImageByModel(AppStore.gender, 'breastSize', item,data.style)} alt=""/>
                </div>
              </ActiveElement>
            )
          })}
        </div>
        <h6 className="create-ai-title justify-content-center">{t('CHOOSE_BUTT_SIZE')}</h6>
        <div className='create-ai-list'>
          {Object.values(EButtSize).map(item => {
            // if (!image) return null;
            return (
              <ActiveElement radio small onClick={() => setButtSize(item as EButtSize)} key={item} active={buttSize === item}>
                <div className='model-card'>
                  <div className="model-card-category"><span className='letter-uppercase'>{t(item.toUpperCase())}</span></div>
                  <img src={getImageByModel(AppStore.gender, 'buttSize', item,data.style)} alt=""/>
                </div>
              </ActiveElement>
            )
          })}
        </div>
      </>
      }
      <StepFooter onBack={() => handleStepChange(-1)} onNext={() => handleStepChange(1)}/>
    </div>
  );
})

export default Step4;