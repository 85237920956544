import React, {FC, InputHTMLAttributes, useRef} from 'react';
import {ReactSVG} from 'react-svg';
import {useTranslation} from "react-i18next";

interface Props extends InputHTMLAttributes<any> {
  label?: string;
  className?: string;
  errorText?: string;
  error?: boolean;
  clearable?: boolean;
  icon?: any;
}

const Input: FC<Props> = ({
                            label,
                            className,
                            icon,
                            error,
                            errorText,
                            clearable,
                            ...props
                          }) => {
  const {t} = useTranslation();

  return (
    <div className={`form-group${error ? ' error' : ''}${clearable ? ' clearable' : ''} ${className || ''}`}>
      {label ?
        <label className={`muted`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      {errorText && error ? <div className='form-group-error'>{errorText}</div> : null}
      <div className='form-control-wrap'>
        <input
          className={`form-control${icon ? ' form-control-icon' : ''}`}
          {...props} name={String(props?.value)}
          autoComplete='new-input'
          placeholder={t(props.placeholder || '') || ''}
        />
        <div className="form-control-bg"/>
        {icon ? <ReactSVG src={icon} className='react-icon input-icon'/> : null}

        {/*{(clearable && props.value) &&*/}
        {/*  //@ts-ignore*/}
        {/*  <CloseBtn className='input-clear' onClick={(e) => props.onChange({...e, target: {...e.target, value: ''}})}/>*/}
        {/*}*/}

        {props.placeholder && (props.type === 'date' || props.type === 'time') && !props.value
          ?
          <div className="form-control-placeholder">{t(props.placeholder)}</div>
          :
          null
        }
      </div>
    </div>
  );
};

export default Input;