import React, {ChangeEvent, FC, useCallback, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
import {EFieldGroup, IAsset, TIdentifier} from "../../modules/rest";
import {API} from "../../modules/api";
import {thumb, uploadFile} from "../../modules/utils";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";
import trash_svg from "../../assets/icons/trash.svg";
import Loadable from "../Loadable";

const cache: Record<TIdentifier, IAsset> = {};

const getAsset = (id: TIdentifier): Promise<IAsset> => {
  if (cache[id]) return Promise.resolve(cache[id]);
  return API.Assets.getAsset(id, [EFieldGroup.AssetFull]).then((asset) => {
    cache[id] = asset;
    return asset;
  });
};

interface Props {
  value?: IAsset|null;
  deletable?: boolean;
  accept?: string;
  id?: string;
  text?: string;
  className?: string;
  preview?: boolean;
  chunkSize?: number;
  children: any;
  size?: 'small'|'medium';
  variant?: 'text'|'outlined'|'contained';

  onChange(file: IAsset|null): void;
}

const InputFile: FC<Props> = ({
                                value,
                                onChange,
                                accept = '*/*',
                                text = 'Select',
                                preview,
                                chunkSize,
                                size = 'small',
                                variant = 'text',
                                id,
                                className,
                                deletable = true,
                                children,
                              }) => {
  const {t} = useTranslation();

  const [asset, setAsset] = useState<IAsset|null>();
  const [progress, setProgress] = useState<number>(0);
  const [uploading, setUploading] = useState<boolean>(false);
  const [filename, setFilename] = useState('');
  const [abortController, setAbortController] = useState<AbortController|null>(null);

  const change = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const file: File = e.target.files![0];
      setFilename(file?.name)
      if (asset) setAsset(null);
      setUploading(true);
      const controller = new AbortController();
      setAbortController(controller);
      uploadFile(
        file,
        (status) => {
          setProgress(status.progress);
        },
        chunkSize,
        controller.signal // Передаем сигнал для отмены загрузки
      )
        .then((res) => {
          cache[res.id] = res;
          setAsset(res);
          onChange(res);
          setProgress(0);
        })
        .catch((error) => {
          if (error.message === 'AbortError') {
          } else {
            toast.error(error);
          }
        })
        .finally(() => {
          setProgress(0);
          setUploading(false);
          setAbortController(null);
        });
    },
    [onChange, asset, chunkSize]
  );

  useEffect(() => {
    if (typeof value === 'string' && value) {
      getAsset(value).then(setAsset);
    } else {
      setAsset(value as IAsset|null);
    }
  }, [value, setAsset]);

  const cancelUpload = (e: any) => {
    e.stopPropagation();
    if (abortController) {
      abortController.abort();
    }
  };

  return (
    <Loadable loading={uploading} className={`input-file-wrap ${className || ''}`}>
      <label className={`input-file${uploading ? ' uploading' : ''}`}>
        {children}
        {!uploading && <input type="file" accept={accept} onChange={change} hidden id={id || ''}/>}
      </label>
    </Loadable>
  );
};

export default InputFile;