import React, {FC} from 'react';
import ActiveElement from "../../../components/ActiveElement";
import {EFilter, ESubscriptionStatus} from "../../../modules/rest";
import {useNavigate} from "react-router-dom";
import AppStore from "../../../store/AppStore";
import {openModalNoMoney} from "../../../modals";
import {observer} from "mobx-react";
import vhs from '../assets/images/vhs.jpg';
import default_img from '../assets/images/default.jpg';
import cyberpunk from '../assets/images/cyberpunk.jpg';
import palaroid from '../assets/images/palaroid.jpg';
import studio from '../assets/images/studio.jpg';
import vintage from '../assets/images/vintage.jpg';
import {ReactSVG} from "react-svg";
import diamond from "../../../assets/icons/diamond.svg";
import HorizontalScroll from "../../../components/HorizontalScroll";
import {useTranslation} from "react-i18next";
import {isSub} from "../../../modules/utils";

export const generateImageFilters: any = {
  Default: default_img,
  Cyberpunk: cyberpunk,
  VHS: vhs,
  Studio: studio,
  Polaroid: palaroid,
  Vintage: vintage,
}

interface Props {
  filter: EFilter;
  onChange: (filter: EFilter) => void
}

const GenerateImageFilters: FC<Props> = observer(({filter, onChange}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = (item: EFilter) => () => {
    if (!isSub(AppStore.user?.subscription?.status) && item !== EFilter.Default) {
      openModalNoMoney('create-ai').then(path => {
        if (path) navigate(path);
      })
    } else {
      onChange(item)
    }
  }
  return (
    <>
      <div className='mt-3 mt-lg-4 text-dark-14 mb-3'>{t('IMAGE_FILTER')}</div>
      <HorizontalScroll className='generate-image-filter-list' secondary>
        {Object.keys(EFilter).map(item => (
          <ActiveElement radio small onClick={handleClick(item as EFilter)} key={item} active={item === filter}>
            <>
              {item !== EFilter.Default && <ReactSVG src={diamond} className='react-icon generate-image-filter-badge'/>}
              <div className='model-card'>
                <div className="model-card-category"><span className='letter-uppercase'>{t(item.toUpperCase())}</span></div>
                <img src={generateImageFilters[item]} alt="filter"/>
              </div>
            </>
          </ActiveElement>
        ))}
      </HorizontalScroll>
    </>
  );
})

export default GenerateImageFilters;