import asyncModal from 'react-async-modal';
import Button from '../../components/Button';
import React, {FC, useState} from "react";
import {EGender} from "../../modules/rest";
import ActiveElement from "../../components/ActiveElement";
import {GenderIcon} from "../../components/GenderSelect";
import {toast} from "react-toastify";
import AppStore from "../../store/AppStore";
import {Input} from "../../components/FormControls";
import {openWarning18Modal} from "./Warning18Modal";
import {useTranslation} from "react-i18next";

interface ConfirmModalProps {
  resolve(): void;
}

const SetUpModal: FC<ConfirmModalProps> = ({resolve}) => {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(AppStore.user?.name || '');
  const [gender, setGender] = useState(EGender.Male);

  const submit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    try {
      setLoading(true);
      await AppStore.updateUser({gender, name});
      resolve()
      if (!localStorage.getItem('confirm18+')) openWarning18Modal()
    } catch (e: any) {
      toast.error(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className='modal-container modal-gender-settings'>
      <h4 className='modal-title justify-content-center'>{t('SET_UP_MODAL_TITLE')}</h4>
      <div className='text-center text-dark-14 mb-4'>{t('SET_UP_MODAL_TEXT')}</div>
      <form onSubmit={submit}>
        <Input
          className='mb-3'
          required
          placeholder='YOUR_NICKNAME'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <div className='text-dark-14 mb-2'>{t('GENDER')}</div>
        <div className='d-flex' style={{margin: '0 -8px'}}>
          {Object.values(EGender).map(item => (
            <ActiveElement
              key={item}
              className='w-50 px-2'
              bordered
              radio
              small
              active={gender === item}
              onClick={() => setGender(item)}
            >
              <div className='modal-gender-item'>
                <GenderIcon gender={item} active={gender === item} size='xl'/>
                <div className='text-bold letter-uppercase mt-4'>{t(item.toUpperCase())}</div>
              </div>
            </ActiveElement>
          ))}
        </div>
        <div className="modal-footer sm">
          <Button
            title={'CONTINUE'}
            type='submit'
          />
        </div>
      </form>
    </div>
  );
};

const openSetUpModal = () => {
  return asyncModal(SetUpModal, {}, {showCloseIcon: false, center: true, closeOnOverlayClick: false});
};

export {openSetUpModal};
