import React, {FC} from 'react';
import ReactPaginate from 'react-paginate';
import './pagination.scss';
import {IPagedData} from "../../modules/rest";
import {ReactSVG} from "react-svg";
import chevron_right from '../../assets/icons/chevron_right.svg';
import chevron_left from '../../assets/icons/chevron_left.svg';
import {Select} from "../FormControls";
import {useTranslation} from "react-i18next";

interface Props {
  limits?: number[];
  pager?: IPagedData<any>;
  textType?: string;
  className?: string;

  onPageChange(page: number): void;

  onLimitChange?(limit: number): void;
}

const Pagination: FC<Props> = ({
                                 pager,
                                 textType,
                                 limits = [15, 50, 100, 500, 1000],
                                 onPageChange,
                                 className,
                                 onLimitChange,
                               }) => {
  const {t} = useTranslation();

  const handlePageClick = (event: any) => {
    onPageChange(event.selected + 1)
  };

  if (!pager || pager.pages! <= 1) return null;

  return (
    <div className={`pagination-wrap`}>
      <div className={`pagination ${className || ''}`}>
        <ReactPaginate
          breakLabel="..."
          nextLabel={<ReactSVG src={chevron_right} className='react-icon'/>}
          onPageChange={handlePageClick}
          pageRangeDisplayed={4}
          pageCount={pager.pages || 0}
          forcePage={(pager?.page - 1) || 0}
          previousLabel={<ReactSVG src={chevron_left} className='react-icon'/>}
          renderOnZeroPageCount={null}
        />

          {!!onLimitChange && (
            <div className='pagination-right text-muted-14'>
              <div>{t(textType || 'records').toLowerCase()}</div>
              <Select
                className='ms-2 sm'
                value={pager.limit}
                style={{maxWidth: 80}}
                onChange={(e) => onLimitChange(Number(e.target.value))}
              >
                {!limits.includes(pager.limit) && <option value={pager.limit}>{pager.limit}</option>}
                {limits.map((limit) => (
                  <option key={limit} value={limit}>
                    {limit}
                  </option>
                ))}
              </Select>
              {pager.count !== null && <span className='ps-2 text-nowrap'>{t('of')} {pager.count}</span>}
            </div>
          )}
      </div>
    </div>
  );
}

export default Pagination;