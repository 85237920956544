import React, {FC} from 'react';
import LegalInformationPageWrapper from "../components/LegalInformationPageWrapper";
import {
        APP_COMPANY,
        APP_COMPANY_ADDRESS,
        APP_COMPANY_EMAIL_SUPPORT,
        APP_COMPANY_NAME
} from "../../../modules/constants";

interface Props {
}

const CookiesPolicy: FC<Props> = () => {
  return (
    <LegalInformationPageWrapper title='COOKIE_POLICY'>

          <p>This Cookies Policy is designed to help visitors and users understand how and why we use cookies on our website https://{APP_COMPANY_NAME} (the “APP “). “We” and “Us” in this document refers to {APP_COMPANY}, Address: {APP_COMPANY_ADDRESS}</p>
          <p>By using our APP, you agree to the use of cookies as described in this policy.</p>
          <h4>1. What are Cookies</h4>
          <p>Cookies are small text files that are stored on your computer or mobile device when you visit a website. They help websites recognize your device and remember information about your visit, preferences, and actions.</p>
          <h4>2. Types of Cookies We Use</h4>
          <p>Essential Cookies</p>
          <p>These cookies are necessary for the proper functioning of our website. They enable you to navigate our site and use its features, such as accessing secure areas. Without these cookies, certain services you have requested cannot be provided.</p>
          <p>Analytical/Performance Cookies</p>
          <p>We use these cookies to analyze how visitors use our website and to monitor its performance. For example, we may use Google Analytics to collect information about your online activity and interactions with our site.</p>
          <p>Functionality Cookies</p>
          <p>These cookies allow us to remember choices you make on our website (such as your username, language, or region) and provide enhanced, more personalized features.</p>
          <p>Targeting Cookies</p>
          <p>We may use these cookies to deliver content that is more relevant to your interests. They may be used by third-party companies to build a profile of your interests and show you relevant advertisements on other sites.</p>
          <h4>3. Your Consent</h4>
          <p>By using our APP, you consent to the placement of cookies on your device. You can manage your cookie preferences through your browser settings. However, please note that disabling certain cookies may affect the functionality of our APP.</p>
          <h4>4. How to Manage Cookies</h4>
          <p>Most web browsers allow you to control cookies through their settings. To find out more about cookies, including how to see what cookies have been set, visit www.allaboutcookies.org.</p>
          <h4>5. Changes to This Policy</h4>
          <p>We may update our Cookies Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be effective immediately upon posting the updated policy on our website.</p>
          <h4>6. Contact Information</h4>
          <p>If you have any questions or require further clarification regarding our Cookies Policy, please contact us at: {APP_COMPANY_EMAIL_SUPPORT} or directly report in the APP in the “Contact” section.</p>
    </LegalInformationPageWrapper>
  );
}

export default CookiesPolicy;