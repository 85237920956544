import {initializeApp} from "firebase/app";
import {getMessaging, getToken, isSupported, onMessage,} from "firebase/messaging";
import {onBackgroundMessage,} from "firebase/messaging/sw";
import {getAnalytics,} from "firebase/analytics";
import AppStore from "../store/AppStore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyBUGrrFQfJqUboGYa6H3inmSUXIwy9qPrw",
  authDomain: "cherryx.firebaseapp.com",
  projectId: "cherryx",
  storageBucket: "cherryx.appspot.com",
  messagingSenderId: "709075734698",
  appId: "1:709075734698:web:b5eedb013255f46f3300d3",
  measurementId: "G-WHL7Z7LWCW"
};

let messaging: any;

// Initialize Firebase
export const initFirebase = async () => {
  const app = initializeApp(firebaseConfig);
  try {
    const isSup = await isSupported();
    if (isSup) {
      messaging = getMessaging(app);
      onMessage(messaging, (payload: any) => {
        console.log('Message received. ', payload);
        // const notificationTitle = payload.notification.title;
        // const notificationOptions = {
        //   body: payload.notification.body,
        //   icon: payload.notification.image,
        //   image: payload.notification.image,
        // };
        // //@ts-ignore
        // self.registration.showNotification(notificationTitle, notificationOptions);
      });

    }
  } catch (e) {
  }
}



export async function requestPermissionMessaging() {
  //requesting permission using Notification API
  if (!messaging) return;
  const permission = await Notification.requestPermission();
  localStorage.setItem('firebaseMessagingStatus', permission);
  if (permission === "granted") {
    const token = await getToken(messaging);
    AppStore.updateUser({fcmToken: token});
    localStorage.setItem('fcm', token);
    //We can send token to server
    console.log("Token generated : ", token);
  } else if (permission === "denied") {
    //notifications are blocked
  }
}



