import   { useEffect} from 'react';
import {useLocation} from "react-router-dom";


const useToTop = () => {
  const location = useLocation();
  useEffect(() => {
    //@ts-ignore
    window.scrollTo({top: 0, behavior: 'instant'});
  }, [location.pathname]);
}

export default useToTop;