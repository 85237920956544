import  React, {FC} from 'react';
import Button from "../../../../components/Button";
import { EHairColor} from "../../../../modules/rest";
import {getModalHairColor} from "../../assets/modules/utils";
import {useTranslation} from "react-i18next";


interface Props {
  value?: EHairColor;
  onChange: (color: EHairColor) => void;
}

const StepHairColor: FC<Props> = ({value, onChange}) => {
  const {t} = useTranslation();

  return (
    <div className="card-dark create-ai-inner-card">
      <h6 className='text-center'>{t('CHOOSE_HAIR_COLOR')}</h6>
      <div className='create-ai-eye-color'>
        {Object.values(EHairColor).map((color) => (
          <Button
            key={color}
            className={`mx-1 mb-2 ${value === color ? 'active' : ''}`}
            size='md'
            btnType='third'
            onClick={() => onChange(color as EHairColor)}
          >
            <div className={'btn-image'} style={{background: getModalHairColor(color)}}></div>
            <span>{t(color.toUpperCase())}</span>
          </Button>
        ))}
      </div>
    </div>
  );
}

export default StepHairColor;