import React, {FC, useState} from 'react';
import StepTitle from "../StepTitle";
import ActiveElement from "../../../../components/ActiveElement";
import {EModelStyle, ICreateModelRequest } from "../../../../modules/rest";
import StepFooter from "../StepFooter";
import {observer} from "mobx-react";
import {getImageByModel, preloadSrcEyeColor} from "../../assets/modules/utils";
import AppStore from "../../../../store/AppStore";
import PreloadImages from "../../../../components/PreloadImages";
import {useTranslation} from "react-i18next";


interface Props {
  onStepChange: (dir: 1|-1, data?: Partial<ICreateModelRequest>) => void;
  data?: EModelStyle;
}

const Step1: FC<Props> = observer(({onStepChange, data}) => {
  const {t} = useTranslation();

  const [style, setStyle] = useState<EModelStyle>(data || EModelStyle.Real);

  const handleStepChange = (dir: 1|-1) => {
    onStepChange(dir, dir === 1 ? {style} : undefined);
  }

  return (
    <div className='create-ai-step'>
      <PreloadImages images={preloadSrcEyeColor} />
      <StepTitle title='CHOOSE_STYLE' prevDisabled onStepChange={handleStepChange} nextDisabled={!style} step={1}/>
      <div className='create-ai-style'>
        {Object.values(EModelStyle).map(key => (
          <ActiveElement radio onClick={() => setStyle(key)} key={key} active={style === key}>
            <div className='model-card'>
              <div className="model-card-category"><span className='letter-uppercase'>{t(key.toUpperCase())}</span></div>
              {/*<SuspenseImg src={getImageByModel(AppStore.gender, 'style', key)}  />*/}
              <img src={getImageByModel(AppStore.gender, 'style', key)} alt=""/>
            </div>
          </ActiveElement>
        ))}
      </div>
      <StepFooter onNext={() => handleStepChange(1)} disabled={!style}/>

    </div>
  );
})

export default Step1;