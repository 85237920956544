import  React, {FC} from 'react';
import {EGender} from "../../modules/rest";
import GenderIcon from "./GenderIcon";
import {useTranslation} from "react-i18next";


interface Props {
  onChange: (gender: EGender) => void;
  gender: EGender;
  className?: string;
}

const GenderSelect: FC<Props> = ({gender, onChange, className}) => {
  const {t} = useTranslation();
  return (
    <div className={`gender-select ${className || ''}`}>
      {Object.values(EGender).reverse().map(item => (
        <div
          key={item}
          className={`gender-select-tab${gender === item ? ' active' : ''}`}
          onClick={() => onChange(item as EGender)}
        >
          <GenderIcon gender={item as EGender} active={gender === item}  />
          <span className='ms-2 letter-uppercase'>{t(item.toUpperCase())}</span>
        </div>
      ))}
    </div>
  );
}

export default GenderSelect;