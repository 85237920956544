import asyncModal from 'react-async-modal';
import Button from '../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC} from "react";
import close_svg from "../assets/icons/close.svg";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import magic_wand from "../assets/icons/magic_wand.svg";
import generate from "../assets/icons/generate.svg";


interface Props {
  resolve(res?: {path: string, needUser: boolean}): void;
}

const FooterCreateModal: FC<Props> = observer(({resolve}) => {
  const {t} = useTranslation();

  const handleClick = (path: string, needUser: boolean) => () => {
    resolve({path, needUser})
  }


  const handleClose = () => {
    resolve();
  }

  return (
    <div className='modal-container modal-gender-settings'>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='modal-title'>{t('CREATE')}</h4>
        <Button
          iconAbsolute
          onClick={handleClick('/create-ai', false)}
          className='w-100'
          title={'CREATE_NEW_CHARACTER'}
          icon={magic_wand}
        />
        <Button
          btnType='secondary'
          iconAbsolute
          onClick={handleClick('/generate-image', true)}
          className='w-100 mt-3'
          title={'GENERATE_IMAGE'}
          icon={generate}
        />
    </div>
  );
});

const openFooterCreateModal = (): Promise<{path: string, needUser: boolean}> => {
  return asyncModal(FooterCreateModal, {}, {
    showCloseIcon: false,
    center: true,
    classNames: {modalContainer: 'bottom-modal'},
  });
};

export {openFooterCreateModal};
