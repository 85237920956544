import React, {FC} from 'react';
import gdpr from '../../../assets/icons/gdpr.svg';
import cloudflare from '../../../assets/icons/cloudflare.svg';
import mastercard from '../../../assets/icons/mastercard.svg';
import pci from '../../../assets/icons/pci.svg';
import visa from '../../../assets/icons/visa.svg';
import PaymentPlatformIconsItem from "./PaymentPlatformIconsItem";

const dataIcons = [
  // {icon: visa, title: "We're a part of Visa Third Party Agent (TPA) program."},
  // {icon: mastercard, title: "We're a part of Mastercard Registration Program (MRP)."},
  {icon: gdpr, title: "When processing our clients' data, we strictly adhere to the data protection principles of the General Data Protection Regulation (GDPR). We believe that the protection of our clients' and their end-users' data is fundamental to our mission — helping build a better internet."},
  {icon: cloudflare, title: 'Cloudflare helps us mitigate DDoS attacks of all forms and sizes and enhances the security of our platform.'},
  // {icon: pci, title: 'Having successfully passed the independent audit and assessment, we received the certificate of PCI DSS version 3.2.1 compliance.'},

]


interface Props {
}

const PaymentPlatformIcons: FC<Props> = () => {
  return (
      <div className='payment-platform-icons'>
        {dataIcons.map((item , i) => (
          <PaymentPlatformIconsItem {...item} key={i} />
        ))}
      </div>
  );
}

export default PaymentPlatformIcons;