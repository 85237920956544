import React, {CSSProperties, FC} from 'react';

interface Props {
  loading?: boolean;
  absolute?: boolean;
  className?: string;
  style?: CSSProperties
}

const Spinner: FC<Props> = ({loading, absolute, style, className}) => {
  if (!loading) return null;
  return (
    <div className={`spinner${absolute ? ' position-absolute' : ''} ${className || ''}`} style={style}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Spinner;