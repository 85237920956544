import  React, {FC} from 'react';
import Button from "../../../../components/Button";
import {EEyesColor} from "../../../../modules/rest";
import {getImageByModel} from "../../assets/modules/utils";
import {observer} from "mobx-react";
import AppStore from "../../../../store/AppStore";
import {useTranslation} from "react-i18next";


interface Props {
  value?: EEyesColor;
  onChange: (color: EEyesColor) => void;
}

const Step2EyeColor: FC<Props> = observer(({value, onChange}) => {
  const {t} = useTranslation();

  return (
    <div className="card-dark create-ai-inner-card">
      <h6 className='text-center'>{t('CHOOSE_EYE_COLOR')}</h6>
      <div className='create-ai-eye-color'>
        {Object.values(EEyesColor).map((color) => (
          <Button
            key={color}
            className={`mx-1 mb-2 ${value === color ? 'active' : ''}`}
            size='md'
            btnType='third'
            onClick={() => onChange(color)}
          >
            <img src={getImageByModel(AppStore.gender, 'eyesColor', color)} alt="eye" className='btn-image'/>
            <span className='letter-uppercase'>{t(color.toUpperCase())}</span>
          </Button>
        ))}
      </div>
    </div>
  );
})

export default Step2EyeColor;