import React, {FC} from 'react';
import {ReactSVG} from "react-svg";
import done from "../../../../../assets/icons/done.svg";
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  data: string[];
}

const PremiumBenefits: FC<Props> = ({title, data}) => {
  const {t} = useTranslation();
  return (
    <div className="premium-benefits">
      <div className='text-bold'>{t(title)}</div>
      <div className='row mt-0 mt-md-2 g-2 g-md-3'>
        {data.map((item, i) => (
          <div className="col-12 col-md-6 d-flex text-dark-14 align-items-center" key={i}>
            <ReactSVG className='react-icon me-2' src={done}/>
            <div>{t(item)}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PremiumBenefits;