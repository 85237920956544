import React, {FC, useEffect} from 'react';
import {observer} from "mobx-react";
import { useNavigate, useParams} from "react-router-dom";
import ModelProfile from "./components/ModelProfile";
import arrow_left from "../../assets/icons/arrow_left.svg";
import Button from "../../components/Button";
import {ChatSettings} from "../ChatPage";
import ChatStore from "../../store/ChatStore";
import AppStore from "../../store/AppStore";

interface Props {
}

const ModelProfilePage: FC<Props> = observer(() => {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    if (params?.chatId && AppStore.user?.id) {
      fetch();
    }
  }, [AppStore.user?.id]);

  const fetch = async () => {
    try {
      const res = await ChatStore.getChat(params?.chatId!);
      if (!res) navigate('/', {replace: true});
    } catch (e) {
      navigate('/', {replace: true});
    }
  }

  return (
    <div className='model-profile-page'>
      <div className="model-profile-head">
        <Button
          className='header-burger'
          size='sm'
          contentClassName='p-0'
          icon={arrow_left}
          btnType='secondary'
          onClick={() => navigate(params?.chatId ? `/chat/${params.chatId}` : '/')}
        />
        <ChatSettings fromModelProfile  model={ChatStore.activeChat?.model}/>
      </div>
      <ModelProfile model={ChatStore.activeChat?.model}/>
    </div>
  );
})

export default ModelProfilePage;