import React, {FC} from 'react';
import {IConversation} from "../../../../modules/rest";
import Avatar from "../../../../components/Avatar";
import {thumb, thumbAvatar} from "../../../../modules/utils";
import sound_svg from "../../../../assets/icons/sound.svg";
import {useTranslation} from "react-i18next";
import {ReactSVG} from "react-svg";

interface Props {
  data: IConversation;
  active?: boolean;
  onClick: () => void;
}

const ChatsListItem: FC<Props> = ({data, active, onClick}) => {
  const {t} = useTranslation();

  return (
    <div className={`chats-list-item${active ? ' active' : ''}`} onClick={onClick}
         id={active ? 'chats-list-item-active' : ''}>
      <Avatar image={thumbAvatar(data.model?.mainPhotoImage?.id, 128)}/>
      <div className='ms-2 flex-1'>
        <div className='d-grid overflow-hidden'>
          <div className='text-bold text-truncate'>{data.model?.name}</div>
        </div>
        {data.lastMessage ?
          <div className='chats-list-item-message text-14'>
            {data.lastMessage.voice
              ?
              <>
                <ReactSVG src={sound_svg} className='react-icon' />
                <div className='ms-1 d-grid'>
                  <span className='text-truncate'>{t('VOICE_MESSAGE')}</span>
                </div>
              </>
              :
              data.lastMessage?.photo
                ?
                <>
                  <img src={thumb(data.lastMessage.photo?.id, 128)} alt="photo"/>
                  <span className='ms-1'>{t('PHOTO')}</span>
                </>
                :
                <p className='d-grid'>
                  <span className='text-truncate'>{data.lastMessage?.text}</span>
                </p>
            }
          </div>
          : null
        }
      </div>
    </div>
  );
}

export default ChatsListItem;
