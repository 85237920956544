import React, {FC, useLayoutEffect, useState} from 'react';
import {Input, Textarea} from "../../../components/FormControls";
import Button from "../../../components/Button";
import refresh from '../../../assets/icons/refresh.svg';
import {generatePromptTemplates, TTemplatePrompt} from "../modules/utils";
import {EGender} from "../../../modules/rest";
import HorizontalScroll from "../../../components/HorizontalScroll";
import {useTranslation} from "react-i18next";

interface Props {
  error: boolean;
  prompt: string;
  onChange: (prompt: string) => void;
  gender?: EGender;
}

const GeneratePromptInput: FC<Props> = ({prompt, onChange, error}) => {
  const {t} = useTranslation();

  const [templates, setTemplates] = useState<TTemplatePrompt[]>();
  const [activeTemplateId, setActiveTemplateId] = useState<string>();

  useLayoutEffect(() => {
    setTemplates(generatePromptTemplates())
  }, []);

  const handleChangeTemplates = () => {
    setTemplates(generatePromptTemplates())
  }

  const handleChange = (e: any) => {
    const value = e.target.value;
    if (!value) setActiveTemplateId(undefined);
    onChange(value);
  }

  return (
    <>
      <div className='mt-3 mt-lg-4 text-dark-14'>{t('ENTER_PROMPT')}*</div>
      <div className={`generate-prompt-card${error ? ' error' : ''}`}>
        <Textarea
          value={prompt}
          onChange={handleChange}
        />
        <div className='d-flex p-2 pb-0 align-items-end'>
          <HorizontalScroll secondary trigger={templates}>
            {templates?.map((template, i) => (
              <div key={i}>
                <Button
                  onClick={() => {
                    setActiveTemplateId(template.button);
                    onChange(template.prompt)
                  }}
                  className={`${template.button === activeTemplateId ? 'active' : ''}`}
                  btnType='third'
                  size='xs'
                  title={template.button}
                />
              </div>
            ))}
          </HorizontalScroll>
          <Button
            icon={refresh}
            onClick={handleChangeTemplates}
            btnType='secondary'
            size='xs'
            className='mb-2'
            circle
          />
        </div>
      </div>
      {error && <div className='text-12 mt-1 text-error'>{t('REQUIRED_FIELD')}</div>}
    </>
  );
}

export default GeneratePromptInput;