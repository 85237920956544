import asyncModal from 'react-async-modal';
import Button from '../components/Button';
import {ReactSVG} from "react-svg";
import React, {FC} from "react";
import close_svg from "../assets/icons/close.svg";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import magic_wand from "../assets/icons/magic_wand.svg";
import generate from "../assets/icons/generate.svg";
import ChatLoyaltySystem from "../pages/ChatPage/components/Chat/ChatLoyaltySystem";
import {IConversation} from "../modules/rest";


interface Props {
  resolve(): void;
  conversation?: IConversation
  handleWarmLevel: () => Promise<void>;
}

const LoyaltySystemModal: FC<Props> = observer(({conversation, resolve, handleWarmLevel}) => {
  const {t} = useTranslation();

  const handleClose = () => {
    resolve();
  }

  const handleClick = async () => {
    try {
      await handleWarmLevel()
    } catch (e) {
      handleClose()
    }
  }

  return (
    <div className={`modal-container modal-loyalty-system`}>
      <ReactSVG src={close_svg} className='modal-close' onClick={handleClose}/>
      <h4 className='modal-title'>{t('LOVE_MATTER')}</h4>
      <ChatLoyaltySystem onClick={handleClick} alwaysVisible conversation={conversation} />
    </div>
  );
});

const openLoyaltySystemModal = (handleWarmLevel: () => void, conversation?: IConversation): Promise<void> => {
  return asyncModal(LoyaltySystemModal, {handleWarmLevel, conversation}, {
    showCloseIcon: false,
    center: true,
    classNames: {modalContainer: 'bottom-modal'},
  });
};

export {openLoyaltySystemModal};
