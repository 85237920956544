import React, {FC, useEffect, useState} from 'react';
import Button from "../../../components/Button";
import ModelCard from "../../../components/ModelCard";
import ActiveElement from "../../../components/ActiveElement";
import {EModelStyle, IModel} from "../../../modules/rest";
import {observer} from "mobx-react";
import HomeStore from "../../../store/HomeStore";
import {useTranslation} from "react-i18next";
import HorizontalScroll from "../../../components/HorizontalScroll";
import {useSearchParams} from "react-router-dom";
import EmptyList from "../../../components/EmptyList";
import Loadable from "../../../components/Loadable";
import Tags from "../../../containers/Tags";

interface Props {
  onAnswer: (model: IModel) => void;
  className?: string;
  containerClassName?: string;
  title?: string;
  titleClassName?: string;
}

const HomeList: FC<Props> = observer(({onAnswer, className, title, titleClassName, containerClassName}) => {
  const {t} = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();

  const handleChangeStyle = (style?: EModelStyle) => () => {
    HomeStore.onStyleChange(style);
    if (searchParams.has('style')) {
      searchParams.delete('style');
      setSearchParams(searchParams);
    }
  }


  return (
    <section className={`home-models ${className || ''}`}>
      <div className={containerClassName}>
        <h2 className={`text-center ${titleClassName || ''}`}>
          <span className='text-tint me-2 me-md-3'>{t(title || 'EXPLORE')}</span>{t('AI_CHARACTERS')}
        </h2>
        <HorizontalScroll className='model-style'>
          <Button
            className={!HomeStore.style ? 'active' : ''}
            btnType='third'
            title='ALL_MODELS'
            onClick={handleChangeStyle()}
          />
          {Object.values(EModelStyle).map(item => (
            <Button
              key={item}
              className={item === HomeStore.style ? 'active' : ''}
              btnType='third'
              title={item.toUpperCase()}
              onClick={handleChangeStyle(item)}
            />
          ))}
        </HorizontalScroll>
        <Tags className='home-tags'/>
        <Loadable loading={HomeStore.loading} className="home-models-list">
          {HomeStore.models?.length
            ?
            HomeStore.models.map((item) => (
            <ActiveElement key={item.id}>
              <ModelCard
                animate
                model={item}
                category={item.style}
                onClick={() => onAnswer(item)}
                chatBtn
                imageSize={900}
              />
            </ActiveElement>
          ))
          :
          <EmptyList className='mt-5' visible={HomeStore.ready} />
          }
        </Loadable>
      </div>
    </section>
  );
})

export default HomeList;