import ModelProfilePage from './ModelProfilePage';
import ModelProfile from './components/ModelProfile';

import './style/model-profile.scss'
import './style/media_model-profile.scss'

export {
  ModelProfile,
}

export default ModelProfilePage;