import React, {FC, ImgHTMLAttributes} from 'react';

interface Props extends ImgHTMLAttributes<any> {
  preloadSrc?: string;

}

const ImageComponent: FC<Props> = ({src, preloadSrc, className, ...props}) => {
  return <>
    {preloadSrc ? <img src={preloadSrc} alt='img' {...props} className={`${className || ''} preload-image`}/> : null}
    <img src={src} alt='img' {...props} className={className || ''}/>
  </>
};

export default ImageComponent;