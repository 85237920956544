import React, {FC} from 'react';
import magic_wand from '../../../assets/icons/magic_wand_active.svg';
import plus from '../../../assets/icons/plus.svg';
import {ReactSVG} from "react-svg";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface Props {
}

const CreateNewAI: FC<Props> = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleCreate = () => {
    navigate('/create-ai')
  }
  return (
    <div className='create-my-ai-container'>
      <div className="create-my-ai" onClick={handleCreate}>
        <div className="create-my-ai-bg" />
        <div className="create-my-ai-content">
            <ReactSVG src={magic_wand} className='react-icon create-my-ai-wand'/>
            <h6 className='text-center px-3'>{t('CREATE_NEW_AI')}</h6>
            <ReactSVG src={plus} className='react-icon create-my-ai-plus'/>
        </div>
      </div>
    </div>
  );
}

export default CreateNewAI;