import React, {FC, useState} from 'react';
import {observer} from "mobx-react";
import {HeaderSecondary} from "../../containers/Header";
import AppStore from "../../store/AppStore";
import {useTranslation} from "react-i18next";
import SharedStore from "../../store/SharedStore";
import SharedChatsListItem from "./components/SharedChatsListItem";
import EmptyList from "../../components/EmptyList";
import Pagination from "../../components/Pagination";
import Loadable, {Spinner} from "../../components/Loadable";
import Button from "../../components/Button";
import {EModelStyle, IConversation} from "../../modules/rest";
import HorizontalScroll from "../../components/HorizontalScroll";
import {openModalLogin, openShareChatModal} from "../../modals";
import {useNavigate} from "react-router-dom";
import {API} from "../../modules/api";
import {toast} from "react-toastify";
import CraftSection from "../HomePage/components/CraftSection";

interface Props {
}

const SharedChatsPage: FC<Props> = observer(() => {
  const navigate = useNavigate();
  const {t} = useTranslation();

  const [loading, setLoading] = useState(false);

  const handleClick = (conversation: IConversation) => () => {
    openShareChatModal(conversation).then((res) => {
      if (res?.type === 'auth') {
        if (window.innerWidth < 768) {
          navigate(`/auth/login`, {state: res.cloneChat ? {sharedChatId: String(conversation.id), prevPathname: '/public'} : {prevPathname: '/public'}});
        } else {
          openModalLogin('login', conversation?.model?.mainPhotoImage?.id).then(success => {
            if (success) {
              if (res.cloneChat) {
                onCloneChat(conversation.id);
              } else {
                handleClick(conversation)();
              }

            }
          });
        }
      } else if (res?.type === 'premium') {
        navigate(`/premium/subscription`);
      } else if (res?.conversationId) {
        navigate(`/chat/${res.conversationId}`);
      }
    })
  }

  const onCloneChat = async (conversationId: number) => {
    setLoading(true);
    if (loading) return;
    try {
      const res = await API.Conversations.cloneShared(conversationId);
      navigate(`/chat/${res.id}`);
    } catch (e: any) {
      toast.error(e)
    } finally {
      setLoading(false);
    }
  }

  const data = SharedStore.pager?.data;

  return (
    <div className='shared-chats-page container'>
      <HeaderSecondary title='PUBLIC_CHATS' onClick={() => AppStore.toggleVisibleAside()}/>
      <h2 className='page-title mb-4'>{t('PUBLIC_CHATS')}</h2>
      <HorizontalScroll className='model-style'>
        <Button
          className={!SharedStore.request?.style ? 'active' : ''}
          btnType='third'
          title='ALL_MODELS'
          onClick={() => SharedStore.onCategoryStyle()}
        />
        {Object.values(EModelStyle).map(item => (
          <Button
            key={item}
            className={item === SharedStore.request.style ? 'active' : ''}
            btnType='third'
            title={item.toUpperCase()}
            onClick={() => SharedStore.onCategoryStyle(item)}
          />
        ))}
      </HorizontalScroll>
        <div className='position-relative d-flex justify-content-center flex-1 w-100'>
          <Spinner loading={SharedStore.loading || !SharedStore.ready} absolute style={{top: -14}}/>
        </div>
        {data
          ?
          data?.length
            ?
            <Loadable loading={SharedStore.loading} showSpinner={false} className='row g-3 g-xl-4'>
              {data.map(item => (
                <SharedChatsListItem
                  conversation={item}
                  key={item.id}
                  onClick={handleClick(item)}
                />
              ))}
            </Loadable>
            : <EmptyList/>
          :
          null
        }
        <div className='home-craft'>
          <CraftSection/>
        </div>
        <Pagination
          onPageChange={SharedStore.onPageChange}
          pager={SharedStore.pager}
        />
    </div>
  );
})

export default SharedChatsPage;