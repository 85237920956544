import React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  children: any;
  title: string
}

const LegalInformationPageWrapper: FC<Props> = ({title, children}) => {
  const {t} = useTranslation();
  return (
    <div className='container'>
      <h2 className='page-title'>{t(title)}</h2>
      <h3 className="legal-information-date">Date of Revision: [ 06/06/2024 ]</h3>
      <div className='legal-information-content'>
        {children}
      </div>
    </div>
  );
}

export default LegalInformationPageWrapper;