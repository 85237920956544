import React, {FC} from 'react';
import lines_bg from '../assets/images/lines_bg_2.png';
import Button from "../../../components/Button";
import magic_wand from "../../../assets/icons/magic_wand.svg";
import {useTranslation} from "react-i18next";

interface Props {
  className?: string;
}

const CraftSection: FC<Props> = ({className}) => {
  const {t} = useTranslation();

  return (
    <div className={`d-flex justify-content-center ${className || ''}`}>
      <div className="home-craft-bg">
        <img src={lines_bg} alt="bg"/>
        <div className="home-craft-bg_bg"/>
      </div>
      <div className='position-relative d-flex align-items-center flex-column w-100 z-1'>
        <h2 className='text-center' dangerouslySetInnerHTML={{__html: t('HOME_CRAFT') || ''}}/>
        <Button
          href={'/create-ai'}
          title={'CREATE_YOUR_AI'}
          size='lg'
          icon={magic_wand}
        />
      </div>
    </div>
  );
}

export default CraftSection;