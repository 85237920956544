import React, {FC, Fragment} from 'react';

interface Props {
  step: number;
}

const StepNumber: FC<Props> = ({step}) => {
  const getClass = (idx: number, className: string) => {
    let addClass = '';
    if(idx === step) addClass = ' current';
    else if (idx < step) addClass = ' complete'
    return className + addClass;
  }

  return (
    <div className='steps'>
      {Array(9).fill({}).map((_, i) => (
        <Fragment key={i}>
          <div className={getClass(i, 'step')}><span>{i + 1}</span></div>
          {i < 8 && <div className={getClass(i, "step-separator")}/>}
        </Fragment>
      ))}
    </div>
  );
}

export default StepNumber;