import React, {FC, useEffect, useState} from 'react';
import {observer} from "mobx-react";
import magic_wand from '../../assets/icons/magic_wand_active.svg';
import {ReactSVG} from "react-svg";
import StepNumber from "./components/StepNumber";
import Step1 from "./components/Step1/Step1";
import Step2 from "./components/Step2/Step2";
import Step3 from "./components/Step3/Step3";
import Step4 from "./components/Step4/Step4";
import Step5 from "./components/Step5/Step5";
import Step6 from "./components/Step6/Step6";
import Step7 from "./components/Step7/Step7";
import Step8 from "./components/Step8/Step8";
import Step9 from "./components/Step9/Step9";
import {EFieldGroup, ICreateModelRequest, IVoice} from "../../modules/rest";
import {toast} from "react-toastify";
import {API} from "../../modules/api";
import {useNavigate} from "react-router-dom";
import AppStore from "../../store/AppStore";
import {openModalLogin, openModalNoMoney} from "../../modals";
import {HeaderSecondary} from "../../containers/Header";
import {useTranslation} from "react-i18next";
import {personalityData, relationshipData, TPersonality} from "./assets/modules/utils";

interface Props {
}

const CreateAIPage: FC<Props> = observer(() => {
  const {t} = useTranslation();
  const navigate = useNavigate()

  const [voices, setVoices] = useState<IVoice[]>();
  const [ready, setReady] = useState(false);
  const [step, setStep] = useState(Number(localStorage.getItem('create-ai-step')) || 0);
  const [loading, setLoading] = useState(false);
  const startForm = localStorage.getItem('create-ai-form');
  const [form, setForm] = useState<Partial<ICreateModelRequest>>(startForm ? JSON.parse(startForm) : {});

  useEffect(() => {
    if (form.style && form.ethnicity) {
      fetchVoices();
    }
  }, [AppStore.gender, form.style, form.ethnicity]);

  const fetchVoices = async () => {
    try {
      const res = await API.Voices.getVoices({
        gender: AppStore.gender,
        style: form.style,
        ethnicity: form.ethnicity,
        limit: 7
      });
      setVoices(res.data);
    } catch (e) {

    }
  }

  useEffect(() => {
    if (ready) handleReset();
    setReady(true);
  }, [AppStore.gender]);

  const handleReset = () => {
    localStorage.removeItem('create-ai-step');
    localStorage.removeItem('create-ai-form');
    setStep(0);
    setForm({})
  }

  const onStepChange = (dir: 1|-1, data?: Partial<ICreateModelRequest>) => {
    localStorage.setItem('create-ai-step', step + dir + '')
    if (data) {
      setForm(prevState => {
        const newForm = {...prevState, ...data};
        localStorage.setItem('create-ai-form', JSON.stringify(newForm))
        return newForm
      })
    }
    setStep(prevState => prevState + dir);
    window.scrollTo({top: 0, behavior: 'auto'})
  }

  const handleSubmit = async () => {
    if (!AppStore.user?.id) {
      return openModalLogin();
    }
    try {
      setLoading(true);
      // @ts-ignore
      const personalityTitle: string = personalityData[form?.personality]?.title.toUpperCase();
      await API.Models.create({
        ...form,
        gender: AppStore.gender,
        // @ts-ignore
        relationship: t(relationshipData[form.relationship][AppStore.gender] || ''),
        personality: `${t(personalityTitle)} (${t(`${personalityTitle}_TEXT`)})`
      } as ICreateModelRequest);
      localStorage.removeItem('create-ai-step');
      localStorage.removeItem('create-ai-form');
      //@ts-ignore
      ym(97677527, 'reachGoal', 'submit7');
      navigate(`/my-ai`, {state: form.style});
    } catch (e: any) {
      const code = API.getStatusCode();
      if (code === 402) {
        openModalNoMoney("create-ai").then((path) => {
          if (path) navigate(path);
        });
      } else {
        toast.error(e);
      }
    } finally {
      setLoading(false);
    }
  }


  return (
    <>
      <HeaderSecondary gender coins title='CREATE_MY_AI' onClick={() => AppStore.toggleVisibleAside()}
                       onGenderChange={handleReset}/>
      <div className='container create-ai-page'>
        <h2 className='page-title'>
          <ReactSVG src={magic_wand} className='react-icon'/>
          <span>{t('CREATE_MY_AI')}</span>
        </h2>
        <div className='card create-ai-card'>
          <StepNumber step={step}/>
          {step === 0 && <Step1 onStepChange={onStepChange} data={form.style}/>}
          {step === 1 && <Step2 onStepChange={onStepChange} data={form}/>}
          {step === 2 && <Step3 onStepChange={onStepChange} data={form}/>}
          {step === 3 && <Step4 onStepChange={onStepChange} data={form}/>}
          {step === 4 && <Step5 onStepChange={onStepChange} data={form} voices={voices}/>}
          {step === 5 && <Step6 onStepChange={onStepChange} data={form}/>}
          {step === 6 && <Step7 onStepChange={onStepChange} data={form}/>}
          {step === 7 && <Step8 onStepChange={onStepChange} data={form}/>}
          {step === 8 &&
            <Step9
              onStepChange={(step) => setStep(step)}
              onSubmit={handleSubmit}
              data={form}
              loading={loading}
              voices={voices}
            />
          }
        </div>
      </div>
    </>
  );
})

export default CreateAIPage;
