import  React, {FC} from 'react';
import {useTranslation} from "react-i18next";

interface Props {
  title: string;
  className?: string;
  children: any;
}

const SummaryItem: FC<Props> = ({title, children, className}) => {
  const {t} = useTranslation();
  return (
      <div className={`create-ai-summary-item ${className || ''}`}>
        <div className='text-muted-14 mb-2 pb-1 text-center'>{t(title)}</div>
        {children}
      </div>
  );
}

export default SummaryItem;