import React,  { FC, SelectHTMLAttributes } from 'react';
import {useTranslation} from "react-i18next";
import { ReactSVG } from 'react-svg';

interface Props extends SelectHTMLAttributes<any> {
  label?: string;
  className?: string;
  errorText?: string;
  placeholder?: string;
  error?: boolean;
  icon?: any;
  children?: any;
}

const Select: FC<Props> = ({
                            label,
                            className,
                            icon,
                            error,
                            errorText,
                             children,
                            ...props
                          }) => {
  const {t} = useTranslation();
  return (
    <div className={`form-group${error ? ' error' : ''} ${className || ''}`}>
      {label ?
        <label className={`muted mb-2`}>
          <span>{t(label)}</span>
          {props.required ? <span className='text-danger text-12'>*</span> : null}
        </label>
        : null
      }
      {errorText && error ? <div className='form-group-error'>{errorText}</div> : null}
      <div className='form-control-wrap'>
        <select
          className={`form-control${icon ? ' form-control-icon' : ''}${props.placeholder && !props.value ? ' text-muted' : ''}`}
          {...props}
          name={String(props?.value || '')}
          autoComplete='new-input'
        >
          {props?.placeholder ? <option value="" disabled selected>{t(props.placeholder || '')}</option> : null}
          {children}
        </select>
        <div className="form-control-bg"/>
        {icon ?  <ReactSVG src={icon} className={'input-icon react-icon'} />  : null}
      </div>
    </div>
  );
};

export default Select;