import React, {FC} from 'react';
import {IModel} from "../../../modules/rest";
import {useNavigate} from "react-router-dom";
import Button from "../../../components/Button";
import user_svg from '../../../assets/icons/user_fill.svg';
import magic_wand from '../../../assets/icons/magic_wand.svg';
import refresh_active from '../../../assets/icons/refresh_active.svg';
import {openChooseModelModal} from "../../../modals";
import Avatar from "../../../components/Avatar";
import {thumbAvatar} from "../../../modules/utils";
import {ReactSVG} from "react-svg";
import {useTranslation} from "react-i18next";

interface Props {
  error: boolean;
  model?: IModel;
  onChange: (model: IModel) => void;
}

const GenerateModel: FC<Props> = ({model, onChange, error}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    openChooseModelModal().then(model => {
      if (model) onChange(model);
    });
  }

  if (!model) return (
    <>
      <div className={`generate-model-btns${error ? ' error' : ''}`}>
        <Button
          className='flex-1'
          title='EXISTING_CHARACTER'
          btnType='secondary'
          icon={user_svg}
          iconAbsolute
          onClick={handleClick}
        />
        <Button
          className='flex-1'
          title='CREATE_NEW_CHARACTER'
          btnType='secondary'
          icon={magic_wand}
          iconAbsolute
          onClick={() => navigate('/create-ai')}
        />
      </div>
      {error && <div className='text-12 mt-1 text-error'>{t('GENERATE_MODEL_ERROR')}</div>}
    </>
  )
  return (
    <div className='card-dark p-2 pe-3 pe-lg-4 d-flex align-items-center'>
      <Avatar size='sm' image={thumbAvatar(model.mainPhotoImage?.id, 128)}/>
      <div className='text-bold ps-2 pe-3 flex-1'>{model.name}</div>
      <div className='d-flex align-items-center cursor-pointer' onClick={handleClick}>
        <ReactSVG src={refresh_active} className='react-icon'/>
        <div className='text-tint text-bold-14 ps-1'>{t('CHANGE')}</div>
      </div>
    </div>
  );
}

export default GenerateModel;